import React from "react";
import FadeIn from 'react-fade-in';

import "./style.scss";
import compmaticon from '../../assets/images/register/compmaticon.svg';
import offericon from '../../assets/images/register/offericon.svg';
import exploreicon from '../../assets/images/register/exploreicon.svg';
import perstesticon from '../../assets/images/register/perstesticon.svg';
import resumeopticon from '../../assets/images/register/resumeopticon.svg';
import jobmatchicon from '../../assets/images/register/jobmatchicon.svg';
import atsguyimage from '../../assets/images/register/atsguyimage.png';

const UploadRegisterLastScreen = () => {
  return (
    <div className="registerlastdiv">
      <div style={{ marginTop: "20px" }}>
        <FadeIn delay={1000}>
          <div className="innercard">
            <img src={compmaticon} alt="" />
            <div style={{ marginLeft: "16px" }}>
              <p className="title">Company Match</p>
              <p className="subtitle">AI based selection of companies that
                would be interested in your profile</p>
            </div>
          </div>

          <div className="innercard">
            <img src={resumeopticon} alt="" />
            <div style={{ marginLeft: "16px" }}>
              <p className="title">Resume Optimizer</p>
              <p className="subtitle">A comprehensive analysis of your
                resume with  recommendations</p>
            </div>
          </div>

          <div className="innercard">
            <img src={exploreicon} alt="" />
            <div style={{ marginLeft: "16px" }}>
              <p className="title">Explore</p>
              <p className="subtitle">Lorem ipsum dolor sit amet,
                adipisicing elit, sed do</p>
            </div>
          </div>
        </FadeIn>
      </div>

      <img src={atsguyimage} alt="" style={{ height: "fit-content" }} />

      <div style={{ marginTop: "20px" }}>
        <FadeIn delay={1000}>
          <div className="innercard">
            <img src={jobmatchicon} alt="" />
            <div style={{ marginLeft: "16px" }}>
              <p className="title">Job Match</p>
              <p className="subtitle">Compare your resume to any job
                description and see how you match</p>
            </div>
          </div>
          <div className="innercard">
            <img src={offericon} alt="" />
            <div style={{ marginLeft: "16px" }}>
              <p className="title">Offer Evaluator</p>
              <p className="subtitle">Compare your current Job  with
                1 or more other job offers</p>
            </div>
          </div>
          <div className="innercard">
            <img src={perstesticon} alt="" />
            <div style={{ marginLeft: "16px" }}>
              <p className="title">Personality Test</p>
              <p className="subtitle">Find the best career for you though
                our personality test</p>
            </div>
          </div>


        </FadeIn>
      </div>
    </div>
  )
}

export default UploadRegisterLastScreen;