/* eslint-disable */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./CompanyDetails.scss";

const checkvalidArray = (data) => {
  if (Array.isArray(data) && data.length) {
    return true;
  } else return false;
};

const CommonCard = ({ companyDeatils, title, handleSocsClick, socsArrays }) => {
  const [isViewAll, setIsViewAll] = useState(true);
  const [clickedTxt, setClickedTxt] = useState(
    title === "Hard Skills" || title === "Soft Skills"
      ? "yourSkills"
      : "viewall"
  );
  const dashboardSkills = useSelector(
    (state) => state.dashboard.skillsForJobMatch
  );
  const skillsForAtsView = useSelector(
    (state) => state.dashboard.skillsForAtsView
  );
  const socLoader = useSelector((state) => state.componies.socLoader);
  const hardSkills =
    skillsForAtsView?.filter((item) => item?.id === "ST1") || [];
  const softSkills =
    skillsForAtsView?.filter((item) => item?.id === "ST2") || [];
  const yourSkills = title === "Hard Skills" ? hardSkills : softSkills;

  const { user_socs, loader } = useSelector((state) => state.login.userData);

  const handleChange = (value) => {
    if (value === "yourSkills" || value === "viewall") {
      setClickedTxt(value);
    } else {
      handleSocsClick(value);
      setClickedTxt(value);
    }
  };

  let upperData = [
    // {
    //   label: "Your Skills",
    //   id: "yourSkills",
    // },
  ];

  if (user_socs && user_socs.length) {
    const arr = user_socs.map((item) => {
      return { label: item.name, id: item.soc_id };
    });
    upperData = [
      ...upperData,
      ...arr,
      {
        label: "View All",
        id: "viewall",
      },
    ];
  }

  return (
    <div
      style={{
        borderRadius: "10px",
        // background: "rgba(67, 137, 250, 0.1)",
        //padding: "15px",
        // marginTop: "20px",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {title === "Hard Skills" || title === "Soft Skills" ? null : (
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "17px",
                  borderRadius: "8px 8px 0px 0px",
                  background: "white",
                  padding: "20px",
                  width: "100%",
                  marginBottom: "4px",
                }}
              >
                {title}
              </p>
            )}
            <>
              {title === "Hard Skills" || title === "Soft Skills" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    onClick={() => {
                      handleChange("yourSkills");
                    }}
                    style={{
                      cursor: "pointer",
                      fontWeight: 600,
                      margin: "0px 15px",
                      paddingBottom: "2px",
                      color:
                        clickedTxt === "yourSkills"
                          ? "rgb(31, 117, 254)"
                          : "rgba(0, 0, 0, 0.85)",
                      borderBottom:
                        clickedTxt === "yourSkills"
                          ? "2px solid rgb(31, 117, 254)"
                          : "none",
                    }}
                  >
                    Your Skills
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {upperData.map((item) => (
                      <div
                        onClick={() => {
                          handleChange(item.id);
                        }}
                        style={{
                          cursor: "pointer",
                          fontWeight: 600,
                          margin: "0px 15px",
                          paddingBottom: "2px",
                          color:
                            clickedTxt === item.id
                              ? "rgb(31, 117, 254)"
                              : "rgba(0, 0, 0, 0.85)",
                          borderBottom:
                            clickedTxt === item.id
                              ? "2px solid rgb(31, 117, 254)"
                              : "none",
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          </div>
          {clickedTxt === "viewall" ? (
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "0px 0px 8px 8px",
                // maxHeight: "180px",
                overflow: "auto",
              }}
            >
              {checkvalidArray(companyDeatils)
                ? companyDeatils.map((companyDeatil, i) => (
                  <>
                    {title === "Hard Skills" || title === "Soft Skills" ? (
                      <span style={{ margin: "8px 0px" }}>
                        {dashboardSkills.jobMatchSkills?.includes(
                          companyDeatil.name
                        ) ? (
                          <>
                            <span
                              style={{
                                backgroundColor: "rgb(233, 241, 255)",
                                fontWeight: 500,
                                fontSize: "15px",
                              }}
                            >
                              {companyDeatil.name}
                            </span>
                            <span
                              style={{
                                color: "rgba(67, 137, 250, 0.4)",
                                margin: "0px 8px",
                              }}
                            >
                              {" "}
                              |{" "}
                            </span>
                          </>
                        ) : (
                          <>
                            {isViewAll && (
                              <>
                                <span> {companyDeatil.name}</span>
                                <span
                                  style={{
                                    color: "rgba(67, 137, 250, 0.4)",
                                    margin: "0px 8px",
                                  }}
                                >
                                  {" "}
                                  |{" "}
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </span>
                    ) : (
                      <span style={{ margin: "8px 0px" }}>
                        {companyDeatil.name || companyDeatil.industry_name}
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </span>
                    )}
                  </>
                ))
                : null}
            </div>
          ) : null}
          {clickedTxt !== "yourSkills" && clickedTxt !== "viewall" ? (
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "0px 0px 8px 8px",
                // maxHeight: "180px",
                overflow: "auto",
              }}
            >
              {socLoader ? (
                <h4>Please wait..!</h4>
              ) : (
                <>
                  {checkvalidArray(socsArrays)
                    ? socsArrays.map((skillname) => (
                      <span style={{ margin: "8px 0px" }}>
                        {dashboardSkills.jobMatchSkills?.includes(
                          skillname
                        ) ? (
                          <>
                            <span
                              style={{
                                backgroundColor: "rgb(233, 241, 255)",
                                fontWeight: 500,
                                fontSize: "15px",
                              }}
                            >
                              {skillname}
                            </span>
                            <span
                              style={{
                                color: "rgba(67, 137, 250, 0.4)",
                                margin: "0px 8px",
                              }}
                            >
                              {" "}
                              |{" "}
                            </span>
                          </>
                        ) : (
                          <>
                            <span> {skillname}</span>
                            <span
                              style={{
                                color: "rgba(67, 137, 250, 0.4)",
                                margin: "0px 8px",
                              }}
                            >
                              {" "}
                              |{" "}
                            </span>
                          </>
                        )}
                      </span>
                    ))
                    : null}
                </>
              )}
            </div>
          ) : null}
          {clickedTxt === "yourSkills" ? (
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "0px 0px 8px 8px",
                // maxHeight: "180px",
                overflow: "auto",
              }}
            >
              {checkvalidArray(yourSkills)
                ? yourSkills.map((skill) => (
                  <span style={{ margin: "8px 0px" }}>
                    {companyDeatils
                      .map((companyDeatil) => companyDeatil.name)
                      .includes(skill.name) ? (
                      <>
                        <span
                          style={{
                            backgroundColor: "rgb(233, 241, 255)",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {skill.name}
                        </span>
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          {skill.name}
                        </span>
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </>
                    )}
                  </span>
                ))
                : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CommonCard;
