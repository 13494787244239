/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Row, Col, Spin, Tooltip } from "antd";
import "./index.scss";
import * as DashBoardActions from "../../store/dashBoard/dashboardActions";
import NewHeader from "../../components/newHeader";

import codeIcon from "../../assets/images/ats/codeIcon.svg";
import crossAroow from "../../assets/images/ats/crossAroow.svg";
import eye from "../../assets/images/ats/eye.svg";
import info from "../../assets/images/ats/info.svg";
import eyeSelected from "../../assets/images/ats/eyeSelected.svg";
import codeNorm from "../../assets/images/ats/codeNorm.svg";

const getSubTxanamy = (subSkills) => {
  let arr = [];
  subSkills.map((item) => {
    arr = [...arr, ...item.skills];
  });
  return arr;
};

const mergeSkills = (subSkills) => {
  let arr = subSkills.filter((item) => parseInt(item.percentOfOverall) <= 5);
  let arr2 = subSkills.filter((item) => parseInt(item.percentOfOverall) >= 5);
  let obj = {
    sub_taxonomy_name: "Others",
    percentOfOverall: 0,
    skills: [],
  };
  arr?.map((item) => {
    obj.percentOfOverall =
      obj.percentOfOverall + parseInt(item.percentOfOverall);
    obj.skills = [...obj.skills, ...item.skills];
  });
  let arr3 = [...arr2, obj];

  return arr3;
};

const formatSkills = (skills) => {
  return mergeSkills(
    skills.map((item) => {
      if (parseInt(item.percentOfOverall) <= 5) {
        return {
          percentOfOverall: item.percentOfOverall,
          taxonomy: "Others",
          skills: getSubTxanamy(item.sub_taxonomy),
        };
      } else {
        return {
          percentOfOverall: item.percentOfOverall,
          taxonomy: item.taxonomy,
          sub_taxonomy: mergeSkills(item.sub_taxonomy),
        };
      }
    })
  );
};

const calculateTimimg = (d) => {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0;
  while (d) {
    if (d >= 365) {
      years++;
      d -= 365;
    } else if (d >= 30) {
      months++;
      d -= 30;
    } else if (d >= 7) {
      weeks++;
      d -= 7;
    } else {
      days++;
      d--;
    }
  }
  return {
    years,
    months,
    weeks,
    days,
  };
};

const Analysis = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.dashboard.loader);
  const loginState = useSelector((state) => state.login);

  const [totalExperience, settotalExperience] = useState(0);

  const contactInfo = useSelector((state) => state.dashboard.contactInfo);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const educationInfo = useSelector((state) => state.dashboard.educationInfo);
  const skillsTaxanomy = useSelector((state) => state.dashboard.skillsTaxanomy);
  const [selectedAtsViewType, setAtsViewType] = useState("ATS Extraction");

  console.log("DADADAD", employementInfo)

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.primary_resume &&
      !employementInfo?.employment[0]?.designation
    ) {
      // console.log("INSIDELOGINSTATE")
      dispatch(
        DashBoardActions.resumeParserRequest(loginState.userData.primary_resume)
      );
    }
  }, [loginState]);

  useEffect(() => {
    {
      let total = 0;
      employementInfo.employment.map((item) => {
        if (item.from) {
          const d1 = moment(item.from);
          const d2 = item.to ? moment(item.to) : moment();
          const diffTime = Math.abs(d2 - d1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          total = total + diffDays;
        }
      });
      settotalExperience(total);
    }
  }, [employementInfo]);

  function monthDiff(date1, date2) {
    const d1 = moment(date1);
    const d2 = date2 ? moment(date2) : moment();
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return calculateTimimg(diffDays);
  }

  const commonSkillsContainer = (skills) => (
    <>
      {skills.percentOfOverall ? (
        <div className="commonSkillsContainer">
          <div className="Skills_title">
            {skills.taxonomy || "Miscellaneous"}({skills.percentOfOverall}%)
          </div>
          {skills.skills
            ? skills?.skills.map((item, i) => (
              <div className="skill_name">
                {item.skill_name}{" "}
                {skills.skills.length === i + 1 ? null : <span>|</span>}{" "}
              </div>
            ))
            : skills.sub_taxonomy.map((item) => (
              <>
                {item.percentOfOverall ? (
                  <div className="sub_skills_taxanomy">
                    <div className="sub_taxonomy_name">
                      {item.sub_taxonomy_name || "Others"}(
                      {item.percentOfOverall}
                      %)
                    </div>
                    <div className="skills_container">
                      {item.skills.map((subItem, i) => (
                        <div className="skill_name">
                          {subItem.skill_name}{" "}
                          {item.skills.length === i + 1 ? null : (
                            <span>|</span>
                          )}{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </>
            ))}
        </div>
      ) : null}
    </>
  );

  return (
    <div className="analisis-container">
      <NewHeader />
      <Spin size="large" spinning={isLoading}>
        <div style={{ padding: "30px" }}>
          <Row className="atsSwitchBtnDiv">
            <Col>
              <div
                style={{ padding: "20px 0" }}
                onClick={() => setAtsViewType("ATS Extraction")}
              >
                <img
                  src={
                    selectedAtsViewType === "ATS Extraction"
                      ? codeIcon
                      : codeNorm
                  }
                  alt="codeIcon"
                  style={{ marginRight: "15px" }}
                />
                <span
                  className={
                    selectedAtsViewType === "ATS Extraction"
                      ? "atsSelectedText"
                      : "atsNormalText"
                  }
                >
                  ATS Extraction
                </span>
              </div>
            </Col>
            <Col className="line" />
            <Col>
              <div
                style={{ padding: "20px 0" }}
                onClick={() => setAtsViewType("ATS View")}
              >
                <img
                  src={selectedAtsViewType === "ATS View" ? eyeSelected : eye}
                  alt="eye"
                  style={{ marginRight: "15px" }}
                />
                <span
                  className={
                    selectedAtsViewType === "ATS View"
                      ? "atsSelectedText"
                      : "atsNormalText"
                  }
                >
                  ATS View
                </span>
              </div>
            </Col>
          </Row>
          {selectedAtsViewType === "ATS Extraction" && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div style={{ paddingRight: "30px" }}>
                <p style={{ color: "#000000", marginBottom: "0px" }}>
                  This is how ATS Bots are currently parsing your resume
                </p>
                <img src={crossAroow} alt="arrowDown" />
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {selectedAtsViewType === "ATS Extraction" && (
              <div
                style={{
                  background: "#000033",
                  padding: "20px",
                  borderRadius: "10px",
                  width: "71%",
                  color: "#FFFFFF",
                }}
              >
                <div>{`{`}</div>
                {(contactInfo.firstName === "" && employementInfo.employment.length == 0) && (
                  <><p>{`  `}</p><p>Please Wait...</p></>
                )}
                <div style={{ paddingLeft: "15px" }}>
                  {/* personal info */}
                  {contactInfo.firstName !== "" &&
                    <div>
                      “
                      <span style={{ color: "rgba(65,255,0)" }}>
                        Personal Info
                      </span>
                      ”:{`{`}
                      <div style={{ paddingLeft: "15px" }}>
                        {Object.values(contactInfo).map((item, i) => {
                          if (item)
                            return (
                              <span>
                                <span style={{ display: i ? "inline" : "none" }}>
                                  ,
                                </span>{" "}
                                <span>“{item}”</span>
                              </span>
                            );
                        })}
                      </div>
                      {`}`}
                    </div>
                  }

                  {/* experience */}
                  {employementInfo.employment.length > 0 &&
                    <div>
                      “<span style={{ color: "rgba(65,255,0)" }}>Experience</span>
                      ”:
                      {`{`}
                      <div style={{ paddingLeft: "15px" }}>
                        <span>
                          “{calculateTimimg(totalExperience)?.years} years,{" "}
                          {calculateTimimg(totalExperience)?.months} months”
                        </span>
                      </div>
                      {`}`}
                    </div>
                  }

                  {/* jobs */}
                  <div>
                    {employementInfo.employment &&
                      employementInfo.employment.map((job, j) => (
                        <div>
                          “
                          <span style={{ color: "rgba(65,255,0)" }}>
                            Job {j + 1}
                          </span>
                          ”:
                          {`{`}
                          <div style={{ paddingLeft: "15px" }}>
                            {Object.values(job).map((item, i) => {
                              if (item && i !== 5)
                                return (
                                  <span>
                                    <span
                                      style={{ display: i ? "inline" : "none" }}
                                    >
                                      ,
                                    </span>{" "}
                                    <span>“{item}”</span>
                                  </span>
                                );
                            })}
                          </div>
                          {`}`}
                        </div>
                      ))}
                  </div>
                  {/* education */}
                  <div>
                    {educationInfo.education &&
                      educationInfo.education.map((edu, j) => (
                        <div>
                          “
                          <span style={{ color: "rgba(65,255,0)" }}>
                            Education {j + 1}
                          </span>
                          ”:
                          {`{`}
                          <div style={{ paddingLeft: "15px" }}>
                            {Object.values(edu).map((item, i) => {
                              if (item)
                                return (
                                  <span>
                                    <span
                                      style={{ display: i ? "inline" : "none" }}
                                    >
                                      ,
                                    </span>{" "}
                                    <span>“{item}”</span>
                                  </span>
                                );
                            })}
                          </div>
                          {`}`}
                        </div>
                      ))}
                  </div>
                  {/* skills taxanomy */}
                  {(skillsTaxanomy && skillsTaxanomy.length > 0) &&
                    <div>
                      “
                      <span style={{ color: "rgba(65,255,0)" }}>
                        Skills-Taxanomy
                      </span>
                      ”:
                      {`{`}
                      <div style={{ paddingLeft: "15px" }}>
                        <div>
                          {skillsTaxanomy &&
                            skillsTaxanomy.map((skillCat, i) => (
                              <div>
                                “
                                <span style={{ color: "rgba(65,255,0)" }}>
                                  {skillCat.taxonomy}
                                </span>
                                ”:
                                {`{`}
                                <div style={{ paddingLeft: "15px" }}>
                                  {skillCat.sub_taxonomy &&
                                    skillCat.sub_taxonomy.map((skill, j) => {
                                      if (skill.sub_taxonomy_name)
                                        return (
                                          <span>
                                            <span
                                              style={{
                                                display: j ? "inline" : "none",
                                              }}
                                            >
                                              ,
                                            </span>{" "}
                                            <span>
                                              “{skill.sub_taxonomy_name}”
                                            </span>
                                          </span>
                                        );
                                    })}
                                </div>
                                {`}`}
                              </div>
                            ))}
                        </div>
                      </div>
                      {`}`}
                    </div>
                  }
                </div>
                <div>{`}`}</div>
              </div>
            )}

            {selectedAtsViewType === "ATS View" && (
              <Row className="atsViewDiv">
                <Col span={24}>
                  <div
                    className="ats_card_container"
                    style={{ background: "#DBE5FA" }}
                  >
                    <h4 className="atsViewHeaders">Contact Info</h4>
                    <Row className="atsSubDiv">
                      <Col span={8}>
                        <div className="atsVerticalDisplay">
                          <span className="atsViewSubText">Name</span>
                          <span
                            className="atsViewSubNormText"
                            style={{ marginTop: "8px" }}
                          >
                            {" "}
                            {contactInfo.formattedName || ""}{" "}
                          </span>
                          <span
                            className="atsViewSubText"
                            style={{ marginTop: "24px" }}
                          >
                            City
                          </span>
                          <span
                            className="atsViewSubNormText"
                            style={{ marginTop: "8px" }}
                          >
                            {" "}
                            {contactInfo.city || ""}
                          </span>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="atsVerticalDisplay">
                          <div>
                            <span className="borLeftDark" style={{ marginRight: "24px" }} />
                            <span className="atsViewSubText">Email</span>
                          </div>
                          <div style={{ display: "flex", marginTop: "8px" }}>
                            <span className="borLeft">|</span>
                            <Tooltip title={contactInfo.email || ""}>
                              <p className="atsViewSubNormText">
                                {contactInfo.email || ""}{" "}
                              </p>
                            </Tooltip>
                          </div>
                          <div style={{ marginTop: "24px" }}>
                            <span className="borLeftDark" style={{ marginRight: "24px" }} />
                            <span className="atsViewSubText">Zip Code</span>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            <span className="borLeft">|</span>
                            <span className="atsViewSubNormText">
                              {contactInfo.postalCode || ""}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="atsVerticalDisplay">
                          <div>
                            <span className="borLeftDark" style={{ marginRight: "24px" }} />
                            <span className="atsViewSubText">Phone Number</span>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            <span className="borLeft">|</span>
                            <span className="atsViewSubNormText">
                              {contactInfo.phoneNumber || ""}{" "}
                            </span>
                          </div>
                          <div style={{ marginTop: "24px" }}>
                            <span className="borLeftDark" style={{ marginRight: "24px" }} />
                            <span className="atsViewSubText">LinkedIn</span>
                          </div>
                          <div style={{ display: "flex", marginTop: "8px" }}>
                            <span className="borLeft">|</span>
                            <Tooltip title={contactInfo.onlineProfile1 || ""}>
                              <p className="atsViewSubNormText" >
                                {contactInfo.onlineProfile1 || ""}{" "}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="ats_card_container"
                    style={{ background: "#EAF9C7" }}
                  >
                    <h4 className="atsViewHeaders">Work Experience</h4>
                    <Row className="atsSubDiv">
                      <Col span={8}>
                        <div className="atsVerticalDisplay">
                          <span className="atsViewSubText" style={{ textAlign: "center", marginLeft: "-24px" }}>Company</span>
                          {employementInfo.employment.map((item) => {
                            if (item)
                              return (
                                <span
                                  className="atsViewSubNormText"
                                  style={{ marginTop: "8px" }}
                                >
                                  {item.company || "NA"}
                                </span>
                              );
                          })}
                        </div>
                      </Col>
                      <Col span={10}>
                        <div className="atsVerticalDisplay">
                          <div style={{ display: "flex" }}>
                            <span className="borLeftDark" />
                            <p className="atsViewSubText" style={{ width: "100%", textAlign: "center" }}>Title</p>
                          </div>
                          {employementInfo.employment.map((item) => {
                            if (item)
                              return (
                                <div
                                  style={{
                                    marginTop: "8px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <span className="borLeft">|</span>
                                  <span className="atsViewSubNormText">
                                    {item.designation || ""}{" "}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className="atsVerticalDisplay">
                          <div style={{ display: "flex" }}>
                            <span className="borLeftDark" />
                            <p className="atsViewSubText" style={{ width: "100%", textAlign: "center" }}>From-To</p>
                          </div>
                          {employementInfo.employment.map((item) => {
                            if (item)
                              return (
                                <div
                                  style={{
                                    marginTop: "8px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <span className="borLeft">|</span>
                                  <span className="atsViewSubNormText">
                                    {moment(item.from).format("MM/YY") || ""} -{" "}
                                    {item.to
                                      ? moment(item?.to).format("MM/YY")
                                      : "Present"}{" "}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      </Col>
                      <Col span={2}>
                        <div className="atsVerticalDisplay">
                          <div style={{ display: "flex" }}>
                            <span className="borLeftDark" />
                            <p className="atsViewSubText" style={{ width: "100%", textAlign: "center" }}>YRS</p>
                          </div>
                          {employementInfo.employment.map((item) => {
                            if (item)
                              return (
                                <div
                                  style={{
                                    marginTop: "8px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <span className="borLeft">|</span>
                                  <span className="atsViewSubNormText">
                                    {monthDiff(item.from, item.to)?.years}.
                                    {monthDiff(item.from, item.to)?.months}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="ats_card_container"
                    style={{ background: "#FFEADB" }}
                  >
                    <h4 className="atsViewHeaders">Education</h4>
                    <Row className="atsSubDiv" style={{ marginBottom: "0px" }}>
                      <Col span={7}>
                        <div className="atsVerticalDisplay">
                          <span className="atsViewSubText" style={{ textAlign: "center" }}>University</span>
                          {educationInfo.education &&
                            educationInfo.education.map((item) => {
                              if (item)
                                return (
                                  <div
                                    style={{
                                      marginTop: "8px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <span className="atsViewSubNormText">
                                      {item.school || ""}{" "}
                                    </span>
                                  </div>
                                );
                            })}
                        </div>
                      </Col>
                      <Col span={7}>
                        <div className="atsVerticalDisplay">
                          <div style={{ display: "flex" }}>
                            <span className="borLeftDark" />
                            <p className="atsViewSubText" style={{ width: "100%", textAlign: "center" }}>Degree</p>
                          </div>

                          {educationInfo.education &&
                            educationInfo.education.map((item) => {
                              if (item)
                                return (
                                  <span
                                    className="atsViewSubNormText"
                                    style={{
                                      marginTop: "8px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <span className="borLeft">|</span>
                                    {item.degreeName || ""}{" "}
                                  </span>
                                );
                            })}
                        </div>
                      </Col>

                      <Col span={7}>
                        <div className="atsVerticalDisplay">
                          <div style={{ display: "flex" }}>
                            <span className="borLeftDark" />
                            <p className="atsViewSubText" style={{ width: "100%", textAlign: "center" }}>Major</p>
                          </div>
                          {educationInfo.education &&
                            educationInfo.education.map((item) => {
                              if (item)
                                return (
                                  <div
                                    style={{
                                      marginTop: "8px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <span className="borLeft">|</span>
                                    <span className="atsViewSubNormText">
                                      {item.field || ""}{" "}
                                    </span>
                                  </div>
                                );
                            })}
                        </div>
                      </Col>
                      <Col span={3}>
                        <div className="atsVerticalDisplay">
                          <div style={{ display: "flex" }}>
                            <span className="borLeftDark" />
                            <p className="atsViewSubText" style={{ width: "100%", textAlign: "center" }}>Year</p>
                          </div>
                          {educationInfo.education &&
                            educationInfo.education.map((item) => {
                              if (item)
                                return (
                                  <div
                                    style={{
                                      marginTop: "8px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <span className="borLeft">|</span>
                                    <span className="atsViewSubNormText">
                                      {(item?.to &&
                                        moment(item?.to).format("YYYY")) ||
                                        ""}
                                    </span>
                                  </div>
                                );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="ats_card_container"
                    style={{ background: "#E1D1FC" }}
                  >
                    <h4 className="atsViewHeaders">Skills</h4>
                    <div className="atsSkillsDiv">
                      <div>
                        {formatSkills(skillsTaxanomy).map((skillCat) =>
                          commonSkillsContainer(skillCat)
                        )}
                      </div>
                    </div>
                  </div>

                </Col>
              </Row>
            )}

            <div
              style={{
                padding: "15px",
                background: "white",
                borderRadius: "10px",
                width: "26%",
                // boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.05)",
                height: "fit-content",
                marginTop: selectedAtsViewType === "ATS View" ? "54px" : "0px",
              }}
            >
              <div>
                <img
                  src={info}
                  alt="info"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    color: "#000000",
                  }}
                />
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Insights
                </span>
              </div>
              <div style={{ color: "#666666", padding: "15px 0px" }}>
                Applicant tracking system (ATS) acts as an electronic gatekeeper
                and parses a resume's content into categories and then scans it
                for specific keywords to determine if the job application should
                be passed along to the recruiter. The ATS is apt to toss
                the least-qualified candidates, rather than identify the
                applicants who are the best fit. Unfortunately, that means if a
                resume template is not written and formatted with the ATS in
                mind, a qualified candidate can be easily passed over.
              </div>
              <div style={{ color: "#666666" }}>
                How to write an ATS-friendly resume? <br />
                <span style={{ color: "#4389FA", cursor: "pointer" }}>
                  {" "}
                  Read Article
                </span>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Analysis;
