import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { AppReducers } from "./reducers";
import rootSaga from "./rootSaga";

let reduxStore;
let sagaMiddleware;

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const configureStore = () => {
  if (!reduxStore) {
    // Saga MiddleWare
    sagaMiddleware = createSagaMiddleware();

    // Apply all middleWares to Redux Store
    const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

    // creating a store with reducers and middleWares
    reduxStore = createStore(AppReducers, enhancer);

    // Running all Worker Sagas
    sagaMiddleware.run(rootSaga);

    return reduxStore;
  }
  return reduxStore;
};

export default configureStore;
