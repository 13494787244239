/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Tooltip, Menu, Dropdown } from "antd";
// import { isEmpty } from "lodash";
import jwt_decode from "jwt-decode";
import history from "../routes/History";
import * as LoginActions from "../store/newLogin/newLoginAction";
import * as RegisterActions from "../store/newRegister/registerActions";
import * as DashBoardActions from "../store/dashBoard/dashboardActions";
import * as ResumeOptimizerActions from "../store/resumeOptimizer/resumeOptimizerActions";
import * as ComponiesActions from "../store/componies/action";
import * as ProfileActions from "../store/profile/profileActions";
import * as ToolsActions from "../store/Tools/toolsActions";
import * as JobMatchActions from "../store/jobMatch/jobMatchActions";

import Gear from "../assets/images/dashboard/Gear.svg";
import home from "../assets/images/dashboard/home.svg";
import arrowDown from "../assets/images/dashboard/arrowDown.svg";
import activeRedIcon from "../assets/images/dashboard/activeRedIcon.svg";
import devider from "../assets/images/dashboard/devider.svg";
import Crown from "../assets/images/dashboard/Crown.svg";
import user from "../assets/images/dashboard/user.svg";
import logout from "../assets/images/dashboard/logout.svg";
import iconbill from "../assets/images/dashboard/iconbill.svg";

import selectorAts from "../assets/images/selector/selectorAts.svg";
import User from "../assets/images/resumeOptimizer/User.svg";
import selectorCompMatch from "../assets/images/selector/selectorCompMatch.svg";
import personalDashboardActive from "../assets/images/dashboard/personalDashboardActive.svg";
import selectorExplore from "../assets/images/selector/selectorExplore.svg";
import selectorFaq from "../assets/images/selector/selectorFaq.svg";
import selectorJobMatch from "../assets/images/selector/selectorJobMatch.svg";
import selectorMore from "../assets/images/selector/selectorMore.svg";
import selectorOptimizer from "../assets/images/selector/selectorOptimizer.svg";
import selectorPersonalTest from "../assets/images/selector/selectorPersonalTest.svg";
import selectorRead from "../assets/images/selector/selectorRead.svg";
import activeExplore from "../assets/images/selector/exploreActive.svg";
import UnnlockNew from "../assets/images/UnnlockNew.svg";
import activebulb from "../assets/images/selector/activebulb.svg";
import reads from "../assets/images/selector/reads.svg";

import atsNavIcon from "../assets/images/selector/atsNavIcon.svg";
import compayNavIcon from "../assets/images/selector/compayNavIcon.svg";
import jobMatchNavIcon from "../assets/images/selector/jobMatchNavIcon.svg";
import moreNaveIcon from "../assets/images/selector/moreNaveIcon.svg";
import resumeOptimizerNavIcon from "../assets/images/selector/resumeOptimizerNavIcon.svg";

import "./header.scss";

const { SubMenu } = Menu;

const upperData = [
  // {
  //   src: selectorAts,
  //   activeSrc: atsNavIcon,
  //   label: "ATS View & Analysis",
  //   link: "/analysis",
  // },
  {
    src: selectorOptimizer,
    label: "Resume Optimizer",
    activeSrc: resumeOptimizerNavIcon,
    link: "/resumeVault",
  },
  {
    src: selectorJobMatch,
    activeSrc: jobMatchNavIcon,
    label: "Job Match",
    link: "/jobmatch",
  },
  {
    src: selectorCompMatch,
    activeSrc: compayNavIcon,
    label: "Company Match",
    link: "/companyMatch",
  },
  // {
  //   src: selectorComponsation,
  //   label: "Compensation",
  //   link: "/analysis",
  // },
  // {
  //   src: selectorJobMatch,
  //   activeSrc: jobMatchNavIcon,
  //   label: "Offer Evaluator",
  //   link: "/offerEvaluator",
  // },
  {
    src: selectorJobMatch,
    activeSrc: jobMatchNavIcon,
    label: "Job Tracker",
    link: "/jobTracker",
  },
  {
    src: selectorRead,
    activeSrc: reads,
    label: "Open Jobs",
    //link: "/OpenJobCompanies",
    link: "/OpenJobs",
  },
  {
    src: selectorExplore,
    label: "Explore",
    link: "/explore",
    activeSrc: activeExplore,
  },
];

const lowerData = [
  // {
  //   src: selectorFaq,
  //   activeSrc: selectorFaq,
  //   label: "Personality Test",
  //   link: "/personalityTest",
  // },
  // {
  //   src: selectorPersonalTest,
  //   activeSrc: activebulb,
  //   label: "Tips & FAQs",
  //   link: "/tipsAndFAQs",
  // },
  {
    src: user,
    activeSrc: user,
    label: "Your Profile",
    link: "/profile",
  },
  // {
  //   src: selectorRead,
  //   activeSrc: reads,
  //   label: "Interesting Reads",
  //   link: "/interestingReads",
  // },
  {
    src: selectorPersonalTest,
    activeSrc: activebulb,
    label: "Personal Dashboard",
    link: "/personalDashboard",
  },
  // {
  //   src: user,
  //   activeSrc: user,
  //   label: "Subscriptions",
  //   link: "/subscriptions",
  // },
];

const menu = (
  <Menu style={{ borderRadius: "10px" }}>
    <Menu.ItemGroup>
      {upperData.map((item) => (
        <Menu.Item
          onClick={() => {
            if (item.link)
              history.push({
                pathname: item.link,
                state: {},
              });
          }}
          style={{ padding: "10px", color: "rgba(38, 28, 75, 0.8)" }}
        >
          <img
            src={item.src}
            alt="label icon"
            style={{ width: "30px", marginRight: "10px" }}
          />
          <span>{item.label}</span>
        </Menu.Item>
      ))}
    </Menu.ItemGroup>
    <SubMenu
      title={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ paddingLeft: "6px" }}>
            <img
              src={selectorMore}
              alt="label icon"
              style={{ width: "30px", marginRight: "10px" }}
            />
            <span>More</span>
          </div>
          <div>
            <img src={moreNaveIcon} style={{ width: "7px" }} />
          </div>
        </div>
      }
      style={{ borderRadius: "10px" }}
      className="customNavbarSubMenue"
    >
      {lowerData.map((item) => (
        <Menu.Item
          onClick={() => {
            if (item.link)
              history.push({
                pathname: item.link,
                state: {},
              });
          }}
          style={{ padding: "10px", color: "rgba(38, 28, 75, 0.8)" }}
        >
          <img
            src={item.src}
            alt="label icon"
            style={{ width: "30px", marginRight: "10px" }}
          />
          <span>{item.label}</span>
        </Menu.Item>
      ))}
    </SubMenu>
  </Menu>
);

const NewHeader = () => {
  const dispatch = useDispatch();

  const [currentPath, setCurrentPath] = useState("");

  const loginState = useSelector((state) => state.login);
  // const { userData } = useSelector((state) => state.login);
  const currentSub = useSelector((state) => state.profile.currentSubscription);
  const subscriptions = useSelector((state) => state.profile.subscriptions);

  const location = useLocation();

  const signoutHandler = () => {
    dispatch(LoginActions.logoutRequest());
    window.localStorage.clear();
    dispatch(LoginActions.reset());
    dispatch(ProfileActions.reset());
    dispatch(ToolsActions.reset());
    dispatch(RegisterActions.reset());
    dispatch(ComponiesActions.reset());
    dispatch(ResumeOptimizerActions.reset());
    dispatch(DashBoardActions.reset());
    dispatch(JobMatchActions.reset());
    history.push("/");
  };

  const profMenu = (
    <Menu style={{ borderRadius: "10px" }}>
      <Menu.ItemGroup>
        <Menu.Item
          style={{ padding: "10px", color: "rgba(38, 28, 75, 0.8)" }}
          onClick={() => {
            history.push("/profile");
          }}
        >
          <img
            src={user}
            alt="label icon"
            style={{ width: "24px", marginRight: "10px" }}
          />
          <span>profile</span>
          {loginState.userData?.email_verified ? null : (
            <div
              style={{
                color: "#ED0606",
                fontWeight: 400,
                fontSize: "10px",
                background: "#FFF2F2",
                padding: "4px",
                borderRadius: "4px",
                marginLeft: "35px",
              }}
            >
              Verify your email address
            </div>
          )}
        </Menu.Item>
        {/* <Menu.Item style={{ padding: "10px", color: "rgba(38, 28, 75, 0.8)" }}>
          <img
            src={iconbill}
            alt="label icon"
            style={{ width: "24px", marginRight: "10px" }}
          />
          <span>Billing</span>
        </Menu.Item> */}
        <Menu.Item
          style={{ padding: "10px", color: "rgba(38, 28, 75, 0.8)" }}
          onClick={() => signoutHandler()}
        >
          <img
            src={logout}
            alt="label icon"
            style={{ width: "24px", marginRight: "10px" }}
          />
          <span>Logout</span>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  useEffect(() => {
    if (!subscriptions?.length) {
      dispatch(ProfileActions.getSubscriptionRequest());
    }
  }, []);

  useEffect(() => {
    if (!loginState.userData) {
      dispatch(LoginActions.getUserDataRequest());
      dispatch(LoginActions.getSubsCountRequest());
    }
    if (window) {
      console.log("Window", window);
      let path;
      // let path =
      //   window.location.pathname == "/jobmatchresults"
      //     ? "/jobmatch"
      //     : window.location.pathname;
      if (window.location.pathname == "/jobmatchresults") {
        path = "/jobmatch";
      } else if (window.location.pathname == "/OfferEvaluatorResult") {
        path = "/offerEvaluator";
      } else {
        path = window.location.pathname;
      }
      if (window.location.pathname) {
        let strArr = window.location.pathname.split("/");
        if (strArr[1] === "resumeOptimizer") {
          path = "/resumeVault";
        }
      }
      if (location.state && location.state.screen === "dashboardJobTracker") {
        path = "/jobTracker";
      }
      setCurrentPath(path);
    }
  }, [location]);

  useEffect(() => {
    const userId = localStorage.getItem("unnlockUserID");
    const refreshToken = localStorage.getItem("unnlockRefreshToken");
    const idToken = localStorage.getItem("unnlockUserToken");
    if (userId && refreshToken) {
      const decoded = jwt_decode(idToken);
      const date = new Date(0);
      const tokExpiryDate = date.setUTCSeconds(decoded.exp);

      if (tokExpiryDate.valueOf() < new Date().valueOf()) {
        dispatch(
          LoginActions.refreshLoginRequest({
            user_id: userId,
            refresh_token: refreshToken,
          })
        );
      }
    }
  }, []);

  const CurrentPage = [...upperData, ...lowerData].find(
    (item) => item.link === currentPath
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#4389FA",
        width: "100%",
      }}
    >
      <div
        className="newHeaderSubDiv"
      // style={{
      //   maxWidth: "1420px",
      //   display: "flex",
      //   justifyContent: "space-between",
      //   alignItems: "center",
      //   padding: "12px 30px",
      //   width: "100%",
      // }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={UnnlockNew}
            alt="UnnlockNew"
            className="unlockImg"
            onClick={() => {
              // if (
              //   localStorage.getItem("unnlockUserToken") ||
              //   window.location.pathname !== "/registration" ||
              //   window.location.pathname !== "/onBoarding"
              // )
              history.push({
                pathname: `/dashboard`,
              });
            }}
          />
          {history.location.pathname === "/dashboard" ? null : (
            <>
              {CurrentPage ? (
                <>
                  <img
                    src={devider}
                    alt="devider"
                    className="dividerIcon"
                  />
                  <img
                    src={
                      [...upperData, ...lowerData].find(
                        (item) => item.link === currentPath
                      )?.activeSrc
                    }
                    alt="current path logo"
                    style={{ marginRight: "5px", width: "25px" }}
                  />

                  <Dropdown overlay={menu}>
                    <div
                      style={{
                        color: "white",
                        cursor: "pointer",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      {CurrentPage?.label}{" "}
                      <img
                        src={arrowDown}
                        alt="arrowDown"
                        style={{ marginLeft: "5px", width: "10px" }}
                      />
                    </div>
                  </Dropdown>
                </>
              ) : null}
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {currentSub.id === 0 &&
            history.location.pathname !== "/subscriptions" &&
            history.location.pathname !== "/coupon" ? (
            <div className="details_btn">
              <img src={Crown} alt="crown" />
              <p className="desctxt">
                You are using a free preview account. Upgrade to get unlimited
                access to unnlock features
              </p>

              <p
                className="moewDetailsBtn"
                onClick={() => {
                  history.push("/subscriptions");
                }}
              >
                Subscribe
              </p>
            </div>
          ) : null}
          {history.location.pathname === "/dashboard" ? null : (
            <div
              className="headerHomeIconDiv"
              onClick={() => history.push("/dashboard")}
            >
              <img src={home} alt="home" style={{ width: "16px" }} />
            </div>
          )}
          <Dropdown overlay={profMenu} placement="bottomRight" arrow>
            <div style={{ position: "relative" }}>
              {loginState.userData?.email_verified ? null : (
                <img
                  src={activeRedIcon}
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "-3px",
                    width: "12px",
                  }}
                />
              )}
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                }}
              >
                <img src={Gear} alt="settings" style={{ width: "17px" }} />
              </div>
            </div>
          </Dropdown>
          {/* <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => signoutHandler()}
        >
          <Tooltip placement="left" title="Logout">
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
              }}
            >
              <img src={Gear} alt="settings" style={{ width: "17px" }} />
            </div>
          </Tooltip>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
