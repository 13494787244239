/* eslint-disable */
import * as types from "./types";

function compare(a, b) {
  if (a.sequence < b.sequence) {
    return -1;
  }
  if (a.sequence > b.sequence) {
    return 1;
  }
  return 0;
}

const sort = (arrData) => {
  return arrData.sort(compare);
};

const INITIAL_STATE = {
  Loader: false,
  faverateLoader: false,
  socLoader: false,
  filteredCompanyList: [],
  filteresList: [],
  nearestComponiesList: [],
  autoComplet: [],
  appliedFilteres: {},
  dashboardCompanyDetails: "",
  companyDeatils: "",
  companyDeatilsErr: "",
  selectedCompanyId: "",
  companyMatch: "",
  industryMatch: "",
  isFailed: false,
  hardSoftCertSocsTxt: "",
  hard_skillsSocArray: [],
  componiesByLocation: [],
  soft_skillsSocArray: [],
  certificationSocsArray: [],
  companyLocations: [],
  exploreSelectedSkills: [],
  compnayLocationsLoader: false,
  selectedLocation: "",
  dashboardCompnayDetailsLoader: false,
  offerEvalIndustryMatch: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COMPANY_LOADER:
      return {
        ...state,
        Loader: action.payload,
      };
    case types.COMPANIESSEARCH_LIST_SUCCESS:
      return {
        ...state,
        filteredCompanyList: Array.isArray(action.payload.companies)
          ? sort(action.payload.companies)
          : [],
        filteresList: {
          companies: action.payload.companies,
          industry_list: action.payload.industry_list,
          location_list: action.payload.location_list,
          size_list: action.payload.size_list,
        },
        appliedFilteres: {},
        companyMatch: action.payload.company_match,
        industryMatch: action.payload.industry_match,
      };
    case types.ADD_FILTERES:
      return {
        ...state,
        appliedFilteres: action.payload,
      };
    case types.SELECTED_COMPANY_ID:
      return {
        ...state,
        selectedCompanyId: action.payload,
      };

    case types.HARD_SOFT_CERT_TXT:
      return {
        ...state,
        hardSoftCertSocsTxt: action.payload,
      };

    case types.FAVOURITE_REQUEST:
      return {
        ...state,
        faverateLoader: true,
      };
    case types.FAVOURITE_SUCCESS:
      return {
        ...state,
        faverateLoader: false,
        // filteredCompanyList: state.filteredCompanyList.map((item) => {
        //   if (item.unnlock_id === action.payload.companyId) {
        //     return {
        //       ...item,
        //       favourite: action.payload.operation === "add" ? true : false,
        //     };
        //   }
        //   return item;
        // }),
      };
    case types.FAVOURITE_FAILURE:
      return {
        ...state,
        faverateLoader: false,
      };
    case types.GET_NEAREST_COMPONIES_REQUEST:
      return {
        ...state,
        Loader: true,
      };
    case types.GET_NEAREST_COMPONIES_SUCCESS:
      return {
        ...state,
        Loader: false,
        nearestComponiesList: action.payload,
      };
    case types.GET_NEAREST_COMPONIES_FAILURE:
      return {
        ...state,
        Loader: false,
      };
    case types.GET_COMPANY_BY_LOCATIONS_REQUEST:
      return {
        ...state,
        Loader: true,
        selectedLocation: action.payload?.city,
      };
    case types.GET_COMPANY_BY_LOCATIONS_SUCCESS:
      return {
        ...state,
        Loader: false,
        // componiesByLocation: ,
        appliedFilteres: {
          ...state.appliedFilteres,
          location_list: Array.isArray(action.payload?.companies)
            ? action.payload.companies.map((item) => item.unnlock_id)
            : [],
        },
      };
    case types.GET_COMPANY_BY_LOCATIONS_FAILURE:
      return {
        ...state,
        componiesByLocation: [],
        selectedLocation: "",
        Loader: false,
      };
    case types.GET_AUTOCOMPLETE_REQUEST:
      return {
        ...state,
        Loader: true,
        autoComplet: [],
      };
    case types.GET_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        Loader: false,
        autoComplet: action.payload,
      };
    case types.GET_AUTOCOMPLETE_FAILURE:
      return {
        ...state,
        Loader: false,
      };
    case types.RESET_AUTOCOMPLETE:
      return {
        ...state,
        autoComplet: [],
      };

    case types.GET_COMPANY_DEATILS_REQUEST:
      return {
        ...state,
        Loader: true,
        companyDeatils: "",
        companyDeatilsErr: "",
      };
    case types.GET_COMPANY_DEATILS_SUCCESS:
      return {
        ...state,
        Loader: false,
        companyDeatils: action.payload[0],
        companyDeatilsErr: "",
      };
    case types.GET_COMPANY_DEATILS_FAILURE:
      return {
        ...state,
        Loader: false,
        companyDeatils: "",
        companyDeatilsErr: action.payload,
      };

    case types.GET_SOCS_SKILLS_REQUEST:
      return {
        ...state,
        socLoader: true,
      };
    case types.GET_SOCS_SKILLS_SUCCESS:
      return {
        ...state,
        socLoader: false,
        [action.payload.type]: action.payload.result,
        // hardSkillsSocsArray:
        //   state.hardSoftCertSocsTxt != "" &&
        //   state.hardSoftCertSocsTxt === "hard_skills"
        //     ? action.payload
        //     : state.hardSkillsSocsArray,
        // softSkillsSocsArray:
        //   state.hardSoftCertSocsTxt != "" &&
        //   state.hardSoftCertSocsTxt === "soft_skills"
        //     ? action.payload
        //     : state.softSkillsSocsArray,
        // certificationSocsArray:
        //   state.hardSoftCertSocsTxt != "" &&
        //   state.hardSoftCertSocsTxt === "certifications"
        //     ? action.payload
        //     : state.certificationSocsArray,
      };
    case types.GET_SOCS_SKILLS_FAILURE:
      return {
        ...state,
        socLoader: false,
      };

    case types.GET_MATCH_INDUSTRY_REQUEST:
      return {
        ...state,
        Loader: true,
      };
    case types.GET_MATCH_INDUSTRY_SUCCESS:
      return {
        ...state,
        Loader: false,
        offerEvalIndustryMatch: action.payload,
      };
    case types.GET_MATCH_INDUSTRY_FAILURE:
      return {
        ...state,
        Loader: false,
      };

    case types.UPDATE_TOPCOMPONIES_REQUEST:
      return {
        ...state,
        Loader: true,
      };
    case types.UPDATE_TOPCOMPONIES_SUCCESS:
      return {
        ...state,
        Loader: false,
      };
    case types.UPDATE_TOPCOMPONIES_FAILURE:
      return {
        ...state,
        Loader: false,
      };

    case types.GET_DASHBOARD_COMPANY_DEATILS_REQUEST:
      return {
        ...state,
        dashboardCompnayDetailsLoader: true,
      };
    case types.GET_DASHBOARD_COMPANY_DEATILS_SUCCESS:
      return {
        ...state,
        dashboardCompnayDetailsLoader: false,
        dashboardCompanyDetails: action.payload,
        isFailed: false,
      };
    case types.GET_DASHBOARD_COMPANY_DEATILS_FAILURE:
      return {
        ...state,
        dashboardCompnayDetailsLoader: false,
        dashboardCompanyDetails: "",
        isFailed: true,
      };

    case types.SEARCH_BY_SKILLS_REQUEST:
      return {
        ...state,
        Loader: true,
      };
    case types.SEARCH_BY_SKILLS_SUCCESS:
      return {
        ...state,
        Loader: false,
        // dashboardCompanyDetails: action.payload,
        isFailed: false,
      };
    case types.SEARCH_BY_SKILLS_FAILURE:
      return {
        ...state,
        Loader: false,
        isFailed: true,
      };

    case types.GET_COMPANY_LOCATIONS_REQUEST:
      return {
        ...state,
        compnayLocationsLoader: true,
      };
    case types.GET_COMPANY_LOCATIONS_SUCCESS:
      return {
        ...state,
        compnayLocationsLoader: false,
        companyLocations: action.payload,
        isFailed: false,
      };
    case types.GET_COMPANY_LOCATIONS_FAILURE:
      return {
        ...state,
        compnayLocationsLoader: false,
        isFailed: true,
      };

    case types.SET_EXPLORE_SKILLS:
      return {
        ...state,
        exploreSelectedSkills: action.payload,
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
