import React, { useState } from "react";

import { Row, Col, Layout, Spin } from "antd";
import NewHeader from "../../components/newHeader";

import tipsMinus from "../../assets/images/tipsMinus.svg";
import tipsPlus from "../../assets/images/tipsPlus.svg";

import "./TipsAndFAQs.scss";
import UnnlockLayout from "../layout";

const { Content } = Layout;

const arr = [
  { title: "What is Lorem Ipsum?", desc: "Decription of What is Lorem Ipsum?" },
  {
    title: "Why do we use it?",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    title: "Where does it come from?",
    desc: "Deswscription of Where does it come from?",
  },
  {
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
    desc: "Description of Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
  },
  {
    title: "Contrary to popular belief, Lorem Ipsum is not simply random text?",
    desc: "Description of Contrary to popular belief, Lorem Ipsum is not simply random text?",
  },
  {
    title:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested?",
    desc: " Description of The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested?",
  },
];

const TipsAndFAQs = () => {
  const [clickExpand, setclickExpand] = useState(false);
  const [clickIndex, setclickIndex] = useState(-1);

  const getImage = (index) => {
    if (clickExpand && clickIndex === index) {
      return tipsMinus;
    }
    return tipsPlus;
  };

  return (
    <UnnlockLayout>
      <Content style={{ padding: "32px" }}>
        <Row>
          <p className="tipsFAQsTitle">Tips And FAQs</p>
        </Row>
        <Row>
          {arr.map((obj, index) => (
            <Col span={16} offset={4} className="cardSection">
              <Row justify="space-between">
                <Col>
                  <p className="title">{obj.title}</p>
                </Col>
                <Col>
                  <img
                    src={getImage(index)}
                    alt="tipsPlus"
                    onClick={() => {
                      setclickIndex(index);
                      setclickExpand(!clickExpand);
                    }}
                  />
                </Col>
              </Row>
              {clickExpand && clickIndex === index && (
                <Row>
                  <p className="desc">{obj.desc}</p>
                </Row>
              )}
            </Col>
          ))}
        </Row>
      </Content>
    </UnnlockLayout>
  );
};

export default TipsAndFAQs;
