import * as types from "./toolsTypes";

export const postMatchJobRequest = (payload) => ({
  type: types.POST_MATCHJOB_REQUEST,
  payload,
});

export const postMatchJobSuccess = (payload) => ({
  type: types.POST_MATCHJOB_SUCCESS,
  payload,
});

export const reset = () => ({
  type: types.RESET,
});

export const postMatchJobFailure = (payload) => ({
  type: types.POST_MATCHJOB_FAILURE,
  payload,
});

export const setToolsLoader = (payload) => ({
  type: types.SET_TOOL_LOADER,
  payload,
});

export const setFormData = (payload) => ({
  type: types.SET_FORM_DATA,
  payload,
});

export const getSalaryComponsation = (payload) => ({
  type: types.GET_SAL_COMP_REQUEST,
  payload,
});

export const getSalaryComponsationSuccess = (payload) => ({
  type: types.GET_SAL_COMP_SUCCESS,
  payload,
});

export const getSalaryComponsationFailure = (payload) => ({
  type: types.GET_SAL_COMP_FAILURE,
  payload,
});

export const getSkillsfromDesig = (payload) => ({
  type: types.GET_SKILL_DESIG_REQUEST,
  payload,
});

export const getSkillsfromDesigSuccess = (payload) => ({
  type: types.GET_SKILL_DESIG_SUCCESS,
  payload,
});

export const getSkillsfromDesigFailure = (payload) => ({
  type: types.GET_SKILL_DESIG_FAILURE,
  payload,
});

export const postCompanyURLS = (payload) => ({
  type: types.POSTCOMP_URLS_REQUEST,
  payload,
});

export const postCompanyURLSsuccess = (payload) => ({
  type: types.POSTCOMP_URLS_SUCCESS,
  payload,
});

export const postCompanyURLSFailure = (payload) => ({
  type: types.POSTCOMP_URLS_FAILURE,
  payload,
});

export const getMatchingSkills = (payload) => ({
  type: types.GETMATCHING_SKILLS_REQUEST,
  payload,
});

export const getMatchingSkillsSuccess = (payload) => ({
  type: types.GETMATCHING_SKILLS_SUCCESS,
  payload,
});

export const getMatchingSkillsFailure = (payload) => ({
  type: types.GETMATCHING_SKILLS_FAILURE,
  payload,
});

export const getOpenJobs = (payload) => ({
  type: types.GET_OPEN_JOBS_REQUEST,
  payload,
});

export const getOpenJobsSuccess = (payload) => ({
  type: types.GET_OPEN_JOBS_SUCCESS,
  payload,
});

export const getOpenJobsFailure = (payload) => ({
  type: types.GET_OPEN_JOBS_FAILURE,
  payload,
});

export const getChatBotAnswers = (payload) => ({
  type: types.GET_CHATBOT_ANSWER_REQUEST,
  payload,
});

export const getChatBotAnswersSuccess = (payload) => ({
  type: types.GET_CHATBOT_ANSWER_SUCCESS,
  payload,
});

export const getChatBotAnswersFailure = (payload) => ({
  type: types.GET_CHATBOT_ANSWER_FAILURE,
  payload,
});

export const getJobMatchFit = (payload) => ({
  type: types.GET_JOBMATCH_FIT_REQUEST,
  payload,
});

export const getJobMatchFitSuccess = (payload) => ({
  type: types.GET_JOBMATCH_FIT_SUCCESS,
  payload,
});

export const getJobMatchFitFailure = (payload) => ({
  type: types.GET_JOBMATCH_FIT_FAILURE,
  payload,
});

export const getJobMatchSuggestion = (payload) => ({
  type: types.GET_JOBMATCH_SUGGESTION_REQUEST,
  payload,
});

export const getJobMatchSuggestionSuccess = (payload) => ({
  type: types.GET_JOBMATCH_SUGGESTION_SUCCESS,
  payload,
});

export const getJobMatchSuggestionFailure = (payload) => ({
  type: types.GET_JOBMATCH_SUGGESTION_FAILURE,
  payload,
});

export const getAiSearchedJobs = (payload) => ({
  type: types.GET_AI_SEARCHED_JOB_REQUEST,
  payload,
});

export const getAiSearchedJobsSuccess = (payload) => ({
  type: types.GET_AI_SEARCHED_JOB_SUCCESS,
  payload,
});

export const getAiSearchedJobsFailure = (payload) => ({
  type: types.GET_AI_SEARCHED_JOB_FAILURE,
  payload,
});

export const geOpenJobChatBotAnswers = (payload) => ({
  type: types.GET_OPEN_JOB_CHATBOT_ANSWER_REQUEST,
  payload,
});

export const geOpenJobChatBotAnswersSuccess = (payload) => ({
  type: types.GET_OPEN_JOB_CHATBOT_ANSWER_SUCCESS,
  payload,
});

export const geOpenJobChatBotAnswersFailure = (payload) => ({
  type: types.GET_OPEN_JOB_CHATBOT_ANSWER_FAILURE,
  payload,
});

export const getChatBotQuestions = (payload) => ({
  type: types.GET_CHAT_BOT_QUESTIOS_REQUEST,
  payload,
});

export const getChatBotQuestionsSuccess = (payload) => ({
  type: types.GET_CHAT_BOT_QUESTIOS_SUCCESS,
  payload,
});

export const getChatBotQuestionsFailure = (payload) => ({
  type: types.GET_CHAT_BOT_QUESTIOS_FAILURE,
  payload,
});

export const getCoverLetter = (payload) => ({
  type: types.GET_COVER_LETTER_REQUEST,
  payload,
});

export const getCoverLetterSuccess = (payload) => ({
  type: types.GET_COVER_LETTER_SUCCESS,
  payload,
});

export const getCoverLetterFailure = (payload) => ({
  type: types.GET_COVER_LETTER_FAILURE,
  payload,
});

export const setOpenJobsResume = (payload) => ({
  type: types.SET_SELECTED_OPENJOBS_RESUME,
  payload,
});

export const setOpenJobFilters = (payload) => ({
  type: types.SET_OPENJOB_FILTERS,
  payload,
});

export const setOpenJobcities = (payload) => ({
  type: types.SET_OPENJOB_CITIES,
  payload,
});

export const setShowCookies = (payload) => ({
  type: types.SET_SHOW_COOKIES,
  payload,
});
