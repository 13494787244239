import React from "react";
import PropTypes from "prop-types";
import "./input.scss";

const InputWithIcon = ({
  label,
  value,
  handleChange,
  icon,
  handleIconClick,
}) => {
  return (
    <div className="textOnInput">
      <p className="label">{label}</p>
      <input
        className="inputwithicon_custom"
        type="text"
        onChange={handleChange}
        value={value}
      />
      <div
        className="icon_holder"
        onClick={handleIconClick}
        onKeyDown={handleIconClick}
        role="button"
        tabIndex={0}
      >
        <img src={icon} alt="icon" />
      </div>
    </div>
  );
};

InputWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.string,
  handleIconClick: PropTypes.string,
};

InputWithIcon.defaultProps = {
  value: false,
  handleChange: () => {},
  handleIconClick: () => {},
};

export default InputWithIcon;
