import React from "react";
import { Layout } from "antd";
import MiinedHeader from "../../components/Header";

import "./Recruiters.scss";

const { Content } = Layout;

const Recruiters = () => {
  return (
    <Layout className="recruiters-container">
      <MiinedHeader />
      <Content>
        <h1>Recruiters Window</h1>
      </Content>
    </Layout>
  );
};

export default Recruiters;
