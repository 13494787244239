import React from "react";
import { Button, Input, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import InputWithLabel from "../../Profile/inputwithLabel";
import "./MatchToJobPosting.scss";
import Tools from "../Tools";
import MatchToJobCommonComp from "./matchTojobCommonComp";
import * as ToolsActions from "../../../store/Tools/toolsActions";

const { TextArea } = Input;

const MatchToJobPosting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.tools.loading);
  const loginState = useSelector((state) => state.login);

  const handleCheck = (values) => {
    const formData = {
      company_name: values.companyname,
      company_url: values.url,
      job_title: values.title,
      text: values.text,
      required_experience: values.experience,
    };
    const primaryResumeId = loginState.userData.primary_resume;
    dispatch(ToolsActions.setFormData(values));
    dispatch(ToolsActions.postMatchJobRequest({ formData, primaryResumeId }));
    history.push("/matchjobResults");
  };

  const initialValues = {
    companyname: "",
    url: "",
    title: "",
    text: "",
    experience: "",
  };

  const validation = Yup.object().shape({
    companyname: Yup.string().required("name is required"),
    url: Yup.string().required("url is required"),
    title: Yup.string().required("title is required"),
    text: Yup.string().required("description is required"),
    experience: Yup.string().required("experience is required"),
  });

  return (
    <Tools>
      <Spin size="large" spinning={isLoading}>
        <MatchToJobCommonComp>
          <h4 className="container_form_header">
            Fill in the below details to find out the match
          </h4>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validation}
            onSubmit={(values) => {
              handleCheck(values);
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div className="input_holders_section">
                  <div className="item_input">
                    <InputWithLabel
                      label="Company Name"
                      value={values.companyname}
                      handleChange={handleChange}
                      name="companyname"
                    />
                    {errors &&
                      errors.companyname &&
                      touched &&
                      touched.companyname && (
                        <p className="error_field">{errors.companyname}</p>
                      )}
                  </div>
                  <div className="item_input">
                    <InputWithLabel
                      label="Company Url"
                      value={values.url}
                      handleChange={handleChange}
                      name="url"
                    />
                    {errors && errors.url && touched && touched.url && (
                      <p className="error_field">{errors.url}</p>
                    )}
                  </div>
                  <div className="item_input">
                    <InputWithLabel
                      label="Job Title"
                      value={values.title}
                      handleChange={handleChange}
                      name="title"
                    />
                    {errors && errors.title && touched && touched.title && (
                      <p className="error_field">{errors.title}</p>
                    )}
                  </div>
                  <div className="item_input">
                    <InputWithLabel
                      label="Experience"
                      type="number"
                      value={values.experience}
                      handleChange={handleChange}
                      name="experience"
                    />
                    {errors &&
                      errors.experience &&
                      touched &&
                      touched.experience && (
                        <p className="error_field">{errors.experience}</p>
                      )}
                  </div>
                </div>
                <div className="text_area_section">
                  <TextArea
                    className="text_item"
                    name="text"
                    value={values.text}
                    onChange={handleChange}
                    placeholder="Job Description"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                  />
                  {errors && errors.text && touched && touched.text && (
                    <p className="error_field">{errors.text}</p>
                  )}
                </div>
                <Button
                  type="primary"
                  shape="round"
                  ghost
                  className="button_submit"
                  onClick={() => {
                    document.getElementById("submit_Checknow").click();
                  }}
                >
                  Check Now
                </Button>
                <button
                  type="submit"
                  id="submit_Checknow"
                  style={{ display: "none" }}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </MatchToJobCommonComp>
      </Spin>
    </Tools>
  );
};
export default MatchToJobPosting;
