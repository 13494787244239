import React, { useEffect, useState } from "react";
/* eslint-disable */
import { Modal, Button, Checkbox, Input } from "antd";
import PropTypes from "prop-types";
import "./model.scss";
import InputWithLabel from "./inputwithLabel";
import SelectWithLabel from "./selectwithlabel";
import InputWithIcon from "./inputWithIcon";
import mastercard from "../../assets/images/profile/mastercard.svg";
import InfoCircle from "../../assets/images/profile/Info Circle.svg";
import up from "../../assets/images/profile/arrrowUp.svg";
import down from "../../assets/images/profile/arrowDown.svg";
import signature from "../../assets/images/profile/signature.svg";
import PaymentComp from "./payment/payment";

const { Search } = Input;

const ChangePaymentModel = ({ visible, showBilling, showPromoCode }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [showbillingdrop, setshowbillingdrop] = useState(false);
  const [showcvvinfo, setshowcvvinfo] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  const renderPointedContainer = (billingType) => (
    <div
      className="billingDrop_container"
      style={{
        width: billingType ? "35%" : "100%",
        top: billingType ? "60px" : "30px",
        left: billingType ? "5px" : "28px",
      }}
    >
      <div className="arrow_container">
        <div
          className="arrow-up"
          style={{
            marginRight: billingType ? "25%" : "10%",
          }}
        >
          {" "}
        </div>
      </div>
      {billingType ? (
        <div className="body_items">
          <p>Monthly - $39</p>
          <p>
            Annually - $ 79
            <span>Save 10%</span>
          </p>
        </div>
      ) : (
        <div className="body_items">
          <p>
            3 or 4 digit number that can been on the back of your card near your
            signature
          </p>
          <div className="signature_holder">
            <img src={signature} alt="manwalking" className="sig_img" />
            <div className="cvv">234</div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      title="Basic Modal"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="change_payment_model"
      width={800}
    >
      <div className="title">Change payment method</div>
      <div className="custom_model_contednt">
        <div className="model_body">
          {showBilling ? (
            <div className="billing_container">
              <div className="left_side">
                <div
                  className="title"
                  onClick={() => {
                    setshowbillingdrop(!showbillingdrop);
                  }}
                  onKeyDown={() => {
                    setshowbillingdrop(!showbillingdrop);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Billed monthly
                  {showbillingdrop ? (
                    <img src={down} alt="manwalking" className="arrow_img" />
                  ) : (
                    <img src={up} alt="manwalking" className="arrow_img" />
                  )}
                </div>

                <div className="discount">Save 10% with annual billing</div>
                {showbillingdrop ? renderPointedContainer(true) : null}
              </div>
              <div className="right_side">
                <div className="amount">$39</div>
                <div className="per_mnth">per month</div>
              </div>
            </div>
          ) : null}
          {showPromoCode ? (
            <div className="propmoinput_holder">
              <Search
                placeholder="Enter Promo Code"
                allowClear
                enterButton="APPLY"
                size="small"
                onSearch={() => console.log("hi")}
                className="Custnom_input_withbtn"
              />
            </div>
          ) : null}

          {/* <div className="inputs_container">
            <div className="payment_title">Payment Details</div>
            <div className="input_container">
              <SelectWithLabel
                label="Payment type"
                value="Debit / Credit Cards"
              />
            </div>
            <div className="input_container">
              <InputWithLabel label="Name on card" value="Samuil S" />
            </div>
            <div className="input_container">
              <InputWithIcon
                label="Card Number"
                value="13456789"
                icon={mastercard}
              />
            </div>
            <div className="displ_singleline">
              <div className="input_container2">
                <InputWithLabel
                  label="Expiry Date"
                  value="Samuil S"
                  type="date"
                />
              </div>
              <div className="input_container2">
                <InputWithIcon
                  label="CVV"
                  value="13456789"
                  icon={InfoCircle}
                  handleIconClick={() => setshowcvvinfo(!showcvvinfo)}
                />
                {showcvvinfo ? renderPointedContainer(false) : null}
              </div>
            </div>
            <div className="check_container">
              <Checkbox>Save card</Checkbox>
            </div>
          </div>
          <div className="model_footer">
            <Button type="primary" className="pay_btn">
              Pay $39
            </Button>
            <div className="stripe">powered by Stripe</div>
          </div> */}
          <PaymentComp />
        </div>
      </div>
    </Modal>
  );
};

ChangePaymentModel.propTypes = {
  visible: PropTypes.bool.isRequired,
  showBilling: PropTypes.bool.isRequired,
  showPromoCode: PropTypes.bool.isRequired,
};

export default ChangePaymentModel;
