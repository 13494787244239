import React from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import UnnlockLayout from "../layout";
import history from "../../routes/History";
import personImg from "../../assets/images/person.png";
import * as LoginActions from "../../store/newLogin/newLoginAction";

import "./ForgotPassword.scss";
import CommonInput from "../ICDProvider/commonInput";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const handleForgotPass = (data) => {
    dispatch(
      LoginActions.forgotPassRequest({
        email: data.email,
      })
    );
  };

  return (
    <UnnlockLayout dontDisplayHeader={true}>
      <div className="forgotpass-container">
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <h2 style={{ fontWeight: "600" }}>unnlock</h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "10px 30px",
          }}
        >
          <div className="login-section">
            <h2 className="forgot_text">Forgot Password?</h2>
            <p className="desctext">
              We will send a reset password link to to your registered mail
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                // console.log(values, "export default");
                handleForgotPass(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <CommonInput formField name="email" placeholder="Email" />
                  <div
                    className="error_fields"
                    style={{
                      color: touched.email && errors.email ? "" : "transparent",
                    }}
                  >
                    {errors?.email}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <button type="submit" className="login_btn">
                      Send link
                    </button>
                  </div>
                  <p
                    className="backtext"
                    onClick={() => {
                      history.push("/registration");
                    }}
                  >
                    Back to Login page
                  </p>
                </Form>
              )}
            </Formik>
          </div>
          <div className="right_side">
            <h2 style={{ fontWeight: 600 }}>
              Identify and win the right career opportunities.
            </h2>
            <p style={{ margin: "40px 0px" }}>
              Dreamers and visionaries from over 100 countries use miined. It's
              time for you to join them. Get a sneak peek at your future!
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                className="tryFree_button"
                onClick={() => {
                  history.push("/registration");
                }}
              >
                Try unnlock for free
              </button>
              <button
                className="subscrption_btn"
                onClick={() => history.push("/plans")}
              >
                View Subscription Plans
              </button>
            </div>
          </div>
        </div>
        <img className="person-img_login" src={personImg} alt="person" />
        <div className="footer_login" />
      </div>
    </UnnlockLayout>
  );
};

export default ForgotPassword;
