import React, { useEffect, useState } from "react";
import { Progress } from "antd";

const CustomProgessBar = () => {
  const step = 10
  const interval = 1000
  const maxProgress = 100
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    const updateProgress = () => setProgressPercentage(progressPercentage + step)
    if (progressPercentage < maxProgress) {
      setTimeout(updateProgress, interval)
    }
  }, [progressPercentage]);

  return (
    <Progress
      strokeColor={{
        "0%": "#4389FA",
        "100%": "#261C4B",
      }}
      strokeWidth={4}
      percent={progressPercentage}
    />
  )
}

export default CustomProgessBar;