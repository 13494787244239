import React from "react";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./commonInput.scss";

const { Option } = Select;

const IndustryDropDown = ({ placeholder, value, handleChange, styles }) => {
  const [industries, setIndustries] = useState([]);
  const [selected, setselected] = useState(undefined);
  const [showDropDown, setShowDropDown] = useState(false);
  const dashboardState = useSelector((state) => state.dashboard);

  const handleSearch = (newValue) => {
    console.log(newValue);
    if (
      newValue &&
      Array.isArray(dashboardState?.companyFilteres?.industry_list)
    ) {
      setIndustries(
        [...dashboardState.companyFilteres.industry_list].filter((item) => {
          if (item.toUpperCase().indexOf(newValue.toUpperCase()) > -1) {
            return true;
          } else {
            return false;
          }
        })
      );
    } else {
      setIndustries(dashboardState.companyFilteres?.industry_list || []);
    }
  };

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
    setIndustries(dashboardState.companyFilteres?.industry_list || []);
  };

  useEffect(() => {
    if (dashboardState.companyFilteres?.industry_list) {
      setIndustries(dashboardState.companyFilteres?.industry_list || []);
    }
  }, [dashboardState.companyFilteres]);

  const options = industries.map((d) => <Option key={d}>{d}</Option>);

  return (
    <div className="Common_input_container" style={styles}>
      <Select
        className="companyInput"
        showSearch
        value={value || selected}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={(d) => {
          console.log(d, "Common_input_container");
          handleChange(d);
          setselected(d);
          toggleDropDown();
        }}
        notFoundContent={null}
      >
        {options}
      </Select>
    </div>
  );
};

export default IndustryDropDown;
