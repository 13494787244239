import React, { useState, useEffect } from "react";
import "./index.scss";
import history from "../../routes/History";
import { useDispatch, useSelector } from "react-redux";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import * as DashBoardActions from "../../store/dashBoard/dashboardActions";
import ConfirmYourCompanyCard from "./confirmYourCompanyCard";
import CompanyDescCard from "./companyDescCard";
import CompanyNotFoundCard from "./companyNotfoundCard";
import UnnlockLayout from "../layout";
import { saveCompanyDatailsRequest } from "../../store/dashBoard/dashboardActions";
// import bulidingIcon from "../../assets/images/dashboard/buliding.svg";
import titleImage from "../../assets/images/UnnlockNew.svg";
import {
  getDashboardComponyDeatailsFailure,
  resetutoComplete,
} from "../../store/componies/action";
import iconChecked from "../../assets/images/dashboard/checked.svg";
import iconUnChecked from "../../assets/images/dashboard/unchecked.svg";
import OnboardingHeader from "../../components/OnboardingHeader";
import { GetOnboardSuggestions } from "../ResumeOpimizer/suggestions";
import unnlockNotification from "../../components/notification";
import { savePreUploadData } from "../../store/newRegister/registerActions";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";
import whitebackarrow from "../../assets/images/whitebackarrow.svg";

const ICDP = () => {
  const [companyNotFound, setCompanyNotFound] = useState(0);
  const [companyNotFound2, setCompanyNotFound2] = useState(0);

  const [currentScreen, setcurrentScreen] = useState(1);
  const [selectedComp, setselectedComp] = useState({
    unnlock_id: "",
    name: "",
    company_to_recommend: "",
    size: "",
    url: "",
    industry: "",
    company_type: "",
    location: "",
  });
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const dashboardState = useSelector((state) => state.dashboard);
  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );
  const companyLoader = useSelector(
    (state) => state.componies.dashboardCompnayDetailsLoader
  );

  useEffect(() => {
    if (loginState.userData && loginState.userData.current_company) {
      //history.push("/dashboard");
      history.push("/lastStep");
    }
  }, [loginState]);

  useEffect(() => {
    document.title = "Confirm last company";
    dispatch(DashBoardActions.getCompanyFilteresRequest());
    dispatch(savePreUploadData(""));
    if (!loginState.userData) {
      dispatch(LoginActions.getUserDataRequest());
    }
  }, []);

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.primary_resume &&
      !dashboardState.employementInfo?.employment[0]?.designation
    ) {
      dispatch(
        DashBoardActions.resumeParserRequest(loginState.userData.primary_resume)
      );
    }
    if (loginState?.userData?.onboarding_complete) {
      history.push("/dashboard");
    }
  }, [loginState]);

  useEffect(() => {
    if (companyDetails && companyDetails?.length)
      if (currentScreen === 2) {
        setselectedComp({
          ...selectedComp,
          unnlock_id: "",
          industry: companyDetails[0]?.industry,
          location: companyDetails[0]?.hq_full_address,
          size: companyDetails[0]?.company_size,
          company_type: companyDetails[0]?.type,
          url: companyDetails[0]?.company_web_url,
          company_to_recommend: companyDetails[0]?.unnlock_id,
        });
      } else {
        setselectedComp({
          unnlock_id: companyDetails[0]?.unnlock_id,
          name: companyDetails[0]?.company,
          company_to_recommend: companyDetails[0]?.unnlock_id,
          size: companyDetails[0]?.company_size,
          url: companyDetails[0]?.company_web_url,
          industry: companyDetails[0]?.industry,
          company_type: companyDetails[0]?.type,
          location: companyDetails[0]?.hq_full_address,
        });
      }
  }, [companyDetails]);

  const handleSubmit = () => {
    let postObject = {
      current_company: {
        ...selectedComp,
      },
      favourite_companies: [
        `${selectedComp.unnlock_id || selectedComp.company_to_recommend}`,
      ],
    };
    if (currentScreen === 2)
      if (!postObject.current_company.name) {
        unnlockNotification("Company name is required!", "warning");
        // } else if (!postObject.current_company.size) {
        //   unnlockNotification("Company size is required!", "warning");
        // } else if (!postObject.current_company.url) {
        //   unnlockNotification("Company website url is required!", "warning");
        // } else if (!postObject.current_company.industry) {
        //   unnlockNotification("Company industry is required!", "warning");
        // } else if (!postObject.current_company.company_type) {
        //   unnlockNotification("Company type is required!", "warning");
      } else if (!postObject.current_company.company_to_recommend) {
        unnlockNotification("Company to recommend is required!", "warning");
      } else if (companyNotFound2 != 1) {
        unnlockNotification("Confirm the company!", "warning");
      } else {
        dispatch(saveCompanyDatailsRequest(postObject));
      }
    else dispatch(saveCompanyDatailsRequest(postObject));
  };

  // const checkDisabled = () => {
  //   if (
  //     !companyNotFound ||
  //     (currentScreen === 2 &&
  //       (!selectedComp.unnlock_id ||
  //         !selectedComp.company_type ||
  //         !selectedComp.industry ||
  //         !selectedComp.location ||
  //         !selectedComp.name ||
  //         !selectedComp.size ||
  //         !selectedComp.url))
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <UnnlockLayout
      dontDisplayHeader={true}
      optionalHeader={
        <>
          <div className="loginPage_header">
            <div className="loginPage_header_inner">
              <img
                src={titleImage}
                alt="titleImage"
                className="unnlockIcon"
              // onClick={() => {
              //   history.push("/");
              // }}
              />
            </div>
          </div>
          <OnboardingHeader />
        </>
      }
    >
      <div className="ICDP_warapper">
        <div className="ICDP_container">
          {currentScreen == 1 ? (
            <div>
              <div className="companySelection">
                <div className="left">
                  <ConfirmYourCompanyCard />
                  {(companyDetails && companyDetails.length) ||
                    companyLoader ? null : (
                    <div className="confirmmations">
                      <div
                        className={companyNotFound == 2 ? "checked" : "confirm"}
                        onClick={() => {
                          setCompanyNotFound(2);
                        }}
                      >
                        <img
                          src={
                            companyNotFound == 2 ? iconChecked : iconUnChecked
                          }
                        />
                        <p
                          style={{
                            margin: "0px",
                            fontSize: "16px",
                            width: "max-content",
                          }}
                        >
                          Company not found
                        </p>
                      </div>

                      <div className="buttons_container">
                        <div className="buttons_container_inner">
                          <button
                            type="button"
                            className="nxtButton"
                            htmlType="submit"
                            onClick={() => {
                              if (companyNotFound) {
                                if (
                                  companyNotFound === 2 &&
                                  currentScreen === 2
                                ) {
                                  handleSubmit();
                                } else {
                                  if (companyNotFound === 2) {
                                    setcurrentScreen(2);
                                    setselectedComp({
                                      unnlock_id: "",
                                      name: "",
                                      company_to_recommend: "",
                                      size: "",
                                      url: "",
                                      industry: "",
                                      company_type: "",
                                      location: "",
                                    });
                                    dispatch(
                                      getDashboardComponyDeatailsFailure()
                                    );
                                    dispatch(resetutoComplete());
                                  } else {
                                    handleSubmit();
                                  }
                                }
                              } else {
                                unnlockNotification(
                                  "Please confirm your company selection!",
                                  "warning"
                                );
                              }
                            }}
                          >
                            <>
                              Next <img src={WhiteArrowLeft} />
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {(companyDetails && companyDetails.length) ||
                    companyLoader ? (
                    <div>
                      <CompanyDescCard
                        currentScreen={currentScreen}
                        companyNotFound={companyNotFound}
                        onConfirm={() => {
                          if (companyDetails && companyDetails.length) {
                            setCompanyNotFound(1);
                          } else {
                            unnlockNotification(
                              "Please select your company in the above selection!",
                              "warning"
                            );
                          }
                        }}
                      />
                      <div className="confirmmations">
                        <div
                          className={
                            companyNotFound == 2 ? "checked" : "confirm"
                          }
                          onClick={() => {
                            setCompanyNotFound(2);
                          }}
                        >
                          <img
                            src={
                              companyNotFound == 2 ? iconChecked : iconUnChecked
                            }
                          />
                          <p
                            style={{
                              margin: "0",
                              fontSize: "16px",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "16px",
                                width: "max-content",
                              }}
                            >
                              Company not found
                            </p>
                          </p>
                        </div>
                        <div className="buttons_container">
                          <div className="buttons_container_inner">
                            <button
                              type="button"
                              className="nxtButton"
                              htmlType="submit"
                              onClick={() => {
                                if (companyNotFound) {
                                  if (
                                    companyNotFound === 2 &&
                                    currentScreen === 2
                                  ) {
                                    handleSubmit();
                                  } else {
                                    if (companyNotFound === 2) {
                                      setcurrentScreen(2);
                                      setselectedComp({
                                        unnlock_id: "",
                                        name: "",
                                        company_to_recommend: "",
                                        size: "",
                                        url: "",
                                        industry: "",
                                        company_type: "",
                                        location: "",
                                      });
                                      dispatch(
                                        getDashboardComponyDeatailsFailure()
                                      );
                                      dispatch(resetutoComplete());
                                    } else {
                                      handleSubmit();
                                    }
                                  }
                                } else {
                                  unnlockNotification(
                                    "Please confirm your company selection!",
                                    "warning"
                                  );
                                }
                              }}
                            >
                              <>
                                Next <img src={WhiteArrowLeft} />
                              </>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="right">
                  <GetOnboardSuggestions
                    mainTitle="Why"
                    screen="confirmEmployer"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <CompanyNotFoundCard
                  setselectedComp={setselectedComp}
                  selectedComp={selectedComp}
                />

                <div className="companySelection">
                  {companyDetails && companyDetails.length ? (
                    <>
                      <div className="left">
                        <CompanyDescCard
                          currentScreen={currentScreen}
                          companyNotFound2={companyNotFound2}
                          onConfirm2={() => {
                            if (companyDetails && companyDetails.length) {
                              setCompanyNotFound2(1);
                            } else {
                              unnlockNotification(
                                "Please select your company in the above selection!",
                                "warning"
                              );
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="buttons_container_with_back">
                <div className="buttons_container_innerB">
                  {currentScreen === 2 ? (
                    // <div
                    //   onClick={() => {
                    //     setcurrentScreen(1);
                    //     setCompanyNotFound(0);
                    //   }}
                    //   className="back"
                    //   style={{ marginBottom: "0px" }}
                    // >
                    //   <span />
                    //   Back
                    // </div>
                    <button
                      type="button"
                      className="nxtButton"
                      htmlType="submit"
                      style={{ marginRight: "12px" }}
                      onClick={() => {
                        setcurrentScreen(1);
                        setCompanyNotFound(0);
                      }}
                    >
                      <>
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src={WhiteArrowLeft}
                        />{" "}
                        Back
                      </>
                    </button>
                  ) : null}
                  {/* <div
                
                // className={checkDisabled() ? "next-disabled" : "next"}
                className="next"
              >
                Next
              </div> */}
                  <button
                    type="button"
                    className="nxtButton"
                    htmlType="submit"
                    onClick={() => {
                      if (companyNotFound) {
                        if (companyNotFound === 2 && currentScreen === 2) {
                          handleSubmit();
                        } else {
                          if (companyNotFound === 2) {
                            setcurrentScreen(2);
                            setselectedComp({
                              unnlock_id: "",
                              name: "",
                              company_to_recommend: "",
                              size: "",
                              url: "",
                              industry: "",
                              company_type: "",
                              location: "",
                            });
                            dispatch(getDashboardComponyDeatailsFailure());
                            dispatch(resetutoComplete());
                          } else {
                            handleSubmit();
                          }
                        }
                      } else {
                        unnlockNotification(
                          "Please confirm your company selection!",
                          "warning"
                        );
                      }
                    }}
                  >
                    <>
                      Next <img src={WhiteArrowLeft} />
                    </>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </UnnlockLayout>
  );
};

export default ICDP;
