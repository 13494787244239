const englishMessages = {
  en: {
    logo: "unnlock",
    login_to_miined: "Login to miined",
    email: "Email",
    password: "Password",
    forgotPass: " Forgot Password?",
    signup: "Sign Up ?",
    google: "google",
    facebook: "facebook",
    linkedin: "linkedin",
    socialTxt: "Or login with your social media sccounts",
    identifyTxt: "Identify and win the right career opportunities.",
    identifySubTxt: ` Dreamers and visionaries from over 100 countries use miined.
    It's time for you to join them. Get a sneak peek at your
    future!`,
    tryFreeTxt: "Try miined for free",
    viewSubsPlan: "View Subscription Plans",
    already: "Already have an account?",
    login: "Login",
    iagree: "I agree to the",
    registerStartedTxt: "Register to get started with miined",
    registerWithSocial: "Upload Resume to get started with miined",
    minimumcharacters: `Minimum 8 characters with atleast one Numeric, Symbol
    and Uppercase character.`,
    supportedDoc: `Supported doc, docx, rtf, pdf files. Maximum file size
    of 2mb.`,
    terms: "Terms and Conditions",
    privacy: "Privacy Policy",
    browse: "Browse",
    home: "Home",
    resumes: "Resumes",
    companies: "Companies",
    recruiters: "Recruiters",
    tools: "Tools",
    blogs: "Blogs",
    searchCompanies: "Search Companies, Recruiters & Resumes",
    welcometounnlockTest:
      "Welcome to unnlock, We are super excited to have you on board!",
    addthecompTxt: "Add the companies you worked and their official web URLs",
    addNewComp: "Add new Company",
    skippingSection:
      "Skipping this section will yield low accuracy in the dashboard",
    skip: "Skip",
  },
};

export default englishMessages;
