import * as types from "./persanalityTestTypes";

// get questions Actions
export const getQuestionsRequest = () => ({
  type: types.GET_QUESTIONS_REQUEST,
});
export const getQuestionsSuccess = (payload) => ({
  type: types.GET_QUESTIONS_SUCCESS,
  payload,
});
export const getQuestionsFailure = (payload) => ({
  type: types.GET_QUESTIONS_FAILURE,
  payload,
});

// get results Actions
export const getResultsRequest = (payload) => ({
  type: types.GET_RESULTS_REQUEST,
  payload,
});
export const getResultsSuccess = (payload) => ({
  type: types.GET_RESULTS_SUCCESS,
  payload,
});
export const getResultsFailure = (payload) => ({
  type: types.GET_RESULTS_FAILURE,
  payload,
});

// submit results Actions
export const submitResultsRequest = (payload) => ({
  type: types.SUBMIT_RESULTS_REQUEST,
  payload,
});
export const submitResultsSuccess = (payload) => ({
  type: types.SUBMIT_RESULTS_SUCCESS,
  payload,
});
export const submitResultsFailure = (payload) => ({
  type: types.SUBMIT_RESULTS_FAILURE,
  payload,
});

// reset
export const reset = () => ({
  type: types.RESET,
});
