/*eslint-disable*/
import * as Yup from "yup";

export const key = "employment";

export const schema = [
  {
    field: "input",
    label: "Company",
    id: "company",
    type: "text",
  },
  {
    field: "input",
    label: "Designation",
    id: "designation",
    type: "text",
  },
  {
    field: "input",
    label: "Location",
    id: "location",
    type: "text",
  },
  {
    field: "monthnyearpicker",
    label: "From",
    id: "from",
    type: "date",
    grid: 6,
  },
  {
    field: "monthnyearpicker",
    label: "To",
    id: "to",
    type: "date",
    grid: 6,
  },
  {
    field: "input",
    label: "Skills",
    id: "skills",
    type: "text",
  },
  // {
  //   field: "textArea",
  //   label: "Job Description",
  //   id: "jobDescription",
  //   type: "text",
  //   grid: 24,
  // },
];

export const values = {
  designation: "",
  company: "",
  location: "",
  from: "",
  to: "",
  // jobDescription: "",
};
export const initialValues = { employment: [values] };
export const validation = Yup.object().shape({
  employment: Yup.array().of(
    Yup.object().shape({
      // designation: Yup.string().required("Designation is required"),
      // company: Yup.string().required("Company is required"),
      // location: Yup.string().required("Location is required"),
      // from: Yup.string().required("From is required"),
      // to: Yup.string().required("To is required"),
      // jobDescription: Yup.string().required("jobDescription is required"),
    })
  ),
});
