/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Typography, Checkbox, Radio } from "antd";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./CompanyLeftNavigation.scss";
import * as COMPANYActions from "../../../store/componies/action";
import ArrowLeft from "../../../assets/images/componies/ArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import makeGeoJSON from "../GeoJson";
import unnlockNotification from "../../../components/notification";

// import CompanyLocation from "./location";

const { Text } = Typography;

const CompanyLeftNavigation = ({
  mobileView,
  setMobileFilteres,
  companyList,
  componiesMapScreen,
  handleMapClick,
}) => {
  const dispatch = useDispatch();
  const companyListObject = useSelector(
    (state) => state.componies.filteredCompanyList
  );
  const filteresList = useSelector((state) => state.componies.filteresList);
  const appliedFilteres = useSelector(
    (state) => state.componies.appliedFilteres
  );
  const selectedLocation = useSelector(
    (state) => state.componies.selectedLocation
  );
  const selectedExploreSkills = useSelector(
    (state) => state.componies.exploreSelectedSkills
  );
  const userData = useSelector((state) => state.login.userData);

  const [data, setData] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const onChange = (e, key) => {
    if (userData?.tier_id === 0) {
      unnlockNotification("Upgrade to use filters.", "warning");
    } else {
      if (key === "location_list") {
        // console.log(e.target.value, "e.target.value");
        dispatch(
          COMPANYActions.getComponiesByLocationRequest({
            unnlock_ids: companyListObject.map((item) => item.unnlock_id) || [],
            city: e.target.value,
          })
        );
        dispatch(
          COMPANYActions.addFilteres({
            ...appliedFilteres,
            [key]: e.target.value,
          })
        );
      } else {
        if (e.length) {
          dispatch(
            COMPANYActions.addFilteres({ ...appliedFilteres, [key]: e })
          );
        } else {
          let obj = { ...appliedFilteres };
          delete obj[key];
          dispatch(COMPANYActions.addFilteres(obj));
        }
      }
    }
  };

  //get list of all company locations as geoJSON
  //utilizing the companies array in filteresList since it remains static across filtering
  useEffect(() => {
    if (filteresList && filteresList.hasOwnProperty("companies")) {
      const locations = [];
      const companyList = filteresList["companies"];
      companyList.forEach(function (company, index) {
        if (company.locations) {
          // const company_locations = JSON.parse(
          //   company.locations.replace(/: NaN/g, ": null")
          // );
          const company_locations = company.locations;
          company_locations.forEach(function (location, i, arr) {
            arr[i]["company"] = company;
            arr[i]["lat"] = arr[i]["Geometry"]["Point"][1];
            arr[i]["lng"] = arr[i]["Geometry"]["Point"][0];
          });
          if (Array.isArray(company_locations) && company_locations.length > 0)
            locations.push(company_locations);
        }
      });
      const geoLocations = makeGeoJSON.parse(locations.flat(), {
        Point: ["lat", "lng"],
      });
      // console.log(geoLocations);
      setLocationsList(geoLocations);
    } else {
      setLocationsList({ features: [] });
    }
  }, [filteresList]);

  useEffect(() => {
    if (filteresList) {
      const arr = Object.keys(filteresList).filter(
        (item) => item !== "companies"
      );
      setData(arr);
    }
  }, [filteresList]);

  const getSorted = (arr, item) => {
    if (item !== "size_list") {
      return arr.sort();
    } else {
      // console.log(arr, "errorCheck");
      const samArr = arr.sort((a, b) => {
        return (
          parseInt(`${a}`.split(["-", "+"])[0]) -
          parseInt(`${b}`.split(["-", "+"])[0])
        );
      });
      return samArr;
    }
  };

  return (
    <div>
      {mobileView ? (
        <div className="filteresCount-mobile">
          <div>
            <img src={ArrowLeft} onClick={() => setMobileFilteres(false)} />
            <span className="Filters">Filters</span> (
            {Object.keys(appliedFilteres).length} applied)
          </div>
          <div
            className="reset"
            onClick={() => {
              dispatch(COMPANYActions.addFilteres({}));
              dispatch(COMPANYActions.getComponiesByLocationFailure());
            }}
          >
            Reset
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="leftNav-container" style={{ opacity: userData?.tier_id === 0 ? "0.5" : "1" }}>
        {window?.location?.search === "?ExploreBySkills=true" ? (
          <div style={{ padding: "20px", overflow: "hidden" }}>
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Selected Skills
            </div>
            <div style={{ wordBreak: "break-all" }}>
              {selectedExploreSkills?.join(", ")}
            </div>
          </div>
        ) : null}
        <div className="filteresCount">
          <div>
            <span className="Filters">Filters</span> (
            {Object.keys(appliedFilteres).length} applied)
          </div>
          <div
            className="reset"
            onClick={() => {
              dispatch(COMPANYActions.addFilteres({}));
              dispatch(COMPANYActions.getComponiesByLocationFailure());
            }}
          >
            Reset
          </div>
        </div>
        <div className="leftNav-container-inner">
          <div
            style={{
              borderBottom: "2px solid #d5e0f3",
              marginBottom: "12px",
            }}
          />
          {filteresList && data.length ? (
            <>
              {data.map((item) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="filter-titles" style={{ marginTop: "8px" }}>
                      {item.split("_")[0].charAt(0).toUpperCase() +
                        item.split("_")[0].slice(1)}
                    </div>
                    {item === "location_list" ? (
                      <div
                        style={{
                          cursor: "pointer",
                          color: "rgb(67, 137, 250)",
                        }}
                        onClick={handleMapClick}
                      >
                        {componiesMapScreen ? "List" : "Map"}
                      </div>
                    ) : null}
                  </div>
                  <PerfectScrollbar className="filter_body">
                    {item === "location_list" ? (
                      <Radio.Group
                        defaultValue={[]}
                        value={selectedLocation}
                        onChange={(e) => onChange(e, item)}
                        className="custom_check_grp"
                      >
                        {[
                          ...new Set(
                            locationsList["features"].map(
                              (location) =>
                                location["properties"]["Municipality"]
                            )
                          ),
                        ]
                          .sort()
                          .map((city) => (
                            <Radio value={city}>{city}</Radio>
                          ))}
                      </Radio.Group>
                    ) : (
                      <Checkbox.Group
                        options={getSorted(filteresList[item], item)}
                        defaultValue={[]}
                        value={
                          appliedFilteres[item]
                            ? [...appliedFilteres[item]]
                            : []
                        }
                        onChange={(e) => onChange(e, item)}
                        className="custom_check_grp"
                      />
                    )}
                  </PerfectScrollbar>
                  <div
                    style={{
                      borderBottom: "2px solid #d5e0f3",
                      marginBottom: "12px",
                    }}
                  />
                </div>
              ))}
            </>
          ) : null}
          {/* <div>
            <Text className="filter-titles">Location</Text>
            <PerfectScrollbar className="">
              <CompanyLocation onCountryChange={onCountryChange} />
            </PerfectScrollbar>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default CompanyLeftNavigation;
