/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row, Col } from "antd";
import { key, schema, validation, values as schemaValues } from "./schema";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import ResumeOptimizerInput from "../resumeOptimizerInput";

import Plus from "../../../assets/images/resumeOptimizer/Plus.svg";
import TrashSimple from "../../../assets/images/resumeOptimizer/TrashSimple (red).svg";

import "./index.scss";

const referencessInfo = () => {
  const [editIndex, SetEditIndex] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isAdded, setisAdded] = useState(false);
  const [editorValues, setEditorValues] = useState([]);
  const referenceInfo = useSelector(
    (state) => state.resumeOptimizer.referenceInfo
  );
  const dispatch = useDispatch();

  const handleSubmit = (values, arr) => {
    let formData = {
      references: values.references.map((item, i) => {
        return item;
      }),
    };
    dispatch(
      ResumeActions.resumeUpdateRequest({
        formData,
        label: "references-update",
      })
    );
  };

  useEffect(() => {
    if (referenceInfo) {
      setDisplay(true);
      const arr = referenceInfo.references.map((item) => item.name) || [];
      setEditorValues(arr);
    }
  }, [referenceInfo]);

  const handlePush = () => {
    setEditorValues(["", ...editorValues]);
  };

  const handleRemove = (index) => {
    const editorArr = [...editorValues];
    editorArr.splice(index, 1);
    setEditorValues(editorArr);
  };

  return (
    <div className="update_experience_container">
      <Row style={{ borderBottom: "1px solid #e4e4e4", marginBottom: "20px" }}>
        <Col span={18}>
          <PerfectScrollbar className="tabs">
            <div className="tab">
              <img
                src={Plus}
                alt="add"
                onClick={() => {
                  setisAdded(true);
                  setTimeout(() => {
                    SetEditIndex(0);
                  }, 1000);
                  document.getElementById("add_references_infoId").click();
                  handlePush();
                }}
              />
            </div>
            {editorValues.map((item, i) => (
              <div
                className={editIndex === i ? "activeTab" : "tab"}
                onClick={() => SetEditIndex(i)}
              >
                References {i + 1}
              </div>
            ))}
          </PerfectScrollbar>
        </Col>
        <Col span={6}>
          <div className="actions">
            {(editIndex || editIndex === 0) &&
            (referenceInfo.references.length || isAdded) ? (
              <Row style={{ alignItems: "center" }}>
                <Col>
                  <button
                    type="button"
                    onClick={() =>
                      document.getElementById("submitreferencesBtn").click()
                    }
                    className="save-button"
                  >
                    Save
                  </button>
                </Col>
                <Col>
                  <img
                    style={{ marginLeft: "20px" }}
                    src={TrashSimple}
                    alt="remove"
                    onClick={() => {
                      setisAdded(false);
                      setTimeout(() => {
                        if (editIndex) {
                          SetEditIndex(editIndex - 1);
                        }
                      }, 1000);
                      document
                        .getElementById(`remove_references_infoId_${editIndex}`)
                        .click();
                      document.getElementById("submitreferencesBtn").click();
                      handleRemove();
                    }}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>

      {display && (
        <>
          <Formik
            initialValues={referenceInfo}
            enableReinitialize
            // validationSchema={validation}
            onSubmit={(values) => {
              setTimeout(() => {
                handleSubmit(values);
              }, 400);
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <FieldArray name={key}>
                  {({ remove, unshift }) => (
                    <div>
                      <div
                        id="add_references_infoId"
                        style={{ display: "none" }}
                        onClick={() => {
                          unshift({
                            name: "",
                            mobile: "",
                            email: "",
                            designation: "",
                          });
                          handlePush();
                        }}
                      >
                        add
                      </div>
                      {values.references.length > 0 &&
                        values.references.map((item, i) => (
                          <>
                            {i === editIndex ? (
                              <>
                                <button
                                  id={`remove_references_infoId_${i}`}
                                  style={{ display: "none" }}
                                  type="button"
                                  onClick={() => {
                                    remove(i);
                                    handleRemove(i);
                                  }}
                                >
                                  Remove
                                </button>
                                <Row gutter={[24, 24]}>
                                  <Col span={12}>
                                    <ResumeOptimizerInput
                                      label="Name"
                                      name={`references[${i}].name`}
                                      type="text"
                                      error={errors.name}
                                      touched={touched.name}
                                    />
                                  </Col>
                                  <Col span={12}>
                                    <ResumeOptimizerInput
                                      label="Mobile"
                                      name={`references[${i}].mobile`}
                                      type="text"
                                      error={errors.mobile}
                                      touched={touched.mobile}
                                    />
                                  </Col>
                                </Row>
                                <Row gutter={[24, 24]}>
                                  <Col span={12}>
                                    <ResumeOptimizerInput
                                      label="Email"
                                      name={`references[${i}].email`}
                                      type="text"
                                      error={errors.email}
                                      touched={touched.email}
                                    />
                                  </Col>
                                  <Col span={12}>
                                    <ResumeOptimizerInput
                                      label="Designation"
                                      name={`references[${i}].designation`}
                                      type="text"
                                      error={errors.designation}
                                      touched={touched.designation}
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                          </>
                        ))}
                    </div>
                  )}
                </FieldArray>
                <button
                  type="submit"
                  id="submitreferencesBtn"
                  style={{ visibility: "hidden" }}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default referencessInfo;
