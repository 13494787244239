import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EllipseEmpty from "../../assets/images/personalDashboard/EllipseEmpty.svg";
import EllipseGreen from "../../assets/images/personalDashboard/EllipseGreen.svg";
import EllipseYllow from "../../assets/images/personalDashboard/EllipseYllow.svg";
import DownGrowth from "../../assets/images/DownGrowth.svg";
import Growth from "../../assets/images/Growth.svg";
import { updateJobMatchRequest } from "../../store/jobMatch/jobMatchActions";

const IntresLevel = ({ index, value }) => {
  const dispatch = useDispatch();
  const [selected, Setselected] = useState(value);
  const jobTracks = useSelector((state) => state.jobmatch.jobTrackeres);

  const selctedIcon = selected >= 3 ? EllipseGreen : EllipseYllow;

  useEffect(() => {
    if (value !== selected) {
      const postData = jobTracks[index];
      dispatch(
        updateJobMatchRequest({
          job_tracker_id: postData.job_tracker_id,
          interest_level: selected,
        })
      );
    }
  }, [selected]);

  return (
    <div
      style={{
        display: "flex"
      }}
    >
      {[1, 2, 3, 4, 5].map((item, i) => (
        <img
          src={i + 1 <= selected ? selctedIcon : EllipseEmpty}
          style={{ width: "13px", marginRight: "6px", cursor: "pointer" }}
          onClick={() => {
            Setselected(i + 1);
          }}
        />
      ))}
      {/* {selected >= 3 ? <img src={Growth} /> : <img src={DownGrowth} />} */}
    </div>
  );
};

export default IntresLevel;
