/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import FolderSimplePlus from "../../assets/images/resumeOptimizer/FolderSimplePlus.svg";
import Check from "../../assets/images/resumeOptimizer/Check.svg";
import Uncheck from "../../assets/images/resumeOptimizer/Uncheck.svg";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  resumeUpdateRequest,
  // getOtheresSectionsRequest,
  setCurrentResumePage,
  setIsOtheresAdded,
  setResumeScreens,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import ResumeOptimizerInput from "./resumeOptimizerInput";
import unnlockNotification from "../../components/notification";

const AddOtheresModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputText, setInputText] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const [selectedID, setselectedID] = useState("");
  const dispatch = useDispatch();
  const { currentResumePage, resumeScreens, loader, otheresSections } =
    useSelector((state) => state.resumeOptimizer);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setInputText("");
    setShowInput(false);
    setIsModalVisible(false);
  };

  const handleDone = () => {
    if (selectedID) {
      dispatch(setCurrentResumePage(selectedID));
      setIsModalVisible(false);
    } else if (showInput) {
      if (inputText) {
        const id = [...inputText.split(" ")].join("_");
        dispatch(
          setResumeScreens([
            ...resumeScreens,
            {
              title: inputText,
              id,
            },
          ])
        );
        dispatch(setCurrentResumePage(id));
        setIsModalVisible(false);
        const formData = {};
        formData["others"] = [
          {
            title: inputText,
            id,
          },
        ];
        dispatch(
          resumeUpdateRequest({
            formData,
            label: "other-update",
          })
        );
      } else {
        unnlockNotification("Please enter the title", "warning");
      }
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  // useEffect(() => {
  //   dispatch(getOtheresSectionsRequest());
  // }, []);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="addOtheres_model"
      width={500}
    >
      <div className="add_otheres_modal">
        <div className="title">Select resume sections to edit</div>
        <div className="content">
          {resumeScreens
            .filter((item) => item.id !== currentResumePage)
            .map((item) => (
              <Skeleton loading={loader} active>
                <div className="item" onClick={() => setselectedID(item.id)}>
                  <img
                    src={selectedID === item.id ? Check : Uncheck}
                    className="checked"
                  />
                  <img src={FolderSimplePlus} className="folderIcon" />{" "}
                  <div className="name">
                    {item.title?.split(/(?=[A-Z])/).join(" ")}
                  </div>
                </div>
              </Skeleton>
            ))}
          {otheresSections?.length ? null : (
            <div
              className="item"
              style={{ color: "#4389fa", padding: "10px 10px 0px" }}
              onClick={() => setShowInput(!showInput)}
            >
              {showInput ? "- Remove" : "+ Add Custom Field"}
            </div>
          )}
          {showInput ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label>Custome Field Title : </label>
              <input
                style={{
                  border: "1px solid #dee5ff",
                  borderRadius: "8px",
                  padding: "10px",
                  marginLeft: "10px",
                }}
                type="text"
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                value={inputText}
              />
            </div>
          ) : null}
        </div>
        <div className="submit_btnContainer">
          <button className="done_btn" onClick={() => handleDone()}>
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddOtheresModal;
