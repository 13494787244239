import * as types from "./resumeOptimizerTypes";

// get Resume Vaults Actions
export const getResumeVaultsRequest = (payload) => ({
  type: types.GET_RESUME_VAULTS_REQUEST,
  payload,
});
export const getResumeVaultsSuccess = (payload) => ({
  type: types.GET_RESUME_VAULTS_SUCCESS,
  payload,
});
export const getResumeVaultsFailure = (payload) => ({
  type: types.GET_RESUME_VAULTS_FAILURE,
  payload,
});

// put Resume Vaults Actions
export const putResumeVaultsRequest = (payload) => ({
  type: types.PUT_RESUME_VAULTS_REQUEST,
  payload,
});
export const putResumeVaultsSuccess = (payload) => ({
  type: types.PUT_RESUME_VAULTS_SUCCESS,
  payload,
});
export const putResumeVaultsFailure = (payload) => ({
  type: types.PUT_RESUME_VAULTS_FAILURE,
  payload,
});

// download Resume in pdf
export const downloadResumePdfRequest = (payload) => ({
  type: types.DOWNLOAD_RESUME_PDF_REQUEST,
  payload,
});
export const downloadResumePdfSuccess = (payload) => ({
  type: types.DOWNLOAD_RESUME_PDF_SUCCESS,
  payload,
});
export const downloadResumePdfFailure = (payload) => ({
  type: types.DOWNLOAD_RESUME_PDF_FAILURE,
  payload,
});

// clone Resume
export const cloneResume = (payload) => ({
  type: types.CLONE_RESUME_REQUEST,
  payload,
});
export const cloneResumeSuccess = (payload) => ({
  type: types.CLONE_RESUME_SUCCESS,
  payload,
});
export const cloneResumeFailure = (payload) => ({
  type: types.CLONE_RESUME_FAILURE,
  payload,
});

// Get Resume
export const resumeRequest = () => ({
  type: types.RESUME_REQUEST,
});
export const resumeSuccess = (payload) => ({
  type: types.RESUME_SUCCESS,
  payload,
});
export const resumeFailure = (payload) => ({
  type: types.RESUME_FAILURE,
  payload,
});

// Update Resume
export const resumeUpdateRequest = (payload) => ({
  type: types.UPDATE_RESUME_REQUEST,
  payload,
});
export const resumeUpdateSuccess = (payload) => ({
  type: types.UPDATE_RESUME_SUCCESS,
  payload,
});
export const resumeUpdateFailure = (payload) => ({
  type: types.UPDATE_RESUME_FAILURE,
  payload,
});

//  sync skills on resume update
export const syncSkillsForJobMatchRequest = (payload) => ({
  type: types.SYNC_JOBMATCHSKILLS_REQUEST,
  payload,
});
export const syncSkillsForJobMatchSuccess = (payload) => ({
  type: types.SYNC_JOBMATCHSKILLS_SUCCESS,
  payload,
});
export const syncSkillsForJobMatchFailure = (payload) => ({
  type: types.SYNC_JOBMATCHSKILLS_FAILURE,
  payload,
});

// Delete Resume
export const resumeDeleteRequest = (payload) => ({
  type: types.DELETE_RESUME_REQUEST,
  payload,
});
export const resumeDeleteSuccess = (payload) => ({
  type: types.DELETE_RESUME_SUCCESS,
  payload,
});
export const resumeDeleteFailure = (payload) => ({
  type: types.DELETE_RESUME_FAILURE,
  payload,
});

// get skills Resume
export const getSkillsRequest = (payload) => ({
  type: types.GET_SKILLS_REQUEST,
  payload,
});
export const getSkillsSuccess = (payload) => ({
  type: types.GET_SKILLS_SUCCESS,
  payload,
});
export const getSkillsFailure = (payload) => ({
  type: types.GET_SKILLS_FAILURE,
  payload,
});

// // Get summary suggetions
// export const getOtheresSectionsRequest = (payload) => ({
//   type: types.GET_OTHERES_SECTIONS_REQUEST,
//   payload,
// });
// export const getOtheresSectionsSuccess = (payload) => ({
//   type: types.GET_OTHERES_SECTIONS_SUCCESS,
//   payload,
// });
// export const getOtheresSectionsFailure = (payload) => ({
//   type: types.GET_OTHERES_SECTIONS_FAILURE,
//   payload,
// });

// set resume page
export const setCurrentResumePage = (payload) => ({
  type: types.SET_CURRENT_RESUMEPAGE,
  payload,
});

// set resume page
export const setCurrentResumeId = (payload) => ({
  type: types.SET_CURRENT_RESUME_ID,
  payload,
});

// set resume page
export const setCurrentTemplate = (payload) => ({
  type: types.SET_CURRENT_TEMPLATE,
  payload,
});

// set otheres added
export const setIsOtheresAdded = (payload) => ({
  type: types.SET_IS_OTHERES_ADDED,
  payload,
});

// set suggestion oader
export const setSuggestionLoader = (payload) => ({
  type: types.SET_SUGGESTION_LOADER,
  payload,
});

// set current eployement index
export const setCurrentEmplyementIndex = (payload) => ({
  type: types.SET_CURRENT_EMPLOYEMENT_INDEX,
  payload,
});

// set current eployement index
export const setExperienceSaved = (payload) => ({
  type: types.SET_EXPERIENCE_SAVED,
  payload,
});

// set save loader
export const setResumeSaveLoader = (payload) => ({
  type: types.SET_SAVE_LOADER,
  payload,
});

export const setResumeScreens = (payload) => ({
  type: types.SET_RESUME_SCREENS,
  payload,
});

// reset
export const reset = () => ({
  type: types.RESET,
});
