import React, { useEffect } from "react";
import { Row, Col, Skeleton, message } from "antd";
import { useState } from "react";
import AddSkillsModal from "./addSkillsModal";
import { getSkillsRequest } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import { useDispatch, useSelector } from "react-redux";
import unnlockNotification from "../../../components/notification";

const renderSkills = (skillsArr, label) => (
  <div
    style={{
      borderRadius: "10px",
      background: "rgba(67, 137, 250, 0.1)",
      padding: "10px",
      marginTop: "20px",
      width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p style={{ fontWeight: 600, fontSize: "17px", margin: "10px 0px" }}>
        {label}
      </p>
    </div>
    <div
      style={{
        background: "white",
        padding: "10px",
        borderRadius: "5px",
        maxHeight: "180px",
        overflow: "auto",
      }}
    >
      {skillsArr.map((item, i) => (
        <>
          <span
            style={
              label == "Added Skills"
                ? { color: "#261C4B", margin: "0px 8px" }
                : {
                    color: "#4389FA",
                    fontSize: "15px",
                    // fontWeight: "600",
                  }
            }
          >
            {item}
          </span>
          {skillsArr?.length === i + 1 ? null : (
            <span
              style={{
                color: "rgba(67, 137, 250, 0.4)",
                margin: "0px 8px",
              }}
            >
              {" "}
              |{" "}
            </span>
          )}
        </>
      ))}
    </div>
  </div>
);

const Skills = ({
  title,
  skills,
  setcurrentSkills,
  addedSkillss,
  setsaveSkills,
}) => {
  const [visible, setVisible] = useState(false);
  const [addedSkills, setaddedSkills] = useState([...addedSkillss]);
  const [suggestedSoftSkills, setsuggestedSoftSkills] = useState([]);
  const [suggestedHardSkills, setsuggestedHardSkills] = useState([]);
  const [suggestedUnmatchedSoftSkills, setsuggestedUnmatchedSoftSkills] =
    useState([]);
  const [suggestedUnmatchedHardSkills, setsuggestedUnmatchedHardSkills] =
    useState([]);
  const [showUnmatchedSkills, setShowUnmatchedSkills] = useState(false);

  const dispatch = useDispatch();
  const { currentEmplyementSkills, saveLoader } = useSelector(
    (state) => state.resumeOptimizer
  );
  const {
    userData: { user_socs },
  } = useSelector((state) => state.login);

  useEffect(() => {
    setcurrentSkills([...addedSkills]);
  }, [addedSkills]);

  const handleAddclcik = () => {
    if (Array.isArray(user_socs) && user_socs?.length) {
      setVisible(!visible);
      dispatch(
        getSkillsRequest({
          label: title === "Hard Skills" ? "hard_skills" : "soft_skills",
          id: user_socs[0].soc_id,
          page: 1,
        })
      );
      setsaveSkills(false);
    } else {
      unnlockNotification("No user socs found", "warning");
    }
  };

  useEffect(() => {
    if (
      Array.isArray(currentEmplyementSkills.soft_skills_name) &&
      currentEmplyementSkills?.soft_skills_name?.length
    ) {
      const arr = currentEmplyementSkills?.soft_skills_name.map((item) => {
        return item.name;
      });
      setsuggestedSoftSkills(arr);
    }
    if (
      Array.isArray(currentEmplyementSkills.hard_skills_name) &&
      currentEmplyementSkills?.hard_skills_name?.length
    ) {
      const arr = currentEmplyementSkills?.hard_skills_name.map((item) => {
        return item.name;
      });
      setsuggestedHardSkills(arr);
    }
  }, [currentEmplyementSkills]);

  // useEffect(() => {
  //   return () => {
  //     localStorage.setItem("unnlockJMUnamatchedSkills", "");
  //   };
  // }, []);

  return (
    <Row style={{ marginBottom: "20px" }}>
      <div className="skills_titleCard">
        <h2
          style={{
            marginTop: "10px",
            fontWeight: 600,
          }}
        >
          {title}
        </h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className="save-button"
            onClick={() => {
              setShowUnmatchedSkills(false);
              handleAddclcik();
            }}
            type="button"
          >
            + Add Skills
          </button>
        </div>
      </div>

      <Col span={24}>
        <Skeleton loading={saveLoader} active>
          {addedSkills?.length ? (
            <div>
              <span style={{ fontWeight: 600 }}>Added Skills : </span>
              <span>{addedSkills.join(", ")}</span>
            </div>
          ) : null}
        </Skeleton>
      </Col>
      <AddSkillsModal
        skills={
          title === "Hard Skills"
            ? showUnmatchedSkills
              ? suggestedUnmatchedHardSkills
              : suggestedHardSkills
            : showUnmatchedSkills
            ? suggestedUnmatchedSoftSkills
            : suggestedSoftSkills
        }
        foundSkills={skills || []}
        visible={visible}
        title={title}
        setaddedSkills={setaddedSkills}
        addedSkills={addedSkills}
        setsaveSkills={setsaveSkills}
      />
    </Row>
  );
};

export default Skills;
