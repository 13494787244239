// GET RESUME VAULTS Types
export const GET_RESUME_VAULTS_REQUEST =
  "resumeOptimizer/GET_RESUME_VAULTS_REQUEST";
export const GET_RESUME_VAULTS_SUCCESS =
  "resumeOptimizer/GET_RESUME_VAULTS_SUCCESS";
export const GET_RESUME_VAULTS_FAILURE =
  "resumeOptimizer/GET_RESUME_VAULTS_FAILURE";

// PUT RESUME VAULTS Types
export const PUT_RESUME_VAULTS_REQUEST =
  "resumeOptimizer/PUT_RESUME_VAULTS_REQUEST";
export const PUT_RESUME_VAULTS_SUCCESS =
  "resumeOptimizer/PUT_RESUME_VAULTS_SUCCESS";
export const PUT_RESUME_VAULTS_FAILURE =
  "resumeOptimizer/PUT_RESUME_VAULTS_FAILURE";

// Cloe Resume
export const CLONE_RESUME_REQUEST = "resumeOptimizer/CLONE_RESUME_REQUEST";
export const CLONE_RESUME_SUCCESS = "resumeOptimizer/CLONE_RESUME_SUCCESS";
export const CLONE_RESUME_FAILURE = "resumeOptimizer/CLONE_RESUME_FAILURE";

// Get Resume
export const RESUME_REQUEST = "resumeOptimizer/RESUME_REQUEST";
export const RESUME_SUCCESS = "resumeOptimizer/RESUME_SUCCESS";
export const RESUME_FAILURE = "resumeOptimizer/RESUME_FAILURE";

// Update Resume
export const UPDATE_RESUME_REQUEST = "resumeOptimizer/UPDATE_RESUME_REQUEST";
export const UPDATE_RESUME_SUCCESS = "resumeOptimizer/UPDATE_RESUME_SUCCESS";
export const UPDATE_RESUME_FAILURE = "resumeOptimizer/UPDATE_RESUME_FAILURE";

// Delete Resume
export const DELETE_RESUME_REQUEST = "resumeOptimizer/DELETE_RESUME_REQUEST";
export const DELETE_RESUME_SUCCESS = "resumeOptimizer/DELETE_RESUME_SUCCESS";
export const DELETE_RESUME_FAILURE = "resumeOptimizer/DELETE_RESUME_FAILURE";

// get skills Resume
export const GET_SKILLS_REQUEST = "resumeOptimizer/GET_SKILLS_REQUEST";
export const GET_SKILLS_SUCCESS = "resumeOptimizer/GET_SKILLS_SUCCESS";
export const GET_SKILLS_FAILURE = "resumeOptimizer/GET_SKILLS_FAILURE";

// download Resume in pdf
export const DOWNLOAD_RESUME_PDF_REQUEST =
  "resumeOptimizer/DOWNLOAD_RESUME_PDF_REQUEST";
export const DOWNLOAD_RESUME_PDF_SUCCESS =
  "resumeOptimizer/DOWNLOAD_RESUME_PDF_SUCCESS";
export const DOWNLOAD_RESUME_PDF_FAILURE =
  "resumeOptimizer/DOWNLOAD_RESUME_PDF_FAILURE";

// sync skills on resume update
export const SYNC_JOBMATCHSKILLS_REQUEST =
  "resumeOptimizer/SYNC_JOBMATCHSKILLS_REQUEST";
export const SYNC_JOBMATCHSKILLS_SUCCESS =
  "resumeOptimizer/SYNC_JOBMATCHSKILLS_SUCCESS";
export const SYNC_JOBMATCHSKILLS_FAILURE =
  "resumeOptimizer/SYNC_JOBMATCHSKILLS_FAILURE";

// // Get Other Resume Fields
// export const GET_OTHERES_SECTIONS_REQUEST =
//   "resumeOptimizer/GET_OTHERES_SECTIONS_REQUEST";
// export const GET_OTHERES_SECTIONS_SUCCESS =
//   "resumeOptimizer/GET_OTHERES_SECTIONS_SUCCESS";
// export const GET_OTHERES_SECTIONS_FAILURE =
//   "resumeOptimizer/GET_OTHERES_SECTIONS_FAILURE";

// current Page
export const SET_CURRENT_RESUMEPAGE = "resumeOptimizer/SET_CURRENT_RESUMEPAGE";

// current Template
export const SET_CURRENT_TEMPLATE = "resumeOptimizer/SET_CURRENT_TEMPLATE";

// Add Otheres Sections
export const SET_IS_OTHERES_ADDED = "resumeOptimizer/SET_IS_OTHERES_ADDED";

// current Resume
export const SET_CURRENT_RESUME_ID = "resumeOptimizer/SET_CURRENT_RESUME_ID";

// suggestion Loader
export const SET_SUGGESTION_LOADER = "resumeOptimizer/SET_SUGGESTION_LOADER";

// set current eployement index
export const SET_CURRENT_EMPLOYEMENT_INDEX =
  "resumeOptimizer/SET_CURRENT_EMPLOYEMEN";

// setExperience saved
export const SET_EXPERIENCE_SAVED = "resumeOptimizer/SET_EXPERIENCE_SAVED";

// setExperience saved
export const SET_SAVE_LOADER = "resumeOptimizer/SET_SAVE_LOADER";

export const SET_RESUME_SCREENS = "resumeOptimizer/SET_RESUME_SCREENS";

// Reset
export const RESET = "resumeOptimizer/RESET";
