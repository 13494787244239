/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import MiinedButton from "../../components/Button";
import { applyCouponRequest } from "../../store/newLogin/newLoginAction";
import "../Profile/model.scss";

const ApplyCouponContainer = ({ visible, setShowApplyCouponModal }) => {
  const dispatch = useDispatch();
  const [Coupon, SetCoupon] = useState("");
  const { loader } = useSelector((state) => state.login);

  const handleOk = () => {
    setShowApplyCouponModal(false);
  };

  const handleCancel = () => {
    setShowApplyCouponModal(false);
  };

  return (
    <Modal
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="custom_addSkillsModal"
    >
      <div className="model_body">
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <h2>Apply Coupon</h2>
            {/* <p className="subsDesc">
                  You are currently using the Free version. Upgrade to unlock
                  all features
                </p> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <input
              type="text"
              onChange={(e) => {
                SetCoupon(e.target.value);
              }}
              value={Coupon}
              style={{
                border: "1px solid #dee5ff",
                borderRadius: "8px",
                marginRight: "10px",
                paddingLeft: "10px",
              }}
            />
            <MiinedButton
              title={loader ? "Loading..." : "Apply"}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                dispatch(applyCouponRequest(Coupon));
                setShowApplyCouponModal(false);
              }}
              disabled={!Coupon}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApplyCouponContainer;
