import React, { useState } from "react";
import "../Explore/index.scss";
import BySkills from "./bySkills";
import { GetOnboardSuggestions } from "../ResumeOpimizer/suggestions";
import { useSelector } from "react-redux";

const ExploreComp = () => {
  const loginState = useSelector((state) => state.login);
  return (
    <div className="explore_comp">
      <div
        className="Exploretitle"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div>Open Jobs </div>
        {/* {loginState?.subsCount && loginState?.userData?.tier_id === 0 ? (
          <div
            style={{
              fontSize: "16px",
              padding: "0px 10px",
              marginLeft: "10px",
              borderRadius: "4px",
              background: "rgb(245 124 66 / 55%)",
              color: "white",
              fontWeight: 500,
            }}
          >{`${loginState?.subsCount?.open_jobs_count} free trials left.`}</div>
        ) : null} */}
      </div>
      <div className="exploreComp_container">
        <div className="card_conatiner">
          <BySkills />
        </div>
        <div className="exploreComp-whycontainer">
          <GetOnboardSuggestions screen="exploreBySkills" />
        </div>
      </div>
    </div>
  );
};

export default ExploreComp;
