import * as types from "./newLoginTypes";

const INITIAL_STATE = {
  loader: false,
  subscriptionLoader: false,
  loaded: false,
  couponApplied: false,
  loginType: "",
  isRefreshed: false,
  isLoggedIn: false,
  userData: "",
  subsCount: "",
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loader: true,
        loginType: "",
        error: "",
      };
    case types.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        // loader: false,
        loginType: "",
        error: "",
      };
    case types.LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        loader: false,
        loginType: "",
        error: action.payload,
      };

      case types.LOGOUT_REQUEST:
        return {
          ...state,
          loader: true,
          loginType: "",
          error: "",
        };
      case types.LOGOUT_REQUEST_SUCCESS:
        return {
          ...INITIAL_STATE,
        };
      case types.LOGOUT_REQUEST_FAILURE:
        return {
          ...state,
          loader: false,
          loginType: "",
          error: action.payload,
        };
    case types.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loader: action?.payload?.dontLoad ? false : true,
        loaded: false,
        error: "",
      };
    case types.GET_USER_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        loader: false,
        loaded: true,
        userData: action.payload,
        isLoggedIn: true,
        error: "",
      };
    case types.GET_USER_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        loader: false,
        loaded: true,
        isLoggedIn: false,
        userData: "",
        error: action.payload,
      };
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loader: true,
        error: "",
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loader: false,
        error: "",
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loader: true,
        error: "",
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loader: false,
        error: "",
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    case types.REFRESH_LOGIN_REQUEST:
      return {
        ...state,
        isRefreshed: false,
      };
    case types.REFRESH_LOGIN_SUCCESS:
      return {
        ...state,
        isRefreshed: true,
      };
    case types.REFRESH_LOGIN_FAILURE:
      return {
        ...state,
        isRefreshed: false,
      };

    case types.GET_SUBSCRIPTION_COUNT_REQUEST:
      return {
        ...state,
        subsCount: "",
      };
    case types.GET_SUBSCRIPTION_COUNT_SUCCESS:
      return {
        ...state,
        subsCount: action.payload,
      };
    case types.GET_SUBSCRIPTION_COUNT_FAILURE:
      return {
        ...state,
        subsCount: "",
      };

    case types.APPLY_COUPON_REQUEST:
      return {
        ...state,
        loader: true,
        couponApplied: false,
      };
    case types.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        couponApplied: true,
        loader: false,
      };
    case types.APPLY_COUPON_FAILURE:
      return {
        ...state,
        couponApplied: false,
        loader: false,
      };

    case types.GET_USER_INDUSTRIES_REQUEST:
      return {
        ...state,
        compnayLocationsLoader: true,
      };
    case types.GET_USER_INDUSTRIES_SUCCESS:
      return {
        ...state,
        compnayLocationsLoader: false,
      };
    case types.GET_USER_INDUSTRIES_FAILURE:
      return {
        ...state,
        compnayLocationsLoader: false,
      };
    case types.SET_SUBSCRIPTION_LOADER:
      return {
        ...state,
        subscriptionLoader: action.payload,
        loginType: "",
        error: "",
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
