// import { Grid, Row } from "antd";
import React from "react";
import { Tooltip } from "antd";
// import { useSelector } from "react-redux";
import Heartfilled from "../../assets/images/componies/heartFilled.svg";
import Heart from "../../assets/images/componies/heart.svg";
import DefaultImage from "../../assets/images/componies/defaultImage.svg";
import "./JobCompanycard.scss";
import MiinedButton from "../../components/Button";
import { useSelector } from "react-redux";
import history from "../../routes/History";
import SubsriptionAlertModal from "./subscribeModal";
import { useState } from "react";
// import DesktopSave from "../../assets/images/Bookmark.svg";

const JobCompanyCard = ({
  item,
  isFaverate,
  getFavClick,
  isDetails,
  showLimited,
  currentOpenJobId,
}) => {
  // const jobmatchedCompanies = useSelector((state) => state.tools.openJobs);
  const userData = useSelector((state) => state.login.userData);
  // const { selectedResumeId } = useSelector((state) => state.tools);
  const [openSubscriptionModal, setopenSubscriptionModal] = useState(false);
  const { loader } = useSelector((state) => state.jobmatch);
  const educationLevel = Array.isArray(item.EDUCATION_LEVELS_NAME)
    ? item.EDUCATION_LEVELS_NAME[0]
    : "";
  const url = Array.isArray(item.URL) ? item.URL[0] : "";

  const renderAdditionalFields = (
    <>
      <div className="card__bodysection">
        <div className="card__bodysection_col">
          <p className="card_body__heading">Employment type</p>
          <Tooltip placement="bottom" title={item.EMPLOYMENT_TYPE_NAME}>
            <p className="card-body__subheading">{item.EMPLOYMENT_TYPE_NAME}</p>
          </Tooltip>
        </div>
        <div className="card__bodysection_col">
          <p className="card_body__heading">Specialized skills</p>
          {Array.isArray(item.SPECIALIZED_SKILLS_NAME) ? (
            <Tooltip placement="bottom" title={item.SPECIALIZED_SKILLS_NAME}>
              <p className="card-body__subheading">
                {item.SPECIALIZED_SKILLS_NAME.join(", ")}
              </p>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="card__bodysection">
        <div className="card__bodysection_col">
          <p className="card_body__heading">Location</p>
          <p className="card-body__subheading">{item.CITY_NAME}</p>
        </div>
        <div className="card__bodysection_col">
          <p className="card_body__heading">Soft skills</p>
          {Array.isArray(item.SKILLS_NAME) ? (
            <Tooltip placement="bottom" title={item.SKILLS_NAME}>
              <p className="card-body__subheading">
                {item.SKILLS_NAME.join(",")}
              </p>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </>
  );

  return (
    <section
      className={isDetails ? "details_card__conatiner" : "card__conatiner"}
      role="contentinfo"
      onClick={() => history.push(`/openJobsDetails?jobId=${item.ID}`)}
      style={{
        backgroundColor: currentOpenJobId === item.ID ? "#cae0f5" : "white",
      }}
    >
      <div role="columnheader" className="card__header">
        {/* image and name */}
        <div className="card_image__name">
          <img
            className="companyLogo"
            src={
              item?.logo_url
                ? item?.logo_url
                : "/static/media/defaultImage.dc9d8294cd9de7750519e6b8bc209104.svg"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = DefaultImage;
            }}
            alt="logo"
          />
          <div className="card__name">
            <p className="card_company__name">{item.COMPANY_NAME}</p>
            <p className="card_company__companyraw">{item.CITY_NAME}</p>
            <p className="card_company__companyraw">{item.TITLE_NAME}</p>
          </div>
        </div>
        {/* save */}
        {showLimited ? null : (
          <div className="card_company__savebutton">
            <img
              style={{ cursor: "pointer" }}
              src={isFaverate ? Heartfilled : Heart}
              onClick={(e) => {
                e.stopPropagation();
                getFavClick(isFaverate ? "remove" : "add", item.ID, item);
              }}
              alt="saveicon"
            />
          </div>
        )}
      </div>
      {showLimited ? null : (
        <>
          <div className="card__body">
            <div className="card__body_right">
              <div className="card__bodysection">
                <div className="card__bodysection_col">
                  <p className="card_body__heading">Job Title</p>
                  <Tooltip placement="bottom" title={item.TITLE_NAME}>
                    <p className="card-body__subheading">{item.TITLE_NAME}</p>
                  </Tooltip>
                </div>
                <div className="card__bodysection_col">
                  <p className="card_body__heading">Industry</p>
                  <Tooltip placement="bottom" title={item.TITLE_RAW}>
                    <p className="card-body__subheading">{item.TITLE_RAW}</p>
                  </Tooltip>
                </div>
              </div>
              <div className="card__bodysection">
                <div className="card__bodysection_col">
                  <p className="card_body__heading">Salary</p>
                  <p className="card-body__subheading">{`$${new Intl.NumberFormat().format(
                    item.SALARY
                  )}`}</p>
                </div>
                <div className="card__bodysection_col">
                  <p className="card_body__heading">Education</p>
                  <Tooltip placement="bottom" title={educationLevel}>
                    <p className="card-body__subheading">{educationLevel}</p>
                  </Tooltip>
                </div>
              </div>
              {isDetails ? renderAdditionalFields : null}
              <div className="card__bodysection">
                <div className="card__bodysection_col">
                  <p className="card_body__heading">Experience</p>
                  <Tooltip placement="bottom" title={item.MIN_YEARS_EXPERIENCE}>
                    <p className="card-body__subheading">
                      {`${item.MIN_YEARS_EXPERIENCE} years`}
                    </p>
                  </Tooltip>
                </div>
              </div>
              {url ? (
                <div className="card__bodysection">
                  <div
                    style={{ width: "90%" }}
                    className="card__bodysection_col"
                  >
                    <p className="card_body__heading">Posting URL</p>
                    <Tooltip placement="bottom" title={url}>
                      <a
                        href={url}
                        target="_blank"
                        style={{ fontSize: "12px" }}
                      >
                        {url}
                      </a>
                    </Tooltip>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="card_body_left">
              <p className="card_body__heading">Description</p>
              <p className="card-body__subheading">{item.BODY}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <MiinedButton
              title={loader ? "Loading..." : "Match Job"}
              onClick={(e) => {
                e.stopPropagation();
                if (userData?.tier_id === 0) {
                  setopenSubscriptionModal(true);
                } else {
                  getFavClick("", item.ID, item, true);
                }
              }}
            />
            {/* <MiinedButton
              title={loader ? "Loading..." : "Chat Bot"}
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: "/coachUnnbot",
                  state: {
                    resume_id: selectedResumeId,
                    openjob_id: item.ID,
                  },
                });
              }}
            /> */}
            <div>
              <a
                onClick={(e) => e.stopPropagation()}
                href={url}
                target="_blank"
              >
                <MiinedButton title="Apply Now" />
              </a>
            </div>
          </div>
        </>
      )}
      <SubsriptionAlertModal visible={openSubscriptionModal} />
    </section>
  );
};

export default JobCompanyCard;
