import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import * as LoginActions from "../store/newLogin/newLoginAction";
import history from "../routes/History";
import Routes from "../routes/Routes";
import withClearCache from "../ClearCache";
import "./App.scss";

function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const container = document.getElementById("root");
  //   document.addEventListener("click", function (event) {
  //     if (container !== event.target && !container.contains(event.target)) {
  //       dispatch(LoginActions.checkTokanExpiry());
  //     }
  //   });
  // }, []);

  axios.interceptors.request.use(
    (config) => {
      console.log(config, "config");
      let strArr = config.url.split("/");
      if (strArr[strArr.length - 1] !== "user-refreshlogin")
        if (localStorage.getItem("unnlockUserToken")) {
          dispatch(LoginActions.checkTokanExpiry());
        }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return (
    <Router history={history}>
      {/* <Navbar /> */}
      {/* <div className="title">Hello</div> */}
      <Routes />
    </Router>
  );
}

export default withClearCache(App);
