import React from "react";
import PropTypes from "prop-types";
import "./input.scss";

const InputWithLabel = ({
  label,
  value,
  handleChange,
  type,
  disabled,
  name,
  errors,
  touched,
  placeholder,
}) => {
  return (
    <div className="textOnInput">
      <p className="label">{label}</p>
      <input
        className={
          errors && errors[name] && touched && touched[name]
            ? "input_custom haserror"
            : "input_custom"
        }
        type={type}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        name={name}
        id={name}
        placeholder={placeholder}
      />
      {errors && errors[name] && touched && touched[name] && (
        <p className="error_text">{errors[name]}</p>
      )}
    </div>
  );
};

InputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.object),
  touched: PropTypes.objectOf(PropTypes.object),
};

InputWithLabel.defaultProps = {
  value: false,
  handleChange: () => {},
  type: "text",
  disabled: false,
  name: "",
  errors: {},
  touched: {},
};

export default InputWithLabel;
