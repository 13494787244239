/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import { message } from "antd";
import * as ACTIONS from "./dashboardActions";
import * as LOGIN_ACTIONS from "../newLogin/newLoginAction";
import * as TYPES from "./dashboardTypes";
import Network from "../../network";

const { API, Config } = Network;

function* getLoginResumeParseData(action) {
  try {
    const resumeId = action.payload;
    const result = yield call(
      API.get,
      Config.resumeParse.resumeParseData(resumeId),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    localStorage.setItem(
      "userNameFromPrimaryResume",
      result?.ContactInfo?.GivenName
    );
    yield put(ACTIONS.resumeParserSuccess(result));
    // yield put(ACTIONS.extractSkillsRequest(result.Value.Text));
  } catch (error) {
    yield put(ACTIONS.resumeParserFailure(error));
  }
}

function* getExtractSkillsData(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      Config.resumeParse.extractSkills,
      { title_id: "", text: params },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.extractSkillsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.extractSkillsFailure(error));
  }
}

function* handleAcceptOverLay(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.put,
      Config.ProfileDetails.OverLayAccept,
      { [params.key]: params.value },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(LOGIN_ACTIONS.getUserDataSuccess(result));
  } catch (error) {
    // yield put(ACTIONS.extractSkillsFailure(error));
  }
}

function* handleSaveCompanyDatils(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.put,
      Config.ProfileDetails.saveCompanyDatils,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(LOGIN_ACTIONS.getUserDataSuccess(result));
    yield put(ACTIONS.saveCompanyDatailsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.saveCompanyDatailsFailure(result));
  }
}

function* handleGetCompanyFilteres(action) {
  try {
    const result = yield call(
      API.get,
      Config.ProfileDetails.getCompanyFilteres,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getCompanyFilteresSuccess(result));
    // yield put(ACTIONS.extractSkillsRequest(result.Value.Text));
  } catch (error) {
    yield put(ACTIONS.getCompanyFilteresFailure(error));
  }
}

// function* favouriteClick(action) {
//   try {
//     const params = action.payload;
//     const { operation, companyId } = params;
//     yield put(COMPANYACTIONS.companiesLoader(true));
//     const result = yield call(
//       API.get,
//       Config.favourite.favouriteUrl(operation, companyId),
//       {
//         Authorization: localStorage.getItem("unnlockUserToken"), // id_token
//       }
//     );
//     yield put(ACTIONS.favouriteClickSuccess(result));
//     yield put(COMPANYACTIONS.companiesLoader(false));
//   } catch (error) {
//     yield put(ACTIONS.favouriteClickFailure(error));
//     yield put(COMPANYACTIONS.companiesLoader(false));
//   }
// }

export function* requestLoginParseData() {
  yield takeLatest(TYPES.RESUME_PARSER_REQUEST, getLoginResumeParseData);
}
export function* requestExtractSkillsData() {
  yield takeLatest(TYPES.EXTRACT_SKILLS_REQUEST, getExtractSkillsData);
}
export function* requestAcceptOverLay() {
  yield takeLatest(TYPES.ACCEPT_OVERlAY_REQUEST, handleAcceptOverLay);
}
export function* requestSaveCompanyDatils() {
  yield takeLatest(TYPES.SAVE_COMPANY_DATILS_REQUEST, handleSaveCompanyDatils);
}
export function* requestGetComapnyFilteres() {
  yield takeLatest(
    TYPES.GET_COMPANY_FILTERS_DATILS_REQUEST,
    handleGetCompanyFilteres
  );
}
// export function* requestFavClicked() {
//   yield takeLatest(TYPES.FAVOURITE_REQUEST, favouriteClick);
// }
