/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import * as ComponiesActions from "../../store/componies/action";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

const SearchInput = ({
  jobMatchSearch,
  defaultValue,
  placeholder,
  onChange,
  nextStepClicked,
  setselectedComp,
  initialEmpty,
  style,
}) => {
  const [data, SetData] = useState([]);
  const [value, SetValue] = useState(undefined);

  const dispatch = useDispatch();

  const companyList = useSelector((state) => state.componies.autoComplet);
  const companyLoader = useSelector((state) => state.componies.Loader);
  const dashboardState = useSelector((state) => state.dashboard);

  const handleSearch = (value) => {
    if (value) {
      SetData([]);
      SetValue(value);
      onChange({
        text: value,
      });
      dispatch(
        ComponiesActions.companiesAutoCompleteRequest({ companyName: value })
      );
      setselectedComp();
    } else {
      SetData([]);
      SetValue(value);
    }
  };

  const handleChange = (val) => {
    SetValue(val);
    let found = data.filter((item) => item.value === Number(val));
    if (found[0]) {
      onChange(found[0]);
    } else {
      if (jobMatchSearch && !companyLoader)
        onChange({
          value: "",
          text: value,
          logo_url: "",
        });
    }
  };

  useEffect(() => {
    if (Array.isArray(companyList) && companyList.length) {
      SetData(() =>
        companyList.map((item) => {
          return {
            value: item.unnlock_id,
            text: item.company,
            logo_url: item.logo_url,
          };
        })
      );
    } else {
      if (jobMatchSearch && !companyLoader)
        SetData([
          {
            value: "",
            text: value,
            logo_url: "",
          },
        ]);
    }
  }, [companyList]);

  useEffect(() => {
    if (
      Array.isArray(dashboardState.topCompanies) &&
      dashboardState.topCompanies.length &&
      dashboardState.topCompanies[0].name &&
      !nextStepClicked &&
      !initialEmpty
    ) {
      let strArr = `${dashboardState.topCompanies[0].name}`.split("");
      let str = strArr[0] + strArr[1] + strArr[2];
      handleSearch(str);
      SetValue(str);
      document.getElementById("company_search_input").focus();
    }
  }, [dashboardState.topCompanies]);

  useEffect(() => {
    if (defaultValue) {
      SetValue(defaultValue);
    }
  }, [defaultValue]);

  const options = data.map((d) => (
    <Option key={d.value}>
      <img src={d.logo_url} style={{ width: "20px", marginRight: "10px" }} />
      {d.text}
    </Option>
  ));

  return (
    <div className="Common_input_container" style={style}>
      {/* <div className="label">{placeholder}</div> */}
      <Select
        showSearch
        value={value}
        placeholder={placeholder}
        style={{
          padding: "0px",
        }}
        className="companyInput"
        defaultActiveFirstOption={false}
        bordered={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={companyLoader ? <Spin size="small" /> : null}
        id="company_search_input"
        defaultOpen={false}
        allowClear
      >
        {options}
      </Select>
    </div>
  );
};

export default SearchInput;
