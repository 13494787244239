import React from "react";
import Microlink from "@microlink/react";

import "./linkPreview.css";

const LinkPreview = ({ index, link }) => {
  return (
    <div className="LinkPreviewCard">
      <Microlink url={link} />
      <button
        className="readMoreBtn"
        onClick={() => {
          document.getElementsByClassName("epNpdM")[index].click();
        }}
      >
        Read More
      </button>
    </div>
  );
};

export default LinkPreview;
