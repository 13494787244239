import {List, Button, Avatar} from 'antd';

const LocationsList = ({ map, locations, handleCompanyClickedCallback }) => {  
  const zoomToFeature = (feature) => {
    if (feature.geometry.coordinates.length > 0 && map) {
      const lat = feature.geometry.coordinates[1];
      const lng = feature.geometry.coordinates[0];
      map.setView([lat,lng], 14);
    }
  }

  return (
    <List
      bordered={true}
      style={{"borderRadius": "0px 8px 8px 0px", "height":"600px", "overflowY":"auto"}}
      dataSource={
        locations['features'].sort((a, b) => (a.properties.company.company > b.properties.company.company) ? 1 : (a.properties.company.company === b.properties.company.company ) ? ((a.properties.Municipality  > b.properties.Municipality) ? 1 : -1) : -1 )
      }
      renderItem={feature => (
        <List.Item 
          onClick={() => zoomToFeature(feature)}
          actions={[<Button type="link" onClick={() => handleCompanyClickedCallback(feature.properties.company.unnlock_id)}>View</Button>]}
        >
            <List.Item.Meta
            avatar={<Avatar shape="square" size="large" src={feature.properties.company.logo_url} />}
            title={feature.properties.company.company}
            description={feature.properties.Municipality}
            />
        </List.Item>
      )}
    />
  )
}

export default LocationsList;