import * as types from "./registerTypes";

const INITIAL_STATE = {
  loader: false,
  uploadResumeLoader: false,
  dataAfterRegister: "",
  dataAfterResumeParse: "",
  userAlreadyExists: false,
  uploadResumeSuccess: false,
  socialRegisterSuccess: false,
  preUpladData: "",
  user_socs: [],
  error: "",
  emailStatus: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REGISTER_REQUEST:
      return {
        ...state,
        loader: true,
        userAlreadyExists: false,
        dataAfterRegister: "",
        error: "",
      };
    case types.REGISTER_REQUEST_SUCCESS:
      return {
        ...state,
        loader: false,
        userAlreadyExists: action.payload.userExistsAlready,
        dataAfterRegister: action.payload.data,
        error: "",
      };
    case types.REGISTER_REQUEST_FAILURE:
      return {
        ...state,
        loader: false,
        userAlreadyExists: false,
        dataAfterRegister: "",
        error: action.payload,
      };
    case types.UPLOAD_RESUME_REQUEST:
      return {
        ...state,
        dataAfterResumeParse: "",
        uploadResumeLoader: true,
        uploadResumeSuccess: false,
        error: "",
      };
    case types.UPLOAD_RESUME_SUCCESS:
      return {
        ...state,
        loader: false,
        uploadResumeLoader: false,
        dataAfterResumeParse: action.payload,
        uploadResumeSuccess: true,
        error: "",
      };
    case types.UPLOAD_RESUME_FAILURE:
      return {
        ...state,
        loader: false,
        uploadResumeLoader: false,
        dataAfterResumeParse: "",
        uploadResumeSuccess: false,
        error: action.payload,
      };

    case types.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        emailStatus: action.payload,
      };

    case types.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        emailStatus: action.payload,
      };

    case types.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        emailStatus: action.payload,
      };

    case types.GET_ALL_SOCS_REQUEST:
      return {
        ...state,
        error: "",
      };
    case types.GET_ALL_SOCS_SUCCESS:
      return {
        ...state,
        user_socs: action.payload,
        error: "",
      };
    case types.GET_ALL_SOCS_FAILURE:
      return {
        ...state,
        user_socs: [],
        error: action.payload,
      };

    case types.SAVE_USER_SOCS_REQUEST:
      return {
        ...state,
        error: "",
      };
    case types.SAVE_USER_SOCS_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case types.SAVE_USER_SOCS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.SOCIAL_REGISTER_REQUEST:
      return {
        ...state,
        loader: true,
        socialRegisterSuccess: false,
        dataAfterRegister: "",
        error: "",
      };
    case types.SOCIAL_REGISTER_SUCCESS:
      return {
        ...state,
        loader: false,
        socialRegisterSuccess: true,
        dataAfterRegister: action.payload,
        error: "",
      };
    case types.SOCIAL_REGISTER_FAILURE:
      return {
        ...state,
        loader: false,
        socialRegisterSuccess: false,
        dataAfterRegister: "",
        error: action.payload,
      };
    case types.CREATE_MAILER_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.CREATE_MAILER_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.CREATE_MAILER_SUBSCRIBER_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.UPDATE_MAILER_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.UPDATE_MAILER_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.UPDATE_MAILER_SUBSCRIBER_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case types.SAVEPREUPLOADDATA:
      return {
        ...state,
        loader: false,
        socialRegisterSuccess: false,
        preUpladData: action.payload,
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
