import * as types from "./profileTypes";

export const setProfileLoader = (payload) => ({
  type: types.SET_PROFILE_LOADER,
  payload,
});

export const setPaymentModal = (payload) => ({
  type: types.SET_PAYMENT_MODAL,
  payload,
});

export const reset = () => ({
  type: types.RESET,
});

export const setAlertModal = (payload) => ({
  type: types.SET_ALERT_MODAL,
  payload,
});

export const profilePicUpdateRequest = (payload) => ({
  type: types.UPDATE_PROFILE_PIC_REQUEST,
  payload,
});
export const profilePicUpdateSuccess = (payload) => ({
  type: types.UPDATE_PROFILE_PIC_SUCCESS,
  payload,
});
export const profilePicUpdateFailure = (payload) => ({
  type: types.UPDATE_PROFILE_PIC_FAILURE,
  payload,
});

export const getprofileRequest = (payload) => ({
  type: types.GET_PROFILE_REQUEST,
  payload,
});
export const getprofileSuccess = (payload) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload,
});
export const getprofileFailure = (payload) => ({
  type: types.GET_PROFILE_FAILURE,
  payload,
});

export const addprofileRequest = (payload) => ({
  type: types.ADD_PROFILE_REQUEST,
  payload,
});
export const addprofileSuccess = (payload) => ({
  type: types.ADD_PROFILE_SUCCESS,
  payload,
});
export const addprofileFailure = (payload) => ({
  type: types.ADD_PROFILE_FAILURE,
  payload,
});

export const uploadProfilePicRequest = (payload) => ({
  type: types.UPLOAD_PROFILE_PIC_REQUEST,
  payload,
});
export const uploadProfilePicSuccess = (payload) => ({
  type: types.UPLOAD_PROFILE_PIC_SUCCESS,
  payload,
});
export const uploadProfilePicFailure = (payload) => ({
  type: types.UPLOAD_PROFILE_PIC_FAILURE,
  payload,
});

export const getSubscriptionRequest = (payload) => ({
  type: types.GET_SUBSCRIPTION_REQUEST,
  payload,
});
export const getSubscriptionSuccess = (payload) => ({
  type: types.GET_SUBSCRIPTION_SUCCESS,
  payload,
});
export const getSubscriptionFailure = (payload) => ({
  type: types.GET_SUBSCRIPTION_FAILURE,
  payload,
});

export const createCustomerRequest = (payload) => ({
  type: types.CREATE_CUSTOMER_REQUEST,
  payload,
});
export const createCustomerSuccess = (payload) => ({
  type: types.CREATE_CUSTOMER_SUCCESS,
  payload,
});
export const createCustomerFailure = (payload) => ({
  type: types.CREATE_CUSTOMER_FAILURE,
  payload,
});

export const createSubscriptionRequest = (payload) => ({
  type: types.CREATE_SUBSCRIPTION_REQUEST,
  payload,
});
export const createSubscriptionSuccess = (payload) => ({
  type: types.CREATE_SUBSCRIPTION_SUCCESS,
  payload,
});
export const createSubscriptionFailure = (payload) => ({
  type: types.CREATE_SUBSCRIPTION_FAILURE,
  payload,
});

export const cancelSubscriptionRequest = (payload) => ({
  type: types.CANCEL_SUBSCRIPTION_REQUEST,
  payload,
});
export const cancelSubscriptionSuccess = (payload) => ({
  type: types.CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});
export const cancelSubscriptionFailure = (payload) => ({
  type: types.CANCEL_SUBSCRIPTION_FAILURE,
  payload,
});

export const getCardsRequest = (payload) => ({
  type: types.GET_CARDS_REQUEST,
  payload,
});
export const getCardsSuccess = (payload) => ({
  type: types.GET_CARDS_SUCCESS,
  payload,
});
export const getCardsFailure = (payload) => ({
  type: types.GET_CARDS_FAILURE,
  payload,
});

export const changePassRequest = (payload) => ({
  type: types.CHANGE_PASS_REQUEST,
  payload,
});
export const changePassSuccess = (payload) => ({
  type: types.CHANGE_PASS_SUCCESS,
  payload,
});
export const changePassFailure = (payload) => ({
  type: types.CHANGE_PASS_FAILURE,
  payload,
});

export const forgotPassRequest = (payload) => ({
  type: types.FORGOT_PASS_REQUEST,
  payload,
});
export const forgotPassSuccess = (payload) => ({
  type: types.FORGOT_PASS_SUCCESS,
  payload,
});
export const forgotPassFailure = (payload) => ({
  type: types.FORGOT_PASS_FAILURE,
  payload,
});

export const getPaymentMethodRequest = (payload) => ({
  type: types.GET_PAY_METHODS_REQUEST,
  payload,
});
export const getPaymentMethodSuccess = (payload) => ({
  type: types.GET_PAY_METHODS_SUCCESS,
  payload,
});
export const getPaymentMethodFailure = (payload) => ({
  type: types.GET_PAY_METHODS_FAILURE,
  payload,
});

export const attachPayMethodRequest = (payload) => ({
  type: types.ATTACH_CARD_REQUEST,
  payload,
});
export const attachPayMethodSuccess = (payload) => ({
  type: types.ATTACH_CARD_SUCCESS,
  payload,
});
export const attachPayMethodFailure = (payload) => ({
  type: types.ATTACH_CARD_FAILURE,
  payload,
});

export const detachPayMethodRequest = (payload) => ({
  type: types.DETACH_CARD_REQUEST,
  payload,
});
export const detachPayMethodSuccess = (payload) => ({
  type: types.DETACH_CARD_SUCCESS,
  payload,
});
export const detachPayMethodFailure = (payload) => ({
  type: types.DETACH_CARD_FAILURE,
  payload,
});

export const updateSubPaymentRequest = (payload) => ({
  type: types.UPDATE_SUB_REQUEST,
  payload,
});
export const updateSubPaymentSuccess = (payload) => ({
  type: types.UPDATE_SUB_SUCCESS,
  payload,
});
export const updateSubPaymentFailure = (payload) => ({
  type: types.UPDATE_SUB_FAILURE,
  payload,
});

export const updateSelectedPlan = (payload) => ({
  type: types.UPDATE_SELECTED_PLAN,
  payload,
});
