/* eslint-disable */
import * as types from "./persanalityTestTypes";

const INITIAL_STATE = {
  loader: false,
  questions: "",
  results: "",
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_QUESTIONS_REQUEST:
      return {
        ...state,
        loader: true,
        questions: "",
        error: "",
      };
    case types.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        loader: false,
        questions: action.payload,
        error: "",
      };
    case types.GET_QUESTIONS_FAILURE:
      return {
        ...state,
        loader: false,
        questions: "",
        error: action.payload,
      };
    case types.GET_RESULTS_REQUEST:
      return {
        ...state,
        loader: true,
        results: "",
        error: "",
      };
    case types.GET_RESULTS_SUCCESS:
      return {
        ...state,
        loader: false,
        results: action.payload,
        error: "",
      };
    case types.GET_RESULTS_FAILURE:
      return {
        ...state,
        loader: false,
        results: "",
        error: action.payload,
      };
    case types.SUBMIT_RESULTS_REQUEST:
      return {
        ...state,
        loader: true,
        results: "",
        error: "",
      };
    case types.SUBMIT_RESULTS_SUCCESS:
      return {
        ...state,
        loader: false,
        results: action.payload,
        error: "",
      };
    case types.SUBMIT_RESULTS_FAILURE:
      return {
        ...state,
        loader: false,
        results: "",
        error: action.payload,
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
