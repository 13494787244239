import { notification } from "antd";

const unnlockNotification = (message, type, top, duration) => {
  return notification[type]({
    message: message,
    placement: "top",
    duration: duration ? duration : 2,
    top: top ? top : 100,
  });
};

export default unnlockNotification;
