/* eslint-disable */
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import App from "./containers/App";
import configureStore from "./store/store";

import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
