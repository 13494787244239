import React from "react";
import Tools from "../Tools";
import MJRTopConatiner from "./topContainer";
import "./mjr.scss";
import BottomContainer from "./bottomContainer";

const MatchJobResults = () => {
  return (
    <Tools>
      <div className="mjr_container">
        <MJRTopConatiner />
        <BottomContainer />
      </div>
    </Tools>
  );
};

export default MatchJobResults;
