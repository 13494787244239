import * as types from "./registerTypes";

// Register Actions
export const registerRequest = (payload) => ({
  type: types.REGISTER_REQUEST,
  payload,
});
export const registerSuccess = (payload) => ({
  type: types.REGISTER_REQUEST_SUCCESS,
  payload,
});
export const registerFailure = (payload) => ({
  type: types.REGISTER_REQUEST_FAILURE,
  payload,
});

// Register Actions
export const saveUSerSubsRequest = (payload) => ({
  type: types.SAVE_USER_SUBS_REQUEST,
  payload,
});
export const saveUSerSubsSuccess = (payload) => ({
  type: types.SAVE_USER_SUBS_SUCCESS,
  payload,
});
export const saveUSerSubsFailure = (payload) => ({
  type: types.SAVE_USER_SUBS_FAILURE,
  payload,
});

// Upload Resume Actions
export const uploadResumeRequest = (payload) => ({
  type: types.UPLOAD_RESUME_REQUEST,
  payload,
});
export const uploadResumeSuccess = (payload) => ({
  type: types.UPLOAD_RESUME_SUCCESS,
  payload,
});
export const uploadResumeFailure = (payload) => ({
  type: types.UPLOAD_RESUME_FAILURE,
  payload,
});

// Upload User to S3 bucket
export const uploadToS3Request = (payload) => ({
  type: types.UPLOAD_TOS3_REQUEST,
  payload,
});
export const uploadToS3Success = (payload) => ({
  type: types.UPLOAD_TOS3_SUCCESS,
  payload,
});
export const uploadToS3Failure = (payload) => ({
  type: types.UPLOAD_TOS3_FAILURE,
  payload,
});

// Upload User to S3 bucket
export const checkValidEmailRequest = (payload) => ({
  type: types.CHECK_EMAIL_REQUEST,
  payload,
});
export const checkValidEmailSuccess = (payload) => ({
  type: types.CHECK_EMAIL_SUCCESS,
  payload,
});
export const checkValidEmailFailure = (payload) => ({
  type: types.CHECK_EMAIL_FAILURE,
  payload,
});

//User Socs
export const getAllSocsRequest = (payload) => ({
  type: types.GET_ALL_SOCS_REQUEST,
  payload,
});
export const getAllSocsSuccess = (payload) => ({
  type: types.GET_ALL_SOCS_SUCCESS,
  payload,
});
export const getAllSocsFailure = (payload) => ({
  type: types.GET_ALL_SOCS_FAILURE,
  payload,
});

//Save user socs
export const saveUserSocsRequest = (payload) => ({
  type: types.SAVE_USER_SOCS_REQUEST,
  payload,
});
export const saveUserSocsSuccess = (payload) => ({
  type: types.SAVE_USER_SOCS_SUCCESS,
  payload,
});
export const saveUserSocsFailure = (payload) => ({
  type: types.SAVE_USER_SOCS_FAILURE,
  payload,
});

// Social Register Actions
export const socialRegisterRequest = (payload) => ({
  type: types.SOCIAL_REGISTER_REQUEST,
  payload,
});
export const socialRegisterSuccess = (payload) => ({
  type: types.SOCIAL_REGISTER_SUCCESS,
  payload,
});
export const socialRegisterFailure = (payload) => ({
  type: types.SOCIAL_REGISTER_FAILURE,
  payload,
});

export const savePreUploadData = (payload) => ({
  type: types.SAVEPREUPLOADDATA,
  payload,
});

// create mailer subscription Actions
export const createMailerSubscriberRequest = (payload) => ({
  type: types.CREATE_MAILER_SUBSCRIBER_REQUEST,
  payload,
});
export const createMailerSubscriberSuccess = (payload) => ({
  type: types.CREATE_MAILER_SUBSCRIBER_SUCCESS,
  payload,
});
export const createMailerSubscriberFailure = (payload) => ({
  type: types.CREATE_MAILER_SUBSCRIBER_FAILURE,
  payload,
});

// update mailer subscription Actions
export const updateMailerSubscriberRequest = (payload) => ({
  type: types.UPDATE_MAILER_SUBSCRIBER_REQUEST,
  payload,
});
export const updateMailerSubscriberSuccess = (payload) => ({
  type: types.UPDATE_MAILER_SUBSCRIBER_SUCCESS,
  payload,
});
export const updateMailerSubscriberFailure = (payload) => ({
  type: types.UPDATE_MAILER_SUBSCRIBER_FAILURE,
  payload,
});

// reset
export const reset = () => ({
  type: types.RESET,
});
