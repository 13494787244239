import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import "./index.scss";
import ByCompanyName from "./byCompanyName";
import BySkills from "./bySkills";
import { GetOnboardSuggestions } from "../ResumeOpimizer/suggestions";
import { getUserIndustriesRequest } from "../../store/newLogin/newLoginAction";

const ExploreComp = (props) => {
  // const params = useParams();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  let location = useLocation();
  console.log("props in explore page", location.pathname);

  const [value, setValue] = useState(2);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (loginState.userData && !loginState.userData.user_industries) {
      dispatch(
        getUserIndustriesRequest({
          industry: loginState.userData.current_company.industry,
        })
      );
    }
  }, [loginState.userData]);

  return (
    <div className="explore_comp">
      <div className="Exploretitle">Company Explore</div>
      <div className="exploreComp_container">
        <div className="card_conatiner">
          <div className="filteres">
            <h3 style={{ margin: "0px" }}>
              Discover companies that match your criteria
            </h3>
            <Radio.Group
              onChange={onChange}
              value={value}
              className="filters-radiobutton"
            >
              <Radio
                value={2}
                style={{
                  color: value == 2 ? "#4389FA" : "#261C4B70",
                  fontSize: "1rem",
                  fontWeight: value == 2 ? 500 : 400,
                }}
              >
                By Skills
              </Radio>
              <Radio
                value={1}
                style={{
                  color: value == 1 ? "#4389FA" : "#261C4B70",
                  fontSize: "1rem",
                  fontWeight: value == 1 ? 500 : 400,
                }}
              >
                By Company
              </Radio>
            </Radio.Group>
          </div>
          {value === 1 ? <ByCompanyName /> : <BySkills />}
        </div>
        <div className="exploreComp-whycontainer">
          <GetOnboardSuggestions screen={value === 1 ? "exploreByComp" : "exploreBySkills"} />
        </div>
      </div>
    </div>
  );
};

export default ExploreComp;
