import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Select } from "antd";
import { resumeParserRequest } from "../../store/dashBoard/dashboardActions";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";
import unnlockNotification from "../../components/notification";
import {
  getOpenJobs,
  setOpenJobFilters,
  setOpenJobsResume,
} from "../../store/Tools/toolsActions";
import "../Explore/index.scss";

import {
  getResumeVaultsRequest,
  setCurrentResumeId,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import {
  searchCompBySkillsRequest,
  setExploreSkills,
} from "../../store/componies/action";
import { stateData } from "../../utils/Data";

const { Option } = Select;

const BySkills = ({}) => {
  const [Suggestedskills, setSuggestedskills] = useState([]);
  const [selectedState, setSelectedState] = useState(undefined);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [viewAll, setviewAll] = useState(true);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.dashboard.loader);
  const componiesLoader = useSelector((state) => state.tools.loading);
  const loginState = useSelector((state) => state.login);
  const dashboardState = useSelector((state) => state.dashboard);

  const [currentResume, setcurrentResume] = useState("0");

  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);

  const handleSubmit = () => {
    if (selectedSkills.length < 5) {
      unnlockNotification("More than 5 skills should be selected", "warning");
    } else {
      if (!selectedState) {
        unnlockNotification("Please select the state.", "warning");
      } else {
        localStorage.setItem("openJobSkills", JSON.stringify(selectedSkills));
        localStorage.setItem("openJobState", selectedState);
        localStorage.setItem("openJobResume", currentResume);
        dispatch(
          getOpenJobs({
            specialized_skills: selectedSkills,
            state_list: [selectedState],
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getResumeVaultsRequest());
    setcurrentResume(loginState.userData?.primary_resume);
    dispatch(setCurrentResumeId(loginState.userData?.primary_resume));
    dispatch(setOpenJobsResume(loginState.userData?.primary_resume));
    //setcurrentResume("0");
  }, [loginState.loader]);

  useEffect(() => {
    if (dashboardState.skillsForAtsView) {
      setSuggestedskills([
        ...dashboardState.skillsForAtsView
          .filter((item) => item.id == "ST1")
          .map((item) => item.name),
      ]);
    }
  }, [dashboardState.skillsForAtsView]);

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.primary_resume &&
      !dashboardState.employementInfo?.employment[0]?.designation
    ) {
      dispatch(resumeParserRequest(loginState.userData.primary_resume));
    }
  }, [loginState.userData]);

  return (
    <div>
      <Spin size="large" spinning={componiesLoader}>
        <div className="selecteddSkills">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ padding: "20px 20px 20px 0px", fontWeight: 600 }}>
              Discover jobs that match your criteria
            </h3>
            <div className="ByCompanyName_submit" style={{ position: "unset" }}>
              {selectedSkills.length > 4 ? (
                <button
                  type="button"
                  onClick={() => (!componiesLoader ? handleSubmit() : {})}
                >
                  {componiesLoader ? (
                    "Loading..!"
                  ) : (
                    <>
                      Next <img src={WhiteArrowLeft} />
                    </>
                  )}
                </button>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Select
              value={currentResume}
              defaultValue="Select Resume"
              onChange={(e) => {
                setcurrentResume(e);
                dispatch(resumeParserRequest(e));
                dispatch(setOpenJobsResume(e));
                dispatch(setCurrentResumeId(e));
              }}
              className="customeselectResume"
              style={{ width: "49%" }}
            >
              <Option key="0">Select Resume</Option>
              {resumeOptimizerState.resumeVaults.map((i) => (
                <Option key={i.resume_id}>{i.file_name}</Option>
              ))}
            </Select>
            <Select
              value={selectedState}
              defaultValue="Select State"
              onChange={(e) => {
                setSelectedState(e);
                dispatch(
                  setOpenJobFilters({
                    state: [e],
                    city: [],
                  })
                );
              }}
              className="customeselectResume"
              style={{ width: "49%" }}
            >
              <Option key={undefined}>Select State</Option>
              {stateData?.length &&
                stateData.map((i) => <Option key={i.value}>{i.value}</Option>)}
            </Select>
          </div>
          <div className="title">Selected Skills</div>
          <div className="skills">
            {selectedSkills.length ? (
              selectedSkills.map((item) => (
                <div
                  className="skill"
                  onClick={() =>
                    setSelectedSkills(selectedSkills.filter((i) => i !== item))
                  }
                >
                  <p>{item}</p>
                  <span>+</span>
                </div>
              ))
            ) : (
              <div style={{ margin: "auto" }}>No skills selected</div>
            )}
          </div>
        </div>
        <div className="suggestedSkills">
          <div className="title">Select minimum five skills from below</div>
          {loader || loginState.loader || !Suggestedskills.length ? (
            "Getting suggested skills..!"
          ) : (
            <div
              className="skills"
              // style={{ maxHeight: viewAll ? "300px" : "200px" }}
            >
              {Suggestedskills.length
                ? Suggestedskills.filter(
                    (item) => !selectedSkills.includes(item)
                  ).map((item, i) => (
                    <>
                      {i < 8 || viewAll ? (
                        <div
                          className="skill"
                          onClick={() => {
                            dispatch(
                              setExploreSkills([
                                ...selectedSkills.filter(
                                  (prev) => prev !== item
                                ),
                                item,
                              ])
                            );
                            setSelectedSkills([
                              ...selectedSkills.filter((prev) => prev !== item),
                              item,
                            ]);
                            // setSuggestedskills(
                            //   Suggestedskills.filter((sel) => sel !== item)
                            // );
                          }}
                        >
                          <p>{item}</p>
                          <span>+</span>
                        </div>
                      ) : null}
                    </>
                  ))
                : null}
              {viewAll || !Suggestedskills.length ? null : (
                <button
                  type="button"
                  className="View-button"
                  onClick={() => setviewAll(true)}
                >
                  View All
                </button>
              )}
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default BySkills;
