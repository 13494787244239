import * as types from "./profileTypes";

const INITIAL_STATE = {
  loading: false,
  basicInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    profileImg: "",
  },
  notifications: {
    email: false,
    sms: false,
    newsLeatter: false,
    web: false,
  },
  currentSubscription: {
    id: 0,
    expiery: "",
    lastupdate: "",
  },
  currentCompany: {
    company_to_recommend: 0,
    company_type: "",
    industry: "",
    location: "",
    name: "",
    size: "",
    unnlock_id: 0,
    url: "",
  },
  uploadUrl: "",
  subscriptions: [],
  customerId: null,
  clientSecrete: "",
  isPaymentModalOpen: false,
  isalertModalOpen: false,
  subscriptionId: "",
  stripePaymentId: "",
  savedCard: "",
  paymentMethods: [],
  uploadPic: false,
  user_socs: [],
  selectedPlan: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PROFILE_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case types.GET_PROFILE_REQUEST:
      return {
        ...state,
      };
    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        basicInfo: {
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          email: action.payload.email,
          phone: action.payload.phone,
          profileImg: action.payload.profile_img,
        },
        notifications: {
          email: action.payload.email_notification,
          sms: action.payload.sms_notification,
          newsLeatter: action.payload.newsletter_notification,
          web: action.payload.push_notification,
        },
        currentSubscription: {
          id: action.payload.tier_id,
          expiery: action.payload?.subscription_expiry,
          lastupdate: action.payload?.updated_at,
        },
        currentCompany: {
          company_to_recommend: action.payload.current_company?.company_to_recommend,
          company_type: action.payload.current_company?.company_type,
          industry: action.payload.current_company?.industry,
          location: action.payload.current_company?.location,
          name: action.payload.current_company?.name,
          size: action.payload.current_company?.size,
          unnlock_id: action.payload.current_company?.unnlock_id,
          url: action.payload.current_company?.url,
        },
        user_socs: action.payload?.user_socs,
        customerId: action.payload?.stripe_user_id || null,
        subscriptionId: action.payload?.stripe_subscription_id || "",
        stripePaymentId: action.payload?.stripe_payment_id || "",
      };
    case types.GET_PROFILE_FAILURE:
      return {
        ...state,
      };
    case types.ADD_PROFILE_REQUEST:
      return {
        ...state,
      };
    case types.ADD_PROFILE_SUCCESS:
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          profileImg: action.payload.profile_pic_url,
        },
        uploadUrl: action.payload.url,
      };
    case types.ADD_PROFILE_FAILURE:
      return {
        ...state,
      };
    case types.UPLOAD_PROFILE_PIC_REQUEST:
      return {
        ...state,
        uploadPic: false,
      };
    case types.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        uploadPic: true,
      };
    case types.UPLOAD_PROFILE_PIC_FAILURE:
      return {
        ...state,
        uploadPic: false,
      };
    case types.GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
      };
    case types.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case types.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
      };
    case types.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
      };
    case types.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerId: action.payload?.stripe_user_id,
      };
    case types.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
      };
    case types.CREATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
      };
    case types.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        clientSecrete:
          action.payload.latest_invoice.payment_intent.client_secret,
      };
    case types.CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
      };
    case types.GET_CARDS_REQUEST:
      return {
        ...state,
      };
    case types.GET_CARDS_SUCCESS:
      return {
        ...state,
        savedCard: action.payload.card,
      };
    case types.GET_CARDS_FAILURE:
      return {
        ...state,
      };
    case types.SET_PAYMENT_MODAL:
      return {
        ...state,
        isPaymentModalOpen: action.payload,
      };
    case types.SET_ALERT_MODAL:
      return {
        ...state,
        isalertModalOpen: action.payload,
      };
    case types.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentSubscription: {
          id: 0,
          expiery: "",
        },
      };
    case types.GET_PAY_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload.data,
      };
    case types.DETACH_CARD_SUCCESS:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case types.UPDATE_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan : action.payload,
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
