import * as Yup from "yup";

export const key = "achievements";
export const schema = [
  {
    field: "input",
    label: "Title",
    id: "title",
    type: "text",
  },
];

export const values = {
  title: "",
};
export const initialValues = { achievements: [values] };
export const validation = Yup.object().shape({
  achievements: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("title is required"),
    })
  ),
});
