/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import "../index.scss";
import SaveButton from "../saveButton";
import DescriptionEditor from "../experienceInfo/descriptionEditor";

const OtheresSections = () => {
  const dispatch = useDispatch();
  const resumeOptimizerData = useSelector((state) => state.resumeOptimizer);
  const [editorValue, setEditorvalue] = useState("");

  const handleSubmit = (values) => {
    const formData = {};
    formData[resumeOptimizerData.currentResumePage] = values;
    dispatch(
      ResumeActions.resumeUpdateRequest({
        formData,
        label: "other-update",
      })
    );
  };

  const handleEditorChange = (e) => {
    setEditorvalue(e);
  };

  useEffect(() => {
    if (
      typeof resumeOptimizerData[resumeOptimizerData.currentResumePage] ===
      "string"
    )
      setEditorvalue(
        resumeOptimizerData[resumeOptimizerData.currentResumePage]
      );
    else setEditorvalue("");
  }, [resumeOptimizerData[resumeOptimizerData.currentResumePage]]);

  return (
    <div className="update_experience_container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ color: "#261c4b", fontSize: "20px", fontWeight: 600 }}>
          {resumeOptimizerData.resumeScreens?.length &&
            resumeOptimizerData.resumeScreens.filter(
              (item) => item?.id === resumeOptimizerData.currentResumePage
            )[0]?.title}
        </div>
        <SaveButton
          handleClick={() => handleSubmit(editorValue)}
          styles={{ background: "none" }}
        />
      </div>
      <DescriptionEditor
        handleEditorChange={handleEditorChange}
        value={editorValue}
      />
    </div>
  );
};

export default OtheresSections;
