import * as types from "./jobMatchTypes";

const INITIAL_STATE = {
  loader: false,
  trackerID: "",
  trackerIDToUpdate: "",
  jobMatchResultData: {},
  jobTrackeres: [],
  jobMatchFetchData: {},
  showUpdateSkillsModal: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TRACKER_ID:
      return {
        ...state,
        loader: false,
        trackerID: action.payload,
        error: "",
      };
    case types.SHOW_UPDATE_SKILLS_MODAL:
      return {
        ...state,
        showUpdateSkillsModal: action.payload,
      };
    case types.SET_TRACKER_ID_TO_UPDATE:
      return {
        ...state,
        loader: false,
        trackerIDToUpdate: action.payload,
        error: "",
      };
    case types.JOBMATCHRESULTS_REQUEST:
      return {
        ...state,
        loader: true,
        // jobMatchResultData: {},
        error: "",
      };
    case types.JOBMATCHRESULTS_SUCCESS:
      return {
        ...state,
        loader: false,
        jobMatchResultData: action.payload,
        error: "",
      };
    case types.JOBMATCHRESULTS_FAILURE:
      return {
        ...state,
        loader: false,
        jobMatchResultData: {},
        error: action.payload,
      };

    case types.GET_JOBTACKERS_REQUEST:
      return {
        ...state,
        loader: true,
        jobMatchFetchData: "",
        error: "",
      };
    case types.GET_JOBTACKERS_SUCCESS:
      return {
        ...state,
        loader: false,
        jobTrackeres: action.payload,
        error: "",
      };
    case types.GET_JOBTACKERS_FAILURE:
      return {
        ...state,
        loader: false,
        jobTrackeres: [],
        error: action.payload,
      };

    case types.UPDATE_JOBMATCHRESULTS_REQUEST:
      return {
        ...state,
        loader: false,
        error: "",
      };
    case types.UPDATE_JOBMATCHRESULTS_SUCCESS:
      return {
        ...state,
        loader: false,
        jobMatchFetchData: "",
        jobTrackeres: state.jobTrackeres.map((item) => {
          if (item.job_tracker_id === action.payload.job_tracker_id) {
            return action.payload;
          }
          return item;
        }),
        error: "",
      };
    case types.UPDATE_JOBMATCHRESULTS_FAILURE:
      return {
        ...state,
        loader: false,
        jobTrackeres: [],
        error: action.payload,
      };

    case types.SAVE_JOBMATCHRESULTS_REQUEST:
      return {
        ...state,
        loader: true,
        error: "",
      };
    case types.SAVE_JOBMATCHRESULTS_SUCCESS:
      return {
        ...state,
        loader: false,
        jobTrackeres: [...state.jobTrackeres, action.payload],
        trackerIDToUpdate: action.payload.job_tracker_id,
        error: "",
      };
    case types.SAVE_JOBMATCHRESULTS_FAILURE:
      return {
        ...state,
        loader: false,
        error: action.payload,
      };

    case types.DELETE_JOBTACKERS_REQUEST:
      return {
        ...state,
        loader: true,
        error: "",
      };
    case types.DELETE_JOBTACKERS_SUCCESS:
      return {
        ...state,
        loader: false,
        jobTrackeres: action.payload,
        error: "",
      };
    case types.DELETE_JOBTACKERS_FAILURE:
      return {
        ...state,
        loader: false,
        jobTrackeres: [],
        error: action.payload,
      };

    case types.UPDATE_JOBTACKERS_REQUEST:
      return {
        ...state,
        loader: true,
        error: "",
      };
    case types.UPDATE_JOBTACKERS_SUCCESS:
      return {
        ...state,
        loader: false,

        // jobTrackeres: action.payload,
        error: "",
      };
    case types.UPDATE_JOBTACKERS_FAILURE:
      return {
        ...state,
        loader: false,
        jobTrackeres: [],
        error: action.payload,
      };

    case types.RESET:
      return {
        ...INITIAL_STATE,
      };

    case types.JOBMATCHFETCH_REQUEST:
      return {
        ...state,
        loader: true,
        jobMatchFetchData: {},
        error: "",
      };
    case types.JOBMATCHFETCH_SUCCESS:
      return {
        ...state,
        loader: false,
        jobMatchFetchData: action.payload,
        error: "",
      };
    case types.JOBMATCHFETCH_FAILURE:
      return {
        ...state,
        loader: false,
        jobMatchFetchData: "",
        error: action.payload,
      };

    default:
      return state;
  }
};
