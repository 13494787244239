import React, { useEffect, useState } from "react";
import { Modal } from "antd";
// import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import "../model.scss";
import * as ProfileActions from "../../../store/profile/profileActions";
import PaymentComp from "./payment";

const PaymentModel = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const isPaymentModalOpen = useSelector(
    (state) => state.profile.isPaymentModalOpen
  );

  const handleOk = () => {
    dispatch(ProfileActions.setPaymentModal(false));
  };

  const handleCancel = () => {
    dispatch(ProfileActions.setPaymentModal(false));
  };

  useEffect(() => {
    setVisible(isPaymentModalOpen);
  }, [isPaymentModalOpen]);

  // console.log(isPaymentModalOpen);

  return (
    <Modal
      title="Basic Modal"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="change_payment_model"
      width={800}
    >
      <div className="title">Payment</div>
      <div className="custom_model_contednt">
        <PaymentComp />
      </div>
    </Modal>
  );
};

export default PaymentModel;
