/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import history from "../../routes/History";
import "./model.scss";
import PaymentModel from "../Profile/payment/paymentModel";
import SubscriptionCard from "./subscriptionCard";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import * as ProfileActions from "../../store/profile/profileActions";
import UnnlockLayout from "../layout";
import ApplyCouponContainer from "../Dashboard/ApplyCoupon";
import MiinedButton from "../../components/Button";

const SubscriptionContainer = () => {
  const dispatch = useDispatch();
  const [showApplyCouponModal, setShowApplyCouponModal] = useState(false);

  const isLoading = useSelector((state) => state.profile.loading);
  const subscriptions = useSelector((state) => state.profile.subscriptions);
  const currentSub = useSelector((state) => state.profile.currentSubscription);
  const customerId = useSelector((state) => state.profile.customerId);
  const basicInfo = useSelector((state) => state.profile.basicInfo);

  const currentSubObj = subscriptions.find(
    (item) => item.tier_id === currentSub.id
  );

  useEffect(() => {
    if (
      customerId === null &&
      basicInfo.email !== "" &&
      typeof customerId !== "string"
    ) {
      const formData = {
        name: `${basicInfo.firstName} ${basicInfo.lastName}`,
        email: basicInfo.email,
      };
      dispatch(
        ProfileActions.createCustomerRequest({
          formData,
        })
      );
    }
  }, [basicInfo]);

  const subsTypeText = (subsData) => {
    if (subsData == "FREE_SUBSCRIPTION") {
      return "FREE";
    } else if (subsData == "MONTHLY_SUBSCRIPTION") {
      return "MONTHLY";
    } else if (subsData == "QUARTERLY_SUBSCRIPTION") {
      return "QUARTERLY";
    } else if (subsData == "YEARLY_SUBSCRIPTION") {
      return "YEARLY";
    } else if (subsData == "HALF_YEARLY_SUBSCRIPTION") {
      return "HALF YEARLY";
    } else {
      return "FREE";
    }
  };

  return (
    <UnnlockLayout>
      <div className="subscription_model">
        <Spin size="large" spinning={isLoading}>
          <div>
            <div className="plansContainer">
              <div
                // style={{
                //   padding: "15px",
                //   position: "absolute",
                //   top: "2%",
                //   left: "2%",
                //   cursor: "pointer",
                // }}
                className="backBtn"
                onClick={() => history.goBack()}
              >
                <img
                  src={ArrowLeft}
                  alt="arrow"
                  style={
                    {
                      // marginBottom: "4px",
                      // width: "20px",
                      // marginRight: "8px",
                    }
                  }
                />
              </div>
              <div className="subheader">
                <h1 className="subsTitle">Choose Your Plan</h1>
                <p className="subsDesc">
                  {`You are currently using the ${subsTypeText(
                    currentSubObj?.description
                  )} version. Upgrade to unlock
                  all features`}
                </p>
                <MiinedButton
                  title="Apply Coupon?"
                  onClick={() => {
                    setShowApplyCouponModal(!showApplyCouponModal);
                  }}
                />
              </div>
              <div className="cardsDiv">
                {subscriptions.map((item, i) => {
                  // if (!(item.tier_id === currentSub.id)) {
                  return (
                    <SubscriptionCard
                      currentSub={item.tier_id === currentSub.id}
                      subsData={item}
                      themetype={i % 2 === 0 ? "white" : "blue"}
                    />
                  );
                  // }
                })}
              </div>
            </div>
            <PaymentModel />
          </div>
        </Spin>
        <ApplyCouponContainer
          visible={showApplyCouponModal}
          setShowApplyCouponModal={setShowApplyCouponModal}
        />
      </div>
    </UnnlockLayout>
  );
};

SubscriptionContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default SubscriptionContainer;
