import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkOutForm";

const stripeKey = process.env.REACT_APP_ENV === "production" ?
  "pk_live_51IHUQrEgMeWk3wKnlvILub8ucBGJTmAn8YUA7ERTFz8A9BwVmYiApFC1xghVGO3GITcOXMrCGU7tZmDifQlduGLW00sBM3vhGV" :
  "pk_test_51IHUQrEgMeWk3wKniHFYLaLSpmhCxovpyL6nOf6VzMu6PgEyT6OL13Fw6JmDlLjNAD5J0Bhblbv2z90LOek7oyRW00osDVHaM2";

const stripePromise = loadStripe(
  stripeKey
);

const PaymentComp = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentComp;
