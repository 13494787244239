import React, { useEffect, useState } from "react";

import "./style.scss";

import teacherstatus from "../../assets/images/teacherstatus.svg";
import briefcasestatus from "../../assets/images/briefcasestatus.svg";
import userstatus from "../../assets/images/userstatus.svg";
import documentstatus from "../../assets/images/documentstatus.svg";
import CustomProgessBar from "./CustomProgressBar";

const UploadStatusCard = (props) => {
  const { filename } = props;

  const CircularCard = (props) => {
    const { strkColor, bgColor, title, pos, stImage } = props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="ring"
          style={{
            color: strkColor,
          }}
        >
          <div className="circlebg" style={{ background: bgColor }}>
            <img src={stImage} alt="" />
          </div>
        </div>

        <p className="statusTitletx">{title}</p>
      </div>
    );
  };
  return (
    <div className="uploaddiv">
      <p className="extractTitle">Extracting data for ATS Simulation...</p>
      <div className="circularCards">
        <CircularCard
          strkColor="#00805F"
          bgColor="#CFEEE6"
          title="Personal Info"
          pos="left"
          stImage={userstatus}
        />

        <CircularCard
          strkColor="#274075"
          bgColor="#DBE5FA"
          title="Experience & Skills"
          pos="bottom"
          stImage={briefcasestatus}
        />

        <CircularCard
          strkColor="#964D1A"
          bgColor="#FFEADB"
          title="Education"
          pos="right"
          stImage={teacherstatus}
        />

        <CircularCard
          strkColor="#502C8D"
          bgColor="#E1D1FC"
          title="Certifications"
          pos="top"
          stImage={documentstatus}
        />
      </div>
      <div className="circularCards-mobile">
        <div className="top-layer">
          <CircularCard
            strkColor="#00805F"
            bgColor="#CFEEE6"
            title="Personal Info"
            pos="left"
            stImage={userstatus}
          />

          <CircularCard
            strkColor="#274075"
            bgColor="#DBE5FA"
            title="Experience & Skills"
            pos="bottom"
            stImage={briefcasestatus}
          />
        </div>

        <div className="bottom-layer">
          <CircularCard
            strkColor="#964D1A"
            bgColor="#FFEADB"
            title="Education"
            pos="right"
            stImage={teacherstatus}
          />

          <CircularCard
            strkColor="#502C8D"
            bgColor="#E1D1FC"
            title="Certifications"
            pos="top"
            stImage={documentstatus}
          />
        </div>
      </div>

      <div className="statusbardiv">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <p
            className="atsTitletxt"
            style={{ marginLeft: "0px", color: "#261C4B", marginRight: "16px" }}
          >
            {filename}
          </p>
          <p style={{ marginBottom: "0px" }}>Uploading...</p>
        </div>

        <CustomProgessBar />
      </div>
    </div>
  );
};

export default UploadStatusCard;
