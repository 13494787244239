import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import { Skeleton, Row, Col } from "antd";
import {
  companiesSearchRequest,
  selectedCompanyId,
} from "../../store/componies/action";
import history from "../../routes/History";
import Heart from "../../assets/images/componies/heart.svg";
import UnnlockLayout from "../layout";
import documentText from "../../assets/images/resumeVault/document-text.svg";
import download from "../../assets/images/resumeVault/download.svg";
import pdf from "../../assets/images/resumeVault/pdf.svg";
import "./index.scss";
import {
  getResumeVaultsRequest,
  resumeRequest,
  setCurrentResumeId,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import DownloadModal from "../ResumeVault/downloadModal";
import PreviewModal from "../ResumeOpimizer/previewModal";
import JobTracker from "./jobTracker";
import { getJobTrackeresRequest } from "../../store/jobMatch/jobMatchActions";
import { resumeParserRequest } from "../../store/dashBoard/dashboardActions";
import CompanyDetails from "../Companies/CompanyDetails/CompanyDetails";
import CompanyCardLite from "../Companies/CompanyCardLite";

const convertDate = (date) => {
  if (date) {
    const d = date?.split("-");
    const t = d[3]?.split(":");
    return `${d[2]}-${d[1]}-${d[0]} ${t[0]}:${t[1]}`;
  }
};

const PersonalDashboard = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);
  const dashboardState = useSelector((state) => state.dashboard);
  const companyListObject = useSelector(
    (state) => state.componies.filteredCompanyList
  );
  const loginLoader = useSelector((state) => state.login.loader);
  const companyLoader = useSelector((state) => state.componies.Loader);
  const skillsForAtsView = useSelector(
    (state) => state.dashboard.skillsForAtsView
  );

  const [previewModal, setpreviewModal] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [isComponyDetails, setisComponyDetails] = useState(false);
  // const [viewJTAll, setviewJTAll] = useState(false);
  // const [isComingFromDash, setIsComingFromDash] = useState(false);

  const hardSkills =
    skillsForAtsView?.filter((item) => item?.id === "ST1") || [];
  const softSkills =
    skillsForAtsView?.filter((item) => item?.id === "ST2") || [];

  const [faverates, setFaverates] = useState([]);

  const handleCompanyClicked = (compId) => {
    dispatch(selectedCompanyId(compId));
    setisComponyDetails(true);
  };

  const handleViewAll = () => {
    // setviewJTAll(!viewJTAll);
    history.push("/jobTracker");
  };

  // const location = useLocation();

  // useEffect(() => {
  //   if (location.state && location.state.screen === "dashboardJobTracker") {
  //     setviewJTAll(true);
  //     setIsComingFromDash(true);
  //   }
  // }, [location]);

  useEffect(() => {
    dispatch(
      companiesSearchRequest({
        postData: {},
      })
    );
    if (!resumeOptimizerState.loader) dispatch(getResumeVaultsRequest());
    dispatch(getJobTrackeresRequest());
  }, [loginLoader]);

  useEffect(() => {
    const favs = userData.favourite_companies || [];
    if (companyListObject.length && favs.length) {
      const arr = companyListObject.filter((item) =>
        favs.includes(`${item.unnlock_id}`)
      );
      setFaverates(arr);
    }
  }, [companyListObject]);

  useEffect(() => {
    if (
      userData &&
      userData.primary_resume &&
      !dashboardState.employementInfo?.employment[0]?.designation
    ) {
      dispatch(resumeParserRequest(userData.primary_resume));
    }
  }, [userData]);

  const getCurrentResume = (item) => {
    if (resumeOptimizerState.currentResume !== item.resume_id) {
      dispatch(setCurrentResumeId(item.resume_id));
      dispatch(resumeRequest());
    }
  };

  // const isShowBackBtn = () => {
  //   // if (viewJTAll) {
  //   //   if (isComingFromDash) {
  //   //     return false;
  //   //   } else {
  //   //     return true;
  //   //   }
  //   // } else {
  //   //   return false;
  //   // }
  // };

  return (
    <UnnlockLayout>
      {isComponyDetails ? (
        <CompanyDetails
          handleBack={() => {
            setisComponyDetails(false);
          }}
          setisComponyDetails={setisComponyDetails}
        />
      ) : (
        <div className="personalDashboard_container">
          {/* {isShowBackBtn() ? (
            <div
              style={{
                marginBottom: "10px",
                color: "#606060",
                fontWeight: 600,
                marginRight: "20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleViewAll}
              className="back"
            >
              <span />
              Back
            </div>
          ) : null} */}
          <JobTracker handleViewAll={handleViewAll} viewJTAll={false} />

          <div
            className="headerCard"
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   alignItems: "center",
          //   paddingBottom: "10px",

          //   backgroundColor: "white",
          //   borderRadius: "10px",
          //   padding: "12px",
          // }}
          >
            <div className="title">
              <img
                src={Heart}
                style={{
                  marginBottom: "3px",
                  marginRight: "5px",
                }}
              />
              Favorite Companies{" "}
              {!companyLoader ? <span>({faverates.length})</span> : null}
            </div>
            {faverates.length && !companyLoader ? (
              <div
                style={{
                  margin: "0",
                  backgroundColor: "#eef3fe",
                  padding: "4px 16px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
                onClick={() =>
                  history.push({
                    pathname: "/companyMatch",
                    state: {
                      showFavByDefault: true,
                    },
                  })
                }
              >
                View All
              </div>
            ) : null}
          </div>
          {faverates.length ? (
            <div className="faviroutecompanylistwrap">
              {faverates.slice(0, 6).map((item, index) => (
                <>
                  <Skeleton loading={companyLoader} active>
                    <CompanyCardLite
                      item={item}
                      getFavClick={() => { }}
                      handleCompanyClicked={handleCompanyClicked}
                      faveratesList={[]}
                      fromDashboard
                    />
                  </Skeleton>
                </>
              ))}
            </div>
          ) : (
            <h3 style={{ textAlign: "center" }}>No Data Found</h3>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "12px",
              marginTop: "20px",
            }}
          >
            <div className="title">Hard Skills</div>
          </div>
          <Skeleton loading={companyLoader} active>
            <div className="skills_wrapper">
              <div className="skillsContainer">
                <div className="blue_bg">
                  {hardSkills.length ? (
                    hardSkills.map((skill) => (
                      <span style={{ margin: "8px 0px" }}>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          {skill.name}
                        </span>
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </span>
                    ))
                  ) : (
                    <h3>No Hard Skills Found</h3>
                  )}
                </div>
              </div>
            </div>
          </Skeleton>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "16px",
              marginBottom: "4px",
              padding: "12px",
              marginTop: "24px",
            }}
          >
            <div className="title">Soft Skills</div>
          </div>
          <Skeleton loading={companyLoader} active>
            <div className="skills_wrapper">
              <div className="skillsContainer">
                <div className="blue_bg">
                  {softSkills.length ? (
                    softSkills.map((skill) => (
                      <span style={{ margin: "8px 0px" }}>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          {skill.name}
                        </span>
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </span>
                    ))
                  ) : (
                    <h3>No Soft Skills Found</h3>
                  )}
                </div>
              </div>
            </div>
          </Skeleton>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "12px",
              marginTop: "24px",
            }}
          >
            <div className="title">Resumes</div>
          </div>
          <div className="resumeVaults">
            <Skeleton loading={resumeOptimizerState.loader} active>
              {resumeOptimizerState.resumeVaults.map((item, i) => (
                <div className="resumeVault">
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="documentText">
                      <img src={documentText} />
                    </div>
                    <div>
                      <div className="resume_name">
                        {item.file_name?.split(".")[0] || ""}
                      </div>
                      <p className="lastupdated">
                        Last Updated {convertDate(item.updated_date)}
                      </p>
                    </div>
                  </div>
                  <div className="controls">
                    <div
                      className="white_bg"
                      onClick={() => {
                        getCurrentResume(item);
                        setpreviewModal(!previewModal);
                      }}
                    >
                      <img src={pdf} alt="CopySimple" />
                    </div>
                    {/* <div
                      className="white_bg"
                      onClick={() => {
                        dispatch(setCurrentResumeId(item.resume_id));
                        setIsDownloadModalVisible(!isDownloadModalVisible);
                      }}
                    >
                      <img src={download} alt="DownloadSimple" />
                    </div> */}
                  </div>
                </div>
              ))}
            </Skeleton>
          </div>
        </div>
      )}
      <DownloadModal visible={isDownloadModalVisible} />
      <PreviewModal visible={previewModal} />
    </UnnlockLayout>
  );
};

export default PersonalDashboard;
