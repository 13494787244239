/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Spin, Tooltip } from "antd";
import history from "../../routes/History";
import * as DashBoardActions from "../../store/dashBoard/dashboardActions";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import * as RegisterActions from "../../store/newRegister/registerActions";
import * as ResumeOptimizerActions from "../../store/resumeOptimizer/resumeOptimizerActions";
import * as ComponiesActions from "../../store/componies/action";
import * as ProfileActions from "../../store/profile/profileActions";
import * as ToolsActions from "../../store/Tools/toolsActions";
import * as JobMatchActions from "../../store/jobMatch/jobMatchActions";
import avatar from "../../assets/images/DefaultImage.svg";
import Optimizer from "../../assets/images/dashboard/optimizer.svg";
import JobMatch from "../../assets/images/dashboard/jobMatch.svg";
import CompanyMatch from "../../assets/images/dashboard/companyMatch.svg";
import newOfferEvaluator from "../../assets/images/dashboard/newOfferEvaluator.svg";
import personalDashboard from "../../assets/images/dashboard/personalDashboard.svg";

// import Personality from "../../assets/images/dashboard/personality.svg";
// import tips from "../../assets/images/dashboard/tips.svg";
// import crossMark from "../../assets/images/crossMark.svg";
import closewhite from "../../assets/images/dashboard/closewhite.svg";
import explore from "../../assets/images/dashboard/explore.svg";
// import Reads from "../../assets/images/dashboard/Reads.svg";
// import ComingSoon from "../../assets/images/dashboard/comingSoon.svg";
import chatbot from "../../assets/images/chatbot.svg";
import UnnlockLayout from "../layout";
import "./Dashboard.scss";
import ApplyCouponContainer from "./ApplyCoupon";
import {
  getUserDataRequest,
  emailVerificationRequest,
} from "../../store/newLogin/newLoginAction";
import unnlockNotification from "../../components/notification";

// const upperData = [
//   {
//     src: CompanyMatch,
//     label: "Company Match",
//     desc: "Strategic recommendations based on deep learning AI analysis of your career path and industry trends.",
//   },
//   {
//     src: explore,
//     label: "Company Explore",
//     desc: "Discover companies in other industries that hire people on similar career paths. Research target companies.",
//   },
//   {
//     src: JobMatch,
//     label: "Job Match",
//     desc: "Don’t waste your time. See if your resume is a match for any job opening before you apply.",
//   },
//   {
//     src: newOfferEvaluator,
//     // label: "Offer Evaluator",
//     label: "Job Tracker",
//     desc: "Keep track of your job matches and applications.",
//   },
//   {
//     src: Optimizer,
//     label: "Resume Optimizer",
//     desc: "A comprehensive analysis of your resume with  recommendations",
//   },
//   {
//     src: personalDashboard,
//     label: "Open Jobs",
//     desc: "Recommended job openings based on your skill set, career profile, industry experience, and preferences.",
//   },
// ];

// const lowerData = [
//   // {
//   //   src: tips,
//   //   label: "Tips & FAQs",
//   //   desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do",
//   // },
//   // {
//   //   src: Reads,
//   //   label: "Interesting Reads",
//   //   desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do",
//   // },
//   // {
//   //   src: Personality,
//   //   label: "Personality Test",
//   //   desc: "Find the best career for you though our personality test",
//   // },
// ];

const col1 = [
  {
    src: Optimizer,
    label: "Resume Optimizer",
    desc: "A comprehensive analysis of your resume with  recommendations",
  },
  {
    src: explore,
    label: "Company Explore",
    desc: "Discover companies in other industries that hire people on similar career paths. Research target companies.",
  },
];
const col2 = [
  {
    src: CompanyMatch,
    label: "Company Match",
    desc: "Strategic recommendations based on deep learning AI analysis of your career path and industry trends.",
  },
  {
    src: chatbot,
    label: "Coach Unnbot",
    desc: "",
  },
  {
    src: newOfferEvaluator,
    // label: "Offer Evaluator",
    label: "Job Tracker",
    desc: "Keep track of your job matches and applications.",
  },
];
const col3 = [
  {
    src: JobMatch,
    label: "Job Match",
    desc: "Don’t waste your time. See if your resume is a match for any job opening before you apply.",
  },
  {
    src: personalDashboard,
    label: "Open Jobs",
    desc: "Recommended job openings based on your skill set, career profile, industry experience, and preferences.",
  },
];

const DashboardContainer2 = () => {
  const dispatch = useDispatch();
  // const resumeParseData = useSelector(
  //   (state) => state.dashboard.resumeParseData
  // );
  const loginState = useSelector((state) => state.login);
  const dashboardState = useSelector((state) => state.dashboard);
  const basicInfo = useSelector((state) => state.profile.basicInfo);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const contactInfo = useSelector((state) => state.dashboard.contactInfo);
  const [resumeErrors, SetResumeErrors] = useState("");
  const [isOfferClick, setIsOfferClick] = useState("");
  const [closeDesclimer, setcloseDesclimer] = useState(true);
  const [showApplyCouponModal, setShowApplyCouponModal] = useState(false);

  const signoutHandler = () => {
    window.localStorage.clear();
    dispatch(LoginActions.reset());
    dispatch(ProfileActions.reset());
    dispatch(ToolsActions.reset());
    dispatch(RegisterActions.reset());
    dispatch(ComponiesActions.reset());
    dispatch(ResumeOptimizerActions.reset());
    dispatch(DashBoardActions.reset());
    dispatch(JobMatchActions.reset());
    history.push("/");
  };

  useEffect(() => {
    if (
      loginState.userData &&
      !loginState.userData.onboarding_complete &&
      !loginState.loader
    ) {
      history.push("/onBoarding");
    } else {
      if (!localStorage.getItem("userNameFromPrimaryResume")) {
        if (loginState.userData.primary_resume)
          dispatch(
            DashBoardActions.resumeParserRequest(
              loginState.userData.primary_resume
            )
          );
        else {
          signoutHandler();
        }
      }
    }
    handleCompanyCardClick();
  }, [loginState]);

  // useEffect(() => {
  //   if (
  //     isOfferClick === "offer" &&
  //     dashboardState.companyFilteres?.industry_list
  //   ) {
  //     setIsOfferClick("");
  //     history.push("/offerEvaluator");
  //   }
  // }, [dashboardState]);

  // useEffect(() => {
  //   if (
  //     resumeParseData &&
  //     resumeParseData.simplified_doc.ResumeQuality &&
  //     resumeParseData.simplified_doc.ResumeQuality["sov:Assessments"][
  //       "sov:Assessment"
  //     ][0]["sov:Level"]
  //   ) {
  //     SetResumeErrors(
  //       resumeParseData.simplified_doc.ResumeQuality["sov:Assessments"][
  //         "sov:Assessment"
  //       ][0]
  //     );
  //   }
  // }, [resumeParseData]);

  const checkLocked = (data) => {
    if (data === "Resume Optimizer") {
      return false;
    } else {
      return true;
    }
  };

  const Card = ({ src, label, desc }, View) => (
    <div
      className={`dashboardContainer_eachcard ${View ? "mainCard" : ""}`}
      onClick={() => {
        if (
          View &&
          loginState.userData &&
          loginState.userData?.unnbot_processing === false
        ) {
          unnlockNotification(
            "We are still processing please try after some time!",
            "warning"
          );
        } else {
          onClickDashboardCard(label);
        }
      }}
      style={{
        opacity:
          View &&
          loginState.userData &&
          loginState.userData?.unnbot_processing === false
            ? 0.5
            : 1,
      }}
    >
      {checkLocked(label) ? null : (
        <>
          {resumeErrors ? (
            <Tooltip
              placement="bottom"
              title={resumeErrors["sov:Findings"]["sov:Information"][0]}
            ></Tooltip>
          ) : null}
        </>
      )}
      <img
        className="dashboardContainer_cardImage"
        src={src}
        alt={label}
        style={{ marginRight: "10px" }}
      />
      <div className="dashboardContainer_cardLabel_Desc">
        {View && loginState.userData?.unnbot_processing === false && (
          <Spin style={{ position: "absolute", right: "20px" }} />
        )}
        <p
          className="dashboardContainer_cardbigtext"
          // style={{ fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}
        >
          {label}
        </p>
        {desc ? (
          <div className="dashboardContainer_cardsubtext">{desc}</div>
        ) : null}
      </div>
    </div>
  );

  const handleCompanyCardClick = () => {
    if (loginState.userData.current_company) {
      localStorage.setItem(
        "comanyMatch_companyName",
        loginState.userData.current_company.company_to_recommend
      );
      localStorage.setItem(
        "comanyMatch_unnlock_id",
        loginState.userData.current_company?.unnlock_id ||
          loginState.userData.current_company?.company_to_recommend ||
          ""
      );
      localStorage.setItem("comanyMatch_companyUrl", "");
      localStorage.setItem("comanyMatch_companyLinkedIn", "");
    }
  };

  const onClickDashboardCard = (cardLabel) => {
    switch (cardLabel) {
      case "ATS View & Analysis":
        history.push("/analysis");
        break;
      case "Personality Test":
        history.push("/personalityTest");
        break;
      case "Job Match":
        history.push({
          pathname: `/jobmatch`,
          state: {
            fromOpenJobs: false,
          },
        });
        break;
      case "Resume Optimizer":
        history.push("/resumeVault");
        break;
      case "Coach Unnbot":
        history.push("/coachUnnbot");
        break;
      case "Tips & FAQs":
        history.push("/tipsAndFAQs");
        break;
      case "Job Tracker":
        history.push("/jobTracker");
        break;
      case "Interesting Reads":
        history.push("/interestingReads");
        break;
      case "Company Match":
        history.push("/companyMatch");
        break;
      case "Company Explore":
        history.push("/explore");
        break;
      case "Open Jobs":
        history.push("/OpenJobs");
        break;
      default:
        history.push("/dashboard");
    }
  };

  const handleEmailVericationClick = () => {
    let emailObj = {
      email: `${loginState?.userData?.email}`,
      subject: "Email Verification for UNNLOCK",
      email_type: "EMAIL_VERIFICATION",
    };
    dispatch(emailVerificationRequest(emailObj));
  };

  useEffect(() => {
    document.title = "unnlock";
    setInterval(() => {
      if (
        loginState.userData &&
        loginState.userData?.unnbot_processing === false
      ) {
        dispatch(getUserDataRequest({ dontLoad: true }));
      }
    }, 60000);
  }, []);

  return (
    <UnnlockLayout>
      <div className="dashboardContainer_loggedinUserwrap">
        <div className="dashboardContainer_loggedinUserinfo">
          <div
            style={{ marginRight: "20px" }}
            onClick={() => {
              history.push("/profile");
            }}
          >
            <Avatar
              shape="circle"
              size={60}
              style={{ cursor: "pointer" }}
              src={
                basicInfo.profileImg ? (
                  <img src={basicInfo.profileImg} alt="avatar" />
                ) : (
                  <img src={avatar} alt="avatar" />
                )
              }
              icon={
                basicInfo.profileImg ? (
                  <img src={basicInfo.profileImg} alt="avatar" />
                ) : (
                  <img src={avatar} alt="avatar" />
                )
              }
            />
          </div>
          {dashboardState.loader || loginState.loader ? null : (
            <div>
              <div
                className="dashboardContainer_bigtext"
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Welcome, {localStorage.getItem("userNameFromPrimaryResume")}
              </div>
              {/* <div className="dashboardContainer_userDesignation">
                {employementInfo.employment[0]?.designation},{" "}
                {employementInfo.employment[0]?.company}
              </div> */}
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {/* <div
            className="PersonalDashboardBtn"
            style={{ marginRight: "32px" }}
            onClick={() => {
              history.push("/coachUnnbot");
            }}
          >
            <button style={{ color: "rgba(0, 0, 0, 0.85)" }}>Chat Bot</button>
          </div> */}
          {/* {loginState?.userData?.tier_id === 0 ? (
            <div
              className="PersonalDashboardBtn"
              style={{ marginRight: "32px" }}
              onClick={() => {
                setShowApplyCouponModal(!showApplyCouponModal);
              }}
            >
              <button style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                Apply Coupon?
              </button>
            </div>
          ) : null} */}
          {!loginState.userData?.email_verified && (
            <div
              className="PersonalDashboardBtn"
              style={{ marginRight: "32px" }}
            >
              <button onClick={handleEmailVericationClick}>
                Email verification pending
              </button>
            </div>
          )}
          <div className="PersonalDashboardBtn">
            <button onClick={() => history.push("/personalDashboard")}>
              Personal Dashboard
            </button>
          </div>
        </div>
      </div>

      <div className="dashboardContainer_dashboardcardswrap">
        {closeDesclimer && loginState?.userData?.show_disclaimer && (
          <div className="desclimer">
            <div>
              <img
                src={closewhite}
                alt="close"
                onClick={() => {
                  dispatch(
                    DashBoardActions.acceptOverLayRequest({
                      key: "show_disclaimer",
                      value: "false",
                    })
                  );
                  setcloseDesclimer(false);
                }}
              />
            </div>
            <p>
              <p>You are in!</p>
              <p>
                This is your new career and job search home. Check out each tool
                below to identify target companies, find jobs that match your
                profile, manage job applications, and fix resume issues before
                you apply. Try starting with Company Match
              </p>
              <p>
                Please send feedback, bugs, and feature requests to
                feedback@unnlock.com.
              </p>
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
            background: "rgba(235, 243, 255, 0.5)",
            borderRadius: "10px",
          }}
        >
          <div className="outer">
            <div className="dashboardContainer_container">
              <div className="col1">
                {col1.map((item) => Card(item, false))}
              </div>
              <div className="col2">
                {col2.map((item, i) =>
                  // process.env.REACT_APP_ENV === "production" &&
                  //   item.label === "Coach Unnbot"
                  //   ? null
                  //   :
                  item.label === "Coach Unnbot"
                    ? Card(item, true)
                    : Card(item, false)
                )}
              </div>
              <div className="col3">
                {col3.map((item) => Card(item, false))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyCouponContainer
        visible={showApplyCouponModal}
        setShowApplyCouponModal={setShowApplyCouponModal}
      />
    </UnnlockLayout>
  );
};

export default DashboardContainer2;
