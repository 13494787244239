import * as Yup from "yup";

export const key = "references";
export const schema = [
  {
    field: "input",
    label: "name",
    id: "name",
    type: "text",
  },
  {
    field: "input",
    label: "Mobile",
    id: "mobile",
    type: "text",
  },
  {
    field: "input",
    label: "Email",
    id: "email",
    type: "text",
  },
  {
    field: "input",
    label: "Designation",
    id: "designation",
    type: "text",
  },
];

export const values = {
  name: "",
};
export const initialValues = { references: [values] };
export const validation = Yup.object().shape({
  references: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("name is required"),
    })
  ),
});
