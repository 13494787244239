import * as types from "./dashboardTypes";

// Resume Parser Actions
export const resumeParserRequest = (payload) => ({
  type: types.RESUME_PARSER_REQUEST,
  payload,
});
export const resumeParserSuccess = (payload) => ({
  type: types.RESUME_PARSER_SUCCESS,
  payload,
});
export const resumeParserFailure = (payload) => ({
  type: types.RESUME_PARSER_FAILURE,
  payload,
});

// Extract Skills Actions
export const extractSkillsRequest = (payload) => ({
  type: types.EXTRACT_SKILLS_REQUEST,
  payload,
});
export const extractSkillsSuccess = (payload) => ({
  type: types.EXTRACT_SKILLS_SUCCESS,
  payload,
});
export const extractSkillsFailure = (payload) => ({
  type: types.EXTRACT_SKILLS_FAILURE,
  payload,
});

// Accept overlay Actions
export const acceptOverLayRequest = (payload) => ({
  type: types.ACCEPT_OVERlAY_REQUEST,
  payload,
});
export const acceptOverLaySuccess = (payload) => ({
  type: types.ACCEPT_OVERlAY_SUCCESS,
  payload,
});
export const acceptOverLayFailure = (payload) => ({
  type: types.ACCEPT_OVERlAY_FAILURE,
  payload,
});

// Accept overlay Actions
export const saveCompanyDatailsRequest = (payload) => ({
  type: types.SAVE_COMPANY_DATILS_REQUEST,
  payload,
});
export const saveCompanyDatailsSuccess = (payload) => ({
  type: types.SAVE_COMPANY_DATILS_SUCCESS,
  payload,
});
export const saveCompanyDatailsFailure = (payload) => ({
  type: types.SAVE_COMPANY_DATILS_FAILURE,
  payload,
});

// get company filteres Actions
export const getCompanyFilteresRequest = (payload) => ({
  type: types.GET_COMPANY_FILTERS_DATILS_REQUEST,
  payload,
});
export const getCompanyFilteresSuccess = (payload) => ({
  type: types.GET_COMPANY_FILTERS_DATILS_SUCCESS,
  payload,
});
export const getCompanyFilteresFailure = (payload) => ({
  type: types.GET_COMPANY_FILTERS_DATILS_FAILURE,
  payload,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});
