import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux";

import LocationsList from "./LocationsList"
import {Layout} from 'antd';
const {Header, Footer, Sider, Content} = Layout;

import L from "leaflet";
import { MapContainer, useMap, useMapEvents, FeatureGroup, GeoJSON, TileLayer, Marker, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
// Due to bug in react-leaflet, leaflet marker images must be loaded manually
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});

//Set map bounds based on visible locations;
//Upon filtering company list, map will update to zoom in on remaining locations.
const UpdateMapPosition = ({ geoJsonKey, groupRef, displayedMarkers }) => {
  // Access the map context with the useMap hook
  const map = useMap()

  // Reset the bounds of the map based on the displayed markers
  const updateMapPosition = () => {
    if (displayedMarkers['features'].length > 0 && map && groupRef.current) {
      const layer = groupRef.current
      if (layer) {
        map.fitBounds(layer.getBounds().pad(0.5))
      }
    }
  }

  // useEffect Hook to reset viewport when geoJson changes
  useEffect(() => {
    updateMapPosition()
  }, [geoJsonKey]) //eslint-disable-line

  return null
}

//Return locations that are currently in viewport so that sidebar location list can update
const GetVisibleMarkers = ({
  groupRef,
  clusterRef,
  setVisibleMarkers,
}) => {
  // Access the map context with the useMap hook
  const map = useMap()

  // Get visible markers on the map
  const getVisibleMarkers = () => {
    if (map && groupRef.current && clusterRef.current) {
      const cluster = clusterRef.current
      let collection = {"type": "FeatureCollection"}
      let features = []
      cluster.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          if (map.getBounds().contains(layer.getLatLng())) {
            features.push(layer.feature)
          }
        }
      })
      collection["features"] = features
      setVisibleMarkers(collection)
    }
  }

  // Hook to access map events from Leaflet API
  useMapEvents({
    zoomend: () => getVisibleMarkers(),
    moveend: () => getVisibleMarkers(),
  })

  return null
}

//Create location layers from GEOJson
const GeoJSONLayer = ({ locations, geoJsonKey, handleCompanyClickedCallback }) =>{    
  const createTooltip = (feature = {}, layer) => {
    const { properties = {} } = feature
    const tooltip = L.tooltip()
    const html = `
    <div>
    <h3>${properties.company.company}</h3>
    <h4>${properties.Municipality}, ${properties.Region}</h4>
    </div>
    `
    tooltip.setContent(html)
    layer.bindTooltip(tooltip)
  }

  function getIcon(url) {
    const icon = url
      ? new L.Icon({
          iconUrl: url,
          iconSize: [26, 26],
          popupAnchor: [0, -15]
        })
      : L.Icon({
        iconRetinaUrl: iconRetina,
        iconUrl: iconMarker,
        shadowUrl: iconShadow,
      })
    return icon
  }

  const pointToLayer = (feature = {}, latlng) => {
    const { properties = {} } = feature
    return L.marker(latlng)
    // return L.marker(latlng, {
    //   icon: getIcon(properties.company.logo_url)
    // });
  }
  
  function onEachFeature(feature, layer){
    if(feature.properties){
      createTooltip(feature, layer)
    }
    if (handleCompanyClickedCallback) {
      layer.on({click: (e) => {
        handleCompanyClickedCallback(feature.properties.company.unnlock_id);
      }})
    }
  }

  return(
    <GeoJSON data={locations} pointToLayer={pointToLayer} onEachFeature={onEachFeature} key={geoJsonKey}  />
  )

}

//Make a random key of given length;
const makeKey = (length) => {
  let result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const LocationsMap = ({locations, handleCompanyClickedCallback, showList, useLocationFilter}) => {

  // Initiate refs to the feature group and cluster group
  const groupRef = useRef()
  const clusterRef = useRef()
  
  //get current selected filter city
  const selectedLocation = useSelector(
    (state) => state.componies.selectedLocation
  );
  //Track map instance for control by elements external to map container (ie. company list in sidebar)
  const [map, setMap] = useState(null)
  // GeoJson Key to handle updating geojson inside react-leaflet
  const [geoJsonKey, setGeoJsonKey] = useState("initialKey123abc")
  //Track which markers are being actively displayed on the map
  const [displayedLocations, setDisplayedLocations] = useState(locations)
  //Track which markers are visible on the map
  const [visibleMarkers, setVisibleMarkers] = useState(locations)
  
  // FUNCTIONS
  // Generate a new key to force an update to GeoJson Layer
  useEffect(() => {
    setGeoJsonKey(makeKey(10))
  }, [selectedLocation, locations]);
  
  useEffect(() => {
    if(useLocationFilter && selectedLocation != '' && locations.hasOwnProperty('features') && locations['features'].length > 0){
      locations['features'] = locations['features'].filter((location) =>
        location.hasOwnProperty('properties') && location['properties'].hasOwnProperty('Municipality') && location['properties']['Municipality'] == selectedLocation
      );
    }
    setDisplayedLocations(locations);
    setVisibleMarkers(locations);
  }, [selectedLocation, locations]);
  
  const southWest = [18.91619, -171.791110603] //coordinates for southwest boundary of US; [24.7433195, -124.7844079] for continental only
  const northEast = [71.3577635769, -40.96466] //coordinates for northeast boundary of US + margin for centering; [49.3457868, -66.9513812] for continental only
  const maxBounds = new L.LatLngBounds(southWest, northEast)

  return (
    <Layout hasSider={true} style={{marginTop: "10px"}}>
      <Content>
        <MapContainer
          ref={setMap}
          center={[39.8283, -98.5795]} //Geographic center of continental US
          maxBounds={maxBounds}
          zoom={4}
          zoomDelta={1}
          zoomSnap={1}
          minZoom={4}
          zoomControl={true}
          style={{
            borderRadius: showList ? "8px 0px 0px 8px" : "8px 8px 8px 8px",
            height: "600px",
            border: "1px solid #ccc",
            zIndex: "0" //necessary for map rounded borders on webkit browsers
          }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
            maxZoom={18}
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
            maxZoom={18}
          />
          <FeatureGroup 
            ref={groupRef}
            name="Locations"
          >
            <MarkerClusterGroup
              ref={clusterRef}
              showCoverageOnHover={false}
              maxClusterRadius={25}
              disableClusteringAtZoom={11}
            >
              <GeoJSONLayer 
                locations={displayedLocations}
                geoJsonKey={geoJsonKey}
                handleCompanyClickedCallback={handleCompanyClickedCallback}
              />
            </MarkerClusterGroup>
          </FeatureGroup>
          <UpdateMapPosition
            geoJsonKey={geoJsonKey}
            groupRef={groupRef}
            displayedMarkers={displayedLocations}
          />
          <GetVisibleMarkers
            geoJsonKey={geoJsonKey}
            groupRef={groupRef}
            clusterRef={clusterRef}
            setVisibleMarkers={setVisibleMarkers}
          />
        </MapContainer> 
      </Content>
      <Sider 
        theme="light"
        width={showList ? 250 : 0}
        collapsed={false}
        style={{ borderRadius: "0px 8px 8px 0px" }}
      >
        {map && showList
          ? <LocationsList map={map} locations={visibleMarkers} handleCompanyClickedCallback={handleCompanyClickedCallback} /> 
          : null
        }
      </Sider>
    </Layout>
  );
}

export default LocationsMap;
