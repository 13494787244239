import React from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import MiinedHeader from "../../components/Header";
import "./Tools.scss";
/* eslint-disable */

const Tools = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const getNavItems = () => {
    switch (location.pathname) {
      case "/tools":
        return "Match to job posting";
      case "/matchjobResults":
        return "Match to job Results";
      case "/evaluateJob":
        return "Evaluate a job offer";
      default:
        return "";
    }
  };

  return (
    <div className="tools-container">
      <MiinedHeader />
      <div className="tools_navBar">
        <div className="navItem">
          <span
            className="back"
            onClick={() => {
              if (location.pathname !== "/tools") history.goBack();
            }}
          >
            <LeftOutlined className="back_icon" />
            Tools
          </span>
          <span className="divider">|</span>
          {getNavItems()}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

Tools.propTypes = {
  children: PropTypes.any,
};

Tools.defaultProps = {
  children: " ",
};

export default Tools;
