import React from "react";
import { Field } from "formik";
import "./commonInput.scss";

const CommonInput = ({ name, placeholder, style, type, validate }) => {
  return (
    <div className="Common_input_container" style={style}>
      <div className="inputWrapper">
        <p>{placeholder}</p>
        <Field name={name} type={type} validate={validate} />
      </div>
    </div>
  );
};

export default CommonInput;
