/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import history from "../../routes/History";
import MiinedButton from "../../components/Button";
import "../Profile/model.scss";
import explainerGif from "../../assets/images/explainerGif.gif";

const SubsriptionAlertModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className={"custom_addSkillsModal"}
    >
      <div className="model_body">
        <div className="content_holder" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "10px 10px 0px",
            }}
          >
            <div>
              Upgrade your plan to see instant fit analysis and resume optimizations needed for this job.
            </div>
            <MiinedButton
              title={"See Plans"}
              onClick={(e) => {
                e.stopPropagation();
                history.push("/subscriptions");
              }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "16px" }}>
            <img src={explainerGif} alt="gif" style={{ width: "-webkit-fill-available" }} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubsriptionAlertModal;
