import React, { useState } from "react";
/* eslint-disable */
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { message, Button } from "antd";
import InputWithLabel from "../inputwithLabel";
import SelectWithLabel from "../selectwithlabel";
import CardSection from "./cardSection";
import { useDispatch, useSelector } from "react-redux";
import * as ProfileActions from "../../../store/profile/profileActions";
import History from "../../../routes/History";
import {
  getUserDataRequest,
  getUserDataSuccess,
  setSubscriptionLoader,
} from "../../../store/newLogin/newLoginAction";
import { useEffect } from "react";

const CheckoutForm = (props) => {
  const clientSecrete = useSelector((state) => state.profile.clientSecrete);
  const [name, setName] = useState("");
  const [getUserLoading, setgetUserLoading] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const userEmail = useSelector((state) => state.profile.basicInfo.email);

  const paymentMethods = useSelector((state) => state.profile.paymentMethods);
  const selectedPayment = useSelector((state) => state.profile.selectedPlan);
  const customerId = useSelector((state) => state.profile.customerId);
  const currentPayMethod = useSelector(
    (state) => state.profile.stripePaymentId
  );
  const userDataLoaded = useSelector((state) => state.login.loaded);
  const isLoading = useSelector((state) => state.profile.loading);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSelectChange = (e) => {
    if (e === "Saved Cards") {
      setShow(true);
      dispatch(ProfileActions.getPaymentMethodRequest({ custId: customerId }));
    } else {
      setShow(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          name: name,
          email: userEmail,
        },
      })
      .then(function (result) {
        if (result.error) {
          message.error(result.error.message);
        } else {
          dispatch(
            ProfileActions.attachPayMethodRequest({
              custId: customerId,
              payMethod: result.paymentMethod.id,
            })
          );
          stripe
            .confirmCardPayment(clientSecrete, {
              payment_method: result.paymentMethod.id,
            })
            .then((result) => {
              if (result.error) {
                message.error(result.error.message);
              } else {
                // Successful subscription payment
                dispatch(ProfileActions.setPaymentModal(false));
                dispatch(ProfileActions.getprofileRequest());
                dispatch(getUserDataRequest());
                setgetUserLoading(true);
                dispatch(setSubscriptionLoader(true));
                // console.log(result, "completed payment");
                message.success("Payment completed.");
                message.success("Your subscription is active now!");
                setTimeout(() => {
                  message.success(
                    "Please wait while we update user profile..."
                  );
                }, 2000);
                // History.goBack();
              }
            });
        }
      });
  };

  const handleCardSelect = (paymentMethodid) => {
    const { stripe } = props;
    stripe
      .confirmCardPayment(clientSecrete, {
        payment_method: paymentMethodid,
      })
      .then((result) => {
        if (result.error) {
          message.error(result.error.message);
        } else {
          // Successful subscription payment
          dispatch(ProfileActions.setPaymentModal(false));
          dispatch(ProfileActions.getprofileRequest());

          // console.log(result, "completed payment");

          message.success("Payment completed!");
          message.success("Your subscription is active now!");
        }
      });
  };

  const handleremove = (paymentMethod) => {
    dispatch(
      ProfileActions.detachPayMethodRequest({
        payMethod: paymentMethod,
      })
    );
  };

  useEffect(() => {
    if (getUserLoading && userDataLoaded) {
      setTimeout(() => {
        setgetUserLoading(false);
        dispatch(getUserDataRequest({ fromSubscriptionChange: true }));
        dispatch(setSubscriptionLoader(false));
        History.goBack();
      }, 5000);
    }
  }, [userDataLoaded]);

  return (
    <div className="model_body">
      {/* <button onClick={() => dispatch(setSubscriptionLoader(true))}>
        kjdf
      </button> */}
      <div className="inputs_container">
        <div className="input_container">
          <InputWithLabel
            placeholder=""
            value={"$ " + selectedPayment}
            disabled
          />
        </div>
        <div className="input_container">
          <SelectWithLabel
            // label="Payment type"
            value="Debit / Credit Cards"
            handleChange={handleSelectChange}
          />
        </div>
        {show ? (
          <div>
            {paymentMethods.map((item) => (
              <div className="blue_container" key={item}>
                <p className="trial">
                  {item.card.brand} - XXXX{item.card.last4} -{" "}
                  {currentPayMethod === item.id ? "Currently in use" : null}
                </p>
                <div className="custom_btn">
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    onClick={() => handleCardSelect(item.id)}
                  >
                    Use
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    onClick={() => handleremove(item.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="input_container">
              <InputWithLabel
                // label="Name on card"
                placeholder="Name on card"
                value={name}
                handleChange={handleChange}
              />
            </div>
            <CardSection />
          </>
        )}
      </div>

      <div className="model_footer">
        <Button
          type="primary"
          className="pay_btn"
          onClick={handleSubmit}
          disabled={!props.stripe || isLoading}
        >
          {isLoading ? "Loading..." : "Pay"}
        </Button>
        <div className="stripe">powered by Stripe</div>
      </div>
    </div>
  );
};

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
