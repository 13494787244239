/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Progress, Radio, Space, Skeleton } from "antd";

import "./index.scss";
// import { PersonalityTestData } from "../../utils/Data";
import CountDown from "./countDown";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionsRequest,
  submitResultsRequest,
} from "../../store/persanalityTest/persanalityTestActions";
import PersonalityTestRsults from "./testResults";
import percardicon from "../../assets/images/percardicon.svg";
import UnnlockLayout from "../layout";

const PersonalityTest = () => {
  const questionsFromReducer = useSelector(
    (state) => state.persanalityTest.questions
  );
  const loader = useSelector((state) => state.persanalityTest.loader);
  const loginLoader = useSelector((state) => state.login.loader);
  const { userData } = useSelector((state) => state.login);
  const [questions, setQuestions] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const [timeElapsed, settimeElapsed] = useState("");
  const [endIndex, setEndIndex] = useState(2);
  const [showResults, setShowResults] = useState(false);
  const [startTest, setstartTest] = useState(false);

  const dispatch = useDispatch();

  const onChange = (e, q) => {
    let qarr = questions.map((item) => {
      if (item.id === q.id) {
        return { ...item, score: e.target.value };
      }
      return item;
    });
    setQuestions(qarr);
  };

  const getQuestion = (question, i) => {
    return (
      <div className="question_holder">
        <div className="question">
          {i + 1}. {question.text}
        </div>
        <div className="choices">
          <Radio.Group
            onChange={(e) => onChange(e, question)}
            value={question.score && question.score}
          >
            <Space direction="vertical">
              {question.choices.map((item) => (
                <Radio value={item.score} className="choice">
                  {item.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </div>
    );
  };

  const getAnsweredPercent = () => {
    return parseInt(
      (questions.filter((item) => item.score).length / questions.length) * 100
    );
  };

  const handleSave = () => {
    setSaving(true);
    if (
      document.getElementById("getCountDownVal") &&
      document.getElementById("getCountDownVal").getAttribute("data-value")
    ) {
      settimeElapsed(
        document.getElementById("getCountDownVal").getAttribute("data-value")
      );
    }
    // dispatch(LoginActions.checkTokanExpiry());
    dispatch(
      submitResultsRequest({
        lang: "en",
        invalid: false,
        answers: questions.map((item) => {
          return {
            questionID: item.id,
            score: item.score,
            domain: item.domain,
            facet: item.facet,
          };
        }),
        timeElapsed: saving
          ? timeElapsed
          : document
              .getElementById("getCountDownVal")
              .getAttribute("data-value"),
        dateStamp: 1651502491517,
      })
    );
    setTimeout(() => {
      setShowResults(true);
    }, 2000);
  };

  useEffect(() => {
    if (saving) handleSave();
  }, [loginLoader]);

  useEffect(() => {
    if (questionsFromReducer) {
      setQuestions(questionsFromReducer);
    }
  }, [questionsFromReducer]);

  useEffect(() => {
    if (userData && userData.quiz_id) {
      setShowResults(true);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getQuestionsRequest());
  }, []);

  return (
    <UnnlockLayout>
      <div className="personality_test_body">
        {!startTest ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="perCardDiv">
              <img className="cardmainimage" src={percardicon} alt="" style={{ marginBottom: "80px" }} />
              <p className="title">Unlock Personality Test</p>
              <p className="subtitle">
                Learn to know yourself better with a free, open-source
                personality test.
              </p>
              <p className="subtitle">
                The following test contains 120 questions which are estimated to
                take you about 10 minutes to complete.
              </p>
              <button
                style={{
                  background: "#4389FA",
                  border: "none",
                  borderRadius: "6px",
                  color: "white",
                  padding: "10px",
                  cursor: "pointer",
                  marginTop: "30px",
                }}
                onClick={() => {
                  setstartTest(true);
                }}
              >
                Start Test
              </button>
            </div>
          </div>
        ) : (
          <>
            {showResults ? (
              <PersonalityTestRsults />
            ) : (
              <>
                <div className="title">
                  Personality Test -{" "}
                  {questions.length && !loader && <CountDown />}
                </div>
                <div className="progressBar_holder">
                  <Progress
                    strokeColor={{
                      "0%": "#108ee9",
                      "100%": "#87d068",
                    }}
                    percent={questions.length && getAnsweredPercent()}
                  />
                </div>
                <Skeleton loading={loader} active>
                  {questions.length && (
                    <div className="Questions_holder">
                      {questions.map((item, i) => (
                        <div>
                          {i >= startIndex && i <= endIndex ? (
                            <>{getQuestion(item, i)}</>
                          ) : null}
                        </div>
                      ))}
                      <div className="button">
                        <button
                          onClick={() => {
                            setStartIndex(startIndex - 3);
                            setEndIndex(endIndex - 3);
                          }}
                          disabled={!startIndex}
                        >
                          Back
                        </button>
                        <button
                          onClick={() => {
                            setStartIndex(startIndex + 3);
                            setEndIndex(endIndex + 3);
                          }}
                          disabled={endIndex >= questions.length - 1}
                        >
                          Next
                        </button>
                        {questions.length ===
                        questions.filter((item) => item.score).length ? (
                          <button
                            id="pt_check_btn"
                            onClick={() => handleSave()}
                          >
                            See Results
                          </button>
                        ) : null}
                      </div>
                    </div>
                  )}
                </Skeleton>
              </>
            )}
          </>
        )}
      </div>
    </UnnlockLayout>
  );
};

export default PersonalityTest;
