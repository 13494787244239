/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row, Col } from "antd";
import { key, schema, validation, values as schemaValues } from "./schema";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import ResumeOptimizerInput from "../resumeOptimizerInput";

import Plus from "../../../assets/images/resumeOptimizer/Plus.svg";
import TrashSimple from "../../../assets/images/resumeOptimizer/TrashSimple (red).svg";

import "./index.scss";
import DescriptionEditor from "../experienceInfo/descriptionEditor";

const MiliteryHistory = () => {
  const [editIndex, SetEditIndex] = useState(0);
  const [display, setDisplay] = useState(false);
  const [editorValues, setEditorValues] = useState([]);
  const militeryHistoryInfo = useSelector(
    (state) => state.resumeOptimizer.otheresSections
  );
  const dispatch = useDispatch();

  const removeSuggestionIds = (data) => {
    let str1 = data.replace(/<li id="li_(\d+(d)*)">/gm, "<li>");
    let str2 = str1.replace(/<p id="p_(\d+(d)*)">/gm, "<p>");
    let str3 = str2.split(">&nbsp;</span></div></div>").map((item) => {
      if (item.includes("<div contenteditable")) {
        return item.split("<div contenteditable")[0];
      }
      return item;
    });
    return str3.join("");
  };

  const handleEditorChange = (value, index, shouldModify) => {
    const arr = [...editorValues];
    arr[index] = shouldModify ? removeSuggestionIds(value) : value;
    setEditorValues(arr);
  };

  const handleSubmit = (values, arr) => {
    let formData = {
      others: {
        ...militeryHistoryInfo,
        militeryHistory: values.militeryHistory.map((item, i) => {
          return item;
        }),
      },
    };
    formData = {
      others: {
        ...militeryHistoryInfo,
        militeryHistory: values.militeryHistory.map((item, i) => {
          return {
            ...item,
            description: arr ? arr[i] : removeSuggestionIds(editorValues[i]),
          };
        }),
      },
    };
    dispatch(
      ResumeActions.resumeUpdateRequest({
        formData,
        label: "other-update",
      })
    );
  };

  const handlePush = () => {
    setEditorValues(["", ...editorValues]);
  };

  const handleRemove = (index) => {
    const editorArr = [...editorValues];
    editorArr.splice(index, 1);
    setEditorValues(editorArr);
    document.getElementById("submitmiliteryHistoryBtn").click();
  };

  useEffect(() => {
    if (militeryHistoryInfo) {
      const arr =
        militeryHistoryInfo.militeryHistory.map((item) => item.description) ||
        [];
      setEditorValues(arr);
      setDisplay(true);
    }
  }, [militeryHistoryInfo]);

  return (
    <div className="update_experience_container">
      <Row style={{ borderBottom: "1px solid #e4e4e4", marginBottom: "20px" }}>
        <Col span={18}>
          <PerfectScrollbar className="tabs">
            <div className="tab">
              {editorValues.length ? null : (
                <img
                  src={Plus}
                  alt="add"
                  onClick={() => {
                    setTimeout(() => {
                      SetEditIndex(0);
                    }, 1000);
                    document
                      .getElementById("add_militeryHistory_infoId")
                      .click();
                  }}
                />
              )}
            </div>
            {editorValues.map((item, i) => (
              <div
                className={editIndex === i ? "activeTab" : "tab"}
                onClick={() => SetEditIndex(i)}
              >
                Militery History
              </div>
            ))}
          </PerfectScrollbar>
        </Col>
        <Col span={6}>
          <div className="actions">
            {(editIndex || editIndex === 0) && editorValues.length ? (
              <Row style={{ alignItems: "center" }}>
                <Col>
                  <button
                    type="button"
                    onClick={() =>
                      document
                        .getElementById("submitmiliteryHistoryBtn")
                        .click()
                    }
                    className="save-button"
                  >
                    Save
                  </button>
                </Col>
                <Col>
                  <img
                    style={{ marginLeft: "20px" }}
                    src={TrashSimple}
                    alt="remove"
                    onClick={() => {
                      setTimeout(() => {
                        if (editIndex) {
                          SetEditIndex(editIndex - 1);
                        }
                      }, 1000);
                      document
                        .getElementById(
                          `remove_militeryHistory_infoId_${editIndex}`
                        )
                        .click();
                    }}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>

      {display && (
        <>
          <Formik
            initialValues={militeryHistoryInfo}
            enableReinitialize
            // validationSchema={validation}
            onSubmit={(values) => {
              setTimeout(() => {
                handleSubmit(values);
              }, 400);
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <FieldArray name={key}>
                  {({ remove, unshift }) => (
                    <div>
                      <div
                        id="add_militeryHistory_infoId"
                        style={{ display: "none" }}
                        onClick={() => {
                          unshift({
                            title: "",
                            description: "",
                          });
                          handlePush();
                        }}
                      >
                        add
                      </div>
                      {values.militeryHistory.length > 0 &&
                        values.militeryHistory.map((item, i) => (
                          <>
                            {i === editIndex ? (
                              <>
                                <button
                                  id={`remove_militeryHistory_infoId_${i}`}
                                  style={{ display: "none" }}
                                  type="button"
                                  onClick={() => {
                                    remove(i);
                                    handleRemove(i);
                                  }}
                                >
                                  Remove
                                </button>
                                {/* <Row gutter={[24, 24]}>
                                  <Col span={14}>
                                    <ResumeOptimizerInput
                                      label="Title"
                                      name={`militeryHistory[${i}].title`}
                                      type="text"
                                      error={errors.title}
                                      touched={touched.title}
                                    />
                                  </Col>
                                </Row> */}
                                <DescriptionEditor
                                  handleEditorChange={(
                                    value,
                                    index,
                                    shouldModify
                                  ) =>
                                    handleEditorChange(
                                      value,
                                      index,
                                      shouldModify
                                    )
                                  }
                                  value={editorValues[i]}
                                  id={i}
                                  editInfo={true}
                                  level={i}
                                  editIndex={editIndex}
                                  label="Description"
                                />
                              </>
                            ) : null}
                          </>
                        ))}
                    </div>
                  )}
                </FieldArray>
                <button
                  type="submit"
                  id="submitmiliteryHistoryBtn"
                  style={{ visibility: "hidden" }}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default MiliteryHistory;
