import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { Select } from "antd";
import {
  extractSkillsRequest,
  getCompanyFilteresRequest,
  resumeParserRequest,
} from "../../store/dashBoard/dashboardActions";
import {
  searchCompBySkillsRequest,
  setExploreSkills,
} from "../../store/componies/action";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";
import "./index.scss";
import unnlockNotification from "../../components/notification";
import { getResumeVaultsRequest } from "../../store/resumeOptimizer/resumeOptimizerActions";

const { Option } = Select;

const BySkills = ({ }) => {
  const [Suggestedskills, setSuggestedskills] = useState([]);
  const [viewAll, setviewAll] = useState(true);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.dashboard.loader);
  const selectedSkills = useSelector(
    (state) => state.componies.exploreSelectedSkills
  );
  const componiesLoader = useSelector((state) => state.componies.Loader);
  const loginState = useSelector((state) => state.login);
  const dashboardState = useSelector((state) => state.dashboard);
  const [currentResume, setcurrentResume] = useState("0");

  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);


  useEffect(() => {
    dispatch(getResumeVaultsRequest());
    setcurrentResume(loginState.userData?.primary_resume);
    //setcurrentResume("0");
  }, [loginState.loader]);

  const handleSubmit = () => {
    if (selectedSkills.length < 3) {
      unnlockNotification("More than 3 skills should be selected", "warning");
    } else {
      dispatch(
        searchCompBySkillsRequest({
          // company_size: details[0].value,
          // city: details[2].value,
          // state: details[1].value,
          skills: selectedSkills,
          industry:
            loginState.userData?.user_industries?.map(
              (item) => item.industry_name
            ) || [],
          // industry: details[3].value,
        })
      );
    }
  };

  useEffect(() => {
    if (dashboardState.skillsForAtsView) {
      console.log("Skill suggestion")
      setSuggestedskills([
        ...dashboardState.skillsForAtsView
          .filter((item) => item.id == "ST1")
          .map((item) => item.name),
      ]);
    }
  }, [dashboardState.skillsForAtsView]);

  // useEffect(() => {
  //   dispatch(getCompanyFilteresRequest());
  // }, []);

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.primary_resume &&
      !dashboardState.employementInfo?.employment[0]?.designation
    ) {
      dispatch(resumeParserRequest(loginState.userData.primary_resume));
    }
  }, [loginState]);

  return (
    <div>
      {/* {selectedSkills.length ? ( */}

      <div className="selecteddSkills">
        <div style={{ display: "flex" }}>
          <div className="title" style={{ marginRight: "16px" }}>1. Select Resume</div>
          <Select
            value={currentResume}
            defaultValue="Select Resume"
            onChange={(e) => {
              setcurrentResume(e);
              dispatch(resumeParserRequest(e));

            }}
            className="customeselectResume"
          >
            <Option key="0">Select Resume</Option>
            {resumeOptimizerState.resumeVaults.map((i) => (
              <Option key={i.resume_id}>{i.file_name}</Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="title">Selected Skills</div>
          {selectedSkills.length < 3 ? null : (
            <div className="ByCompanyName_submit" style={{ position: "absolute", top: "86px" }}>
              <button
                type="button"
                onClick={() => (!componiesLoader ? handleSubmit() : {})}
              >
                {componiesLoader ? (
                  "Loading..."
                ) : (
                  <>
                    Next <img src={WhiteArrowLeft} />
                  </>
                )}
              </button>
            </div>
          )}
        </div>
        <div className="skills">
          {selectedSkills.length ? (
            selectedSkills.map((item) => (
              <div
                className="skill"
                onClick={() =>
                  dispatch(
                    setExploreSkills(selectedSkills.filter((i) => i !== item))
                  )
                }
              >
                <p>{item}</p>
                <span>+</span>
              </div>
            ))
          ) : (
            <div style={{ margin: "auto" }}>No skills selected</div>
          )}
        </div>
      </div>
      {/* ) : null} */}
      <div className="suggestedSkills">
        <div className="title">2. Select minimum five skills from below</div>
        {loader || loginState.loader || !Suggestedskills.length ? (
          "Getting suggested skills..!"
        ) : (
          <div
            className="skills"
          // style={{ maxHeight: viewAll ? "300px" : "200px" }}
          >
            {Suggestedskills.length
              ? Suggestedskills.filter(
                (item) => !selectedSkills.includes(item)
              ).map((item, i) => (
                <>
                  {i < 8 || viewAll ? (
                    <div
                      className="skill"
                      onClick={() => {
                        dispatch(
                          setExploreSkills([
                            ...selectedSkills.filter((prev) => prev !== item),
                            item,
                          ])
                        );
                        // setSuggestedskills(
                        //   Suggestedskills.filter((sel) => sel !== item)
                        // );
                      }}
                    >
                      <p>{item}</p>
                      <span>+</span>
                    </div>
                  ) : null}
                </>
              ))
              : null}
            {viewAll || !Suggestedskills.length ? null : (
              <button
                type="button"
                className="View-button"
                onClick={() => setviewAll(true)}
              >
                View All
              </button>
            )}
          </div>
        )}
      </div>
      {selectedSkills.length < 3 ? null : (
        <div
          className="ByCompanyName_submit"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            paddingTop: "15px",
          }}
        >
          <button
            type="button"
            onClick={() => (!componiesLoader ? handleSubmit() : {})}
          >
            {componiesLoader ? (
              "Loading..!"
            ) : (
              <>
                Next <img src={WhiteArrowLeft} />
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default BySkills;
