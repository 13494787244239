/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import "../Profile/model.scss";
// import InputWithLabel from "../Profile/inputwithLabel";
import CommonInput from "../ICDProvider/commonInput";

const CloneModal = ({
  visible,
  value,
  handleChange,
  handleSubmit,
  isAddModal,
  note,
  handleNoteChange,
  isEdit,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);

  console.log(note,"note");

  const handleOk = () => {
    handleSubmit();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="custom_confirm_model"
    >
      <div className="model_body">
        <div className="content_holder" style={{ width: "100%" }}>
          <div className="title">
            {isAddModal
              ? "Add your resume"
              : isEdit
              ? "Edit Resume Name"
              : "Duplicate your resume"}
          </div>
          <div className="input_holder">
            <CommonInput
              placeholder="Enter Resume Name"
              value={value}
              name="name"
              style={{
                marginTop: "20px",
              }}
              handleChange={handleChange}
              type="text"
            />
            <CommonInput
              placeholder="Type here..."
              value={note}
              name="note"
              style={{
                marginTop: "20px",
              }}
              handleChange={handleNoteChange}
              type="textarea"
            />
          </div>
          <div className="buttons_container">
            <Button type="primary" className="custom_btn" onClick={handleOk}>
              {isAddModal ? "Add" : isEdit ? "Save" : "Duplicate"}
            </Button>
            <Button type="link" className="custom_btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CloneModal;
