/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Divider, List, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Heartfilled from "../../assets/images/componies/heartFilled.svg";
import Heart from "../../assets/images/componies/heart.svg";
import DefaultImage from "../../assets/images/componies/defaultImage.svg";
import Globe from "../../assets/images/componies/Globe.svg";
import LinkedinLogo from "../../assets/images/componies/LinkedinLogo.svg";
import { useSelector } from "react-redux";
import unnlockNotification from "../../components/notification";
import "./Companies.scss";

const CompanyCardLite = ({
  item,
  getFavClick,
  handleCompanyClicked,
  isFaverate,
  selectedCompany,
  fromDashboard,
}) => {
  const [isFav, setFav] = useState(isFaverate);
  const faverateLoader = useSelector((state) => state.componies.faverateLoader);
  const [imageUrl, setImageUrl] = useState('');
  const checkvalidArray = (data) => {
    if (Array.isArray(data) && data.length) {
      return true;
    } else return false;
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    // Set the CDN URL of the image
    setImageUrl(item.logo_url ? item.logo_url : "/static/media/defaultImage.dc9d8294cd9de7750519e6b8bc209104.svg");
  }, []);

  return (
    <List.Item
      className={fromDashboard ? "companyItemfromDashboard" : "companyItem"}
      key={item.unnlock_id}
    >
      <div
        className="companyItemboxShadow"
        onClick={() => handleCompanyClicked(item.unnlock_id)}
      >
        <div className="descriptionHolder">
          <img
            src={imageUrl}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = DefaultImage;
            }}
            alt="cl"
            className="companyLogo"
          />

          <div style={{ width: "100%" }}>

            <div className="companyName">{item.company}</div>
            <div className="cityName">
              {checkvalidArray(item.city_name) ? item.city_name[0].name : null}
            </div>
          </div>

          {fromDashboard ? null : (
            <>
              {faverateLoader && isFav === item.unnlock_id ? (
                <Spin
                  indicator={antIcon}
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <img
                  className="like-img"
                  src={isFaverate ? Heartfilled : Heart}
                  alt="like"
                  onClick={(e) => {
                    setFav(item.unnlock_id);
                    if (faverateLoader) {
                      e.stopPropagation();
                      unnlockNotification("Please wait.", "warning");
                    } else {
                      e.stopPropagation();
                      getFavClick(
                        isFaverate ? "remove" : "add",
                        item.unnlock_id
                      );
                    }
                  }}
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                />
              )}
            </>
          )}
        </div>
        <Divider style={{ color: '#E7E7E7', marginBottom: '1rem', marginTop: 0 }} />
        <div className="companyAttributes">
          <div>
            <div style={{ fontWeight: 600 }}>Employees</div>
            <div>{item.company_size}</div>
          </div>
          <div className="industry">
            <div style={{ fontWeight: 600 }}>Industry</div>
            <div
              className="industryname"

            >
              {item.industry}
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export default CompanyCardLite;
