/* eslint-disable react/destructuring-assignment */
/* eslint-disable */
import React from "react";
import { Button } from "antd";

import "./style.scss";

const MiinedButton = (props) => {
  const { type, ...restProps } = props;

  // Primary styles
  let customStyles = {
    height: "48px",
    border: "none",
    background: "#4389fa",
    borderRadius: "8px",
    color: "#ffffff",
    width: "fit-content",
  };
  if (type === "disabled") {
    customStyles = {
      height: "48px",
      background: "#4389fa",
      opacity: "0.5",
      borderRadius: "8px",
      color: "#ffffff",
    };
  }

  if (type === "secondary") {
    customStyles = {
      height: "48px",
      borderRadius: "8px",
      color: "#4389FA",
      background: "#FFFFFF",
      border: "1px solid #4389FA",
      boxSizing: "border-box",
    };
  }

  if (type === "image") {
    customStyles = {
      display: "flex",
      width: "-webkit-fill-available",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      background: "#FFFFFF",
      border: "1px solid #DEE5FF",
      boxSizing: "border-box",
      borderRadius: "8px",
    };
  }

  if (type === "roundborder") {
    customStyles = {
      height: "48px",
      borderRadius: "48px",
      color: "#4389FA",
      background: "#F4F8FF",
      border: "1px solid #5594FB",
      boxSizing: "border-box",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "12px",
    };
  }

  if (type === "customBg") {
    customStyles = {
      height: "48px",
      background: restProps.bgColor,
      borderRadius: "8px",
      color: restProps.txtColor,
      border: "none",
    };
  }
  if (type === "secondayTransparent") {
    customStyles = {
      height: "48px",
      borderRadius: "8px",
      color: "#FFFFFF",
      background: "#4389fa",
      border: "1px solid #FFFFFF",
      boxSizing: "border-box",
    };
  }

  return (
    <Button style={customStyles} type block {...restProps}>
      {props.title}
    </Button>
  );
};

// function MiinedButton(props) {
//   let btnStyle;

//   if (props.type === "primary") {
//     btnStyle = {
//       height: "48px",
//       background: "#4389fa",
//       borderRadius: "8px",
//       color: "#ffffff",
//     };
//     return (
//       <Button style={btnStyle} type="primary" block>
//         {props.title}
//       </Button>
//     );
//   }

//   if (props.type === "disabled") {
//     btnStyle = {
//       height: "48px",
//       background: "#4389fa",
//       opacity: "0.5",
//       borderRadius: "8px",
//       color: "#ffffff",
//     };
//     return (
//       <Button style={btnStyle} type="primary" block disabled>
//         {props.title}
//       </Button>
//     );
//   }
//   if (props.type === "secondary") {
//     btnStyle = {
//       height: "48px",
//       borderRadius: "8px",
//       color: "#4389FA",
//       background: "#FFFFFF",
//       border: "1px solid #4389FA",
//       boxSizing: "border-box",
//     };
//     return (
//       <Button style={btnStyle} type="primary" block>
//         {props.title}
//       </Button>
//     );
//   }
//   if (props.type === "image") {
//     btnStyle = {
//       display: "flex",
//       width: "-webkit-fill-available",
//       justifyContent: "center",
//       alignItems: "center",
//       height: "48px",
//       background: "#FFFFFF",
//       border: "1px solid #DEE5FF",
//       boxSizing: "border-box",
//       borderRadius: "8px",
//     };
//     return (
//       <Button
//         style={btnStyle}
//         type="primary"
//         icon={<img src={props.img} alt={props.title} />}
//         onClick={props.click}
//       />
//     );
//   }
//   return (
//     <Button style={btnStyle} type block { ... }>
//       {props.title}
//     </Button>
//   );
// }

export default MiinedButton;
