import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import "./input.scss";

const { Option } = Select;

const SelectWithLabel = ({ label, value, handleChange }) => {
  return (
    <div className="textOnInput">
      <p className="label">{label}</p>
      <Select
        defaultValue={value}
        onChange={handleChange}
        className="Custome_select"
        size="large"
      >
        <Option value="Debit / Credit Cards">Debit / Credit Cards</Option>
        <Option value="Net Banking">Net Banking</Option>
        <Option value="Amazon Pay">Amazon Pay</Option>
        <Option value="Saved Cards">Saved Cards</Option>
      </Select>
    </div>
  );
};

SelectWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.string,
};

SelectWithLabel.defaultProps = {
  value: false,
  handleChange: () => {},
};

export default SelectWithLabel;
