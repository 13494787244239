import * as Yup from "yup";

export const key = "education";
export const schema = [
  {
    field: "input",
    label: "Field",
    id: "field",
    type: "text",
  },
  {
    field: "input",
    label: "School",
    id: "school",
    type: "text",
  },
  {
    field: "input",
    label: "Grade",
    id: "grade",
    type: "text",
  },
  {
    field: "monthnyearpicker",
    label: "From Year",
    id: "from",
    type: "date",
    grid: 6,
  },
  {
    field: "monthnyearpicker",
    label: "To Year",
    id: "to",
    type: "date",
    grid: 6,
  },
];

export const values = {
  level: "",
  field: "",
  school: "",
  grade: "",
  from: "",
  to: "",
};

export const initialValues = {
  education: [values],
};

export const validation = Yup.object().shape({
  education: Yup.array().of(
    Yup.object().shape({
      // field: Yup.string().required("Field is required"),
      // school: Yup.string().required("School is required"),
      // grade: Yup.string().required("Grade is required"),
      // from: Yup.string().required("From is required"),
      // to: Yup.string().required("To is required"),
    })
  ),
});
