import React from "react";
import { Button } from "antd";
import EvaluateSearch from "../../../assets/images/tools/evaluate_search_icon.svg";
import EvaluateImg from "../../../assets/images/tools/evaluate_image.svg";
import "./evaluateJob.scss";
import EvaluateTable from "./evaluateTable";
import Tools from "../Tools";
import InputWithLabel from "../../Profile/inputwithLabel";

const EvaluateJob = () => {
  return (
    <Tools>
      <div className="evaluateJob_container">
        <div className="form_conatiner">
          <div className="header_conatiner">
            <img alt="search" src={EvaluateSearch} className="search_icon" />
            <div className="text_holder">
              <h4>Evaluate a job offer</h4>
              <p>Compare new company with current employer</p>
            </div>
            <img alt="EvaluateImg" src={EvaluateImg} className="form_img" />
          </div>
          <div className="sub_title">
            Fill in the below details to find out the match
          </div>
          <div className="form_wrapper">
            <div className="input_holder">
              <InputWithLabel label="Company Name" value="Google" />
            </div>
            <div className="input_holder">
              <InputWithLabel label="Company URL" value="google.com" />
            </div>
            <div className="input_holder">
              <InputWithLabel
                label="Job Title"
                value="Senior Software Engineer"
              />
            </div>
            <div className="input_holder">
              <InputWithLabel label="Compensation" value="$520,000 p.a" />
            </div>
          </div>
          <Button type="primary" shape="round" ghost className="custom_btn">
            Evaluate Now
          </Button>
        </div>
        <div className="table_container">
          <EvaluateTable />
        </div>
      </div>
    </Tools>
  );
};

export default EvaluateJob;
