/* eslint-disable */
import {
  formatAchievementsData,
  formatAssociationData,
  findAndUpdate,
  formatATSdata,
  formatCertificateData,
  formatContactData,
  formatEducationData,
  formatEmployementData,
  formatLanguageData,
  formatMiliteryData,
  formatPiechartData,
  formatReferenceData,
  formatSecurityData,
  formatSkillsData,
  formatTrainingData,
  // formatOtheres,
  getResumeScreens,
} from "../../utils/helper";
import * as types from "./resumeOptimizerTypes";

const INITIAL_STATE = {
  loader: false,
  loaded: false,
  saveLoader: false,
  downloadLoader: false,
  resumeScreens: [],
  unsavedExperience: "",
  downloadLink: "",
  resumeVaults: [],
  resumeCloned: "",
  currentResumePage: "all",
  currentResume: "",
  suggestionLoader: false,
  contactInfo: formatContactData(""),
  employementInfo: formatEmployementData(""),
  educationInfo: formatEducationData(""),
  certificateInfo: formatCertificateData(""),
  languageInfo: formatLanguageData(""),
  skillsInfo: formatSkillsData(""),
  achievementsInfo: formatAchievementsData(""),
  associationInfo: formatAssociationData(""),
  pieChartData: formatPiechartData(""),
  militeryInfo: formatMiliteryData(""),
  securityInfo: formatSecurityData(""),
  trainingInfo: formatTrainingData(""),
  referenceInfo: formatReferenceData(""),
  ExperienceSummaryDetails: "",
  skillsTaxanomy: [],
  skillsForAtsView: [],
  otheresSections: [],
  isOtheresAdded: false,
  initialResumeCloned: false,
  currentTemplate: 1,
  currentStep: 0,
  currntEployementIndex: 0,
  currentEmplyementSkills: { soft_skills_name: "", hard_skills_name: "" },
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SAVE_LOADER:
      return {
        ...state,
        loader: action.payload,
        error: "",
      };
    case types.GET_RESUME_VAULTS_REQUEST:
      return {
        ...state,
        loader: true,
        error: "",
      };
    case types.GET_RESUME_VAULTS_SUCCESS:
      return {
        ...state,
        loader: false,
        resumeVaults: action.payload,
        error: "",
      };
    case types.GET_RESUME_VAULTS_FAILURE:
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    case types.PUT_RESUME_VAULTS_REQUEST:
      return {
        ...state,
        saveLoader: true,
        error: "",
      };
    case types.PUT_RESUME_VAULTS_SUCCESS:
      return {
        ...state,
        saveLoader: false,
        resumeVaults: findAndUpdate(state.resumeVaults, action.payload),
        error: "",
      };
    case types.PUT_RESUME_VAULTS_FAILURE:
      return {
        ...state,
        saveLoader: false,
        error: action.payload,
      };
    case types.CLONE_RESUME_REQUEST:
      return {
        ...state,
        loader: true,
        resumeCloned: "",
        error: "",
      };
    case types.CLONE_RESUME_SUCCESS:
      return {
        ...state,
        loader: false,
        initialResumeCloned: true,
        resumeCloned: action.payload
          .map((item) => item.resume_id)
          .filter(
            (x) => !state.resumeVaults.map((item) => item.resume_id).includes(x)
          )[0],
        currentResume: action.payload
          .map((item) => item.resume_id)
          .filter(
            (x) => !state.resumeVaults.map((item) => item.resume_id).includes(x)
          )[0],
        resumeVaults: action.payload,
        error: "",
      };
    case types.CLONE_RESUME_FAILURE:
      return {
        ...state,
        loader: false,
        resumeCloned: "",
        error: action.payload,
      };
    case types.RESUME_REQUEST:
      return {
        ...state,
        loader: true,
        loaded: false,
        resumeData: "",
        contactInfo: formatContactData(""),
        employementInfo: formatEmployementData(""),
        educationInfo: formatEducationData(""),
        // certificateInfo: formatCertificateData(""),
        // languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(""),
        // achievementsInfo: formatAchievementsData(""),
        // associationInfo: formatAssociationData(""),
        // pieChartData: formatPiechartData(""),
        // militeryInfo: formatMiliteryData(""),
        // securityInfo: formatSecurityData(""),
        // trainingInfo: formatTrainingData(""),
        // referenceInfo: formatReferenceData(""),
        otheresSections: [],
        skillsTaxanomy: [],
        resumeScreens: [],
        summary: "",
      };
    case types.RESUME_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loader: false,
        loaded: true,
        resumeData: "",
        originalView: "",
        contactInfo: formatContactData(action.payload.ContactInfo),
        employementInfo: formatEmployementData(
          action.payload.EmploymentHistory
        ),
        educationInfo: formatEducationData(action.payload.EducationHistory),
        // certificateInfo: formatCertificateData(
        //   action.payload.LicensesAndCertifications
        // ),
        // languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(action.payload.SkillsTaxonomy),
        ExperienceSummaryDetails: action.payload.ExperienceSummaryDetails,
        // achievementsInfo: formatAchievementsData(action.payload.Achievements),
        // associationInfo: formatAssociationData(
        //   action.payload.Associations?.Association
        // ),
        // militeryInfo: formatMiliteryData(action.payload.MilitaryExperience),
        // securityInfo: formatSecurityData(""),
        // trainingInfo: formatTrainingData(action.payload.TrainingHistory),
        // referenceInfo: formatReferenceData(action.payload.References),
        otheresSections: action.payload?.others,
        skillsForAtsView: action.payload?.skills_for_job_match || [],
        isOtheresAdded: action.payload?.others?.length ? true : false,
        skillsTaxanomy: action.payload?.SkillsTaxonomy || [],
        summary: action.payload?.ExecutiveSummary || "",
        dashBoardSkills: action.payload?.OriginalSkillsExtract || [],
        OriginalSkills: action.payload?.OriginalSkills || [],
        resumeScreens: getResumeScreens(action.payload),
      };
    case types.RESUME_FAILURE:
      return {
        ...state,
        loader: false,
        loaded: true,
        resumeData: "",
        contactInfo: formatContactData(""),
        employementInfo: formatEmployementData(""),
        educationInfo: formatEducationData(""),
        // certificateInfo: formatCertificateData(""),
        // languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(""),
        // achievementsInfo: formatAchievementsData(""),
        // associationInfo: formatAssociationData(""),
        // pieChartData: formatPiechartData(""),
        // militeryInfo: formatMiliteryData(""),
        // securityInfo: formatSecurityData(""),
        // trainingInfo: formatTrainingData(""),
        // referenceInfo: formatReferenceData(""),
        otheresSections: [],
        skillsTaxanomy: [],
        resumeScreens: [],
        summary: "",
      };
    case types.SET_RESUME_SCREENS:
      return {
        ...state,
        resumeScreens: action.payload,
      };
    case types.UPDATE_RESUME_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.UPDATE_RESUME_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loader: false,
        resumeData: "",
        originalView: "",
        contactInfo: formatContactData(action.payload.ContactInfo),
        employementInfo: formatEmployementData(
          action.payload.EmploymentHistory
        ),
        educationInfo: formatEducationData(action.payload.EducationHistory),
        // certificateInfo: formatCertificateData(
        //   action.payload.LicensesAndCertifications
        // ),
        // languageInfo: formatLanguageData(""),
        skillsInfo: formatSkillsData(action.payload.SkillsTaxonomy),
        ExperienceSummaryDetails: action.payload.ExperienceSummaryDetails,
        // achievementsInfo: formatAchievementsData(action.payload.Achievements),
        // associationInfo: formatAssociationData(
        //   action.payload.Associations?.Association
        // ),
        // militeryInfo: formatMiliteryData(action.payload.MilitaryExperience),
        // securityInfo: formatSecurityData(""),
        // trainingInfo: formatTrainingData(action.payload.TrainingHistory),
        // referenceInfo: formatReferenceData(action.payload.References),
        otheresSections: [],
        isOtheresAdded: action.payload?.others?.length ? true : false,
        skillsTaxanomy: action.payload?.SkillsTaxonomy || [],
        summary: action.payload?.ExecutiveSummary || "",
        dashBoardSkills: action.payload?.OriginalSkillsExtract || [],
        OriginalSkills: action.payload?.OriginalSkills || [],
        resumeScreens: getResumeScreens(action.payload),
      };
    case types.UPDATE_RESUME_FAILURE:
      return {
        ...state,
        loader: false,
      };
    // case types.GET_OTHERES_SECTIONS_REQUEST:
    //   return {
    //     ...state,
    //     saveLoader: true,
    //     error: "",
    //   };
    // case types.GET_OTHERES_SECTIONS_SUCCESS:
    //   return {
    //     ...state,
    //     saveLoader: false,
    //     otheresSections: action.payload.rephrased_response,
    //     error: "",
    //   };
    // case types.GET_OTHERES_SECTIONS_FAILURE:
    //   return {
    //     ...state,
    //     saveLoader: false,
    //     error: action.payload,
    //   };
    case types.GET_SKILLS_REQUEST:
      return {
        ...state,
        saveLoader: true,
        error: "",
      };
    case types.GET_SKILLS_SUCCESS:
      return {
        ...state,
        saveLoader: false,
        currentEmplyementSkills: {
          ...state.currentEmplyementSkills,
          ...action.payload,
        },
      };
    case types.GET_SKILLS_FAILURE:
      return {
        ...state,
        saveLoader: false,
        error: action.payload,
      };
    case types.DELETE_RESUME_REQUEST:
      return {
        ...state,
        saveLoader: true,
        error: "",
      };
    case types.DELETE_RESUME_SUCCESS:
      return {
        ...state,
        saveLoader: false,
        resumeVaults: action.payload,
        error: "",
      };
    case types.DELETE_RESUME_FAILURE:
      return {
        ...state,
        saveLoader: false,
        error: action.payload,
      };

    case types.DOWNLOAD_RESUME_PDF_REQUEST:
      return {
        ...state,
        downloadLoader: true,
        error: "",
      };
    case types.DOWNLOAD_RESUME_PDF_SUCCESS:
      return {
        ...state,
        downloadLoader: false,
        downloadLink: action.payload.url,
      };
    case types.DOWNLOAD_RESUME_PDF_FAILURE:
      return {
        ...state,
        downloadLoader: false,
        downloadLink: "",
        error: action.payload,
      };
    case types.SYNC_JOBMATCHSKILLS_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case types.SYNC_JOBMATCHSKILLS_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case types.SYNC_JOBMATCHSKILLS_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case types.SET_CURRENT_RESUMEPAGE:
      return {
        ...state,
        loader: false,
        currentResumePage: action.payload,
      };
    case types.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      };
    case types.SET_CURRENT_RESUME_ID:
      return {
        ...state,
        currentResume: action.payload,
      };
    case types.SET_SUGGESTION_LOADER:
      return {
        ...state,
        suggestionLoader: action.payload,
      };
    case types.SET_IS_OTHERES_ADDED:
      return {
        ...state,
        isOtheresAdded: action.payload.bool,
        // otheresSections: [...state.otheresSections, action.payload.data],
      };
    case types.SET_CURRENT_EMPLOYEMENT_INDEX:
      return {
        ...state,
        currntEployementIndex: action.payload,
        currentStep:
          state.employementInfo.employment[action.payload]?.currentStep || 0,
      };
    case types.SET_EXPERIENCE_SAVED:
      return {
        ...state,
        unsavedExperience: action.payload,
      };
    case types.RESET:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
