import React, { useState } from "react";
import { Button, Input, Spin } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./topcontainer.scss";
import MatchToJobCommonComp from "../../matchjob/matchTojobCommonComp";
import InputWithLabel from "../../../Profile/inputwithLabel";

const { TextArea } = Input;

const MJRTopConatiner = () => {
  const [displayDetails, setdisplayDetails] = useState(false);
  const isLoading = useSelector((state) => state.tools.loading);
  const formData = useSelector((state) => state.tools.formData);

  return (
    <MatchToJobCommonComp>
      <Spin size="large" spinning={isLoading}>
        <div className="mjr_topcontainer">
          <div className="job_title">
            {formData.companyname}
            <spn> | </spn>
            {formData.title}
          </div>
          <div
            className="details_container"
            style={{ display: displayDetails ? "block" : "none" }}
          >
            <div className="input_holders_section">
              <div className="item_input">
                <InputWithLabel
                  label="Company Name"
                  value={formData.companyname}
                />
              </div>
              <div className="item_input">
                <InputWithLabel label="Company URL" value={formData.url} />
              </div>
              <div className="item_input">
                <InputWithLabel label="Job Title" value={formData.title} />
              </div>
            </div>
            <div className="text_area_section">
              <TextArea
                className="text_item"
                value={formData.text}
                placeholder="Job Description"
                autoSize={{ minRows: 5, maxRows: 8 }}
              />
            </div>
          </div>

          <Button
            type="link"
            className="viewall_btn"
            onClick={() => {
              setdisplayDetails(!displayDetails);
            }}
          >
            View details
            {displayDetails ? <UpOutlined /> : <DownOutlined />}
          </Button>
        </div>
      </Spin>
    </MatchToJobCommonComp>
  );
};

export default MJRTopConatiner;
