/* eslint-disable */
import React from "react";
import { Route, Redirect } from "react-router-dom";

const isLogin = () => {
  if (localStorage.getItem("unnlockUserToken")) {
    return true;
  } else {
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/registration" />
      }
    />
  );
};

export default PrivateRoute;
