/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AutoComplete, Layout, Menu, Image, Avatar } from "antd";
// import { LogoutOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
// eslint-disable-next-line camelcase
// import jwt_decode from "jwt-decode";
import messages from "../assets/strings/messages";
import * as LoginActions from "../store/newLogin/newLoginAction";
import * as RegisterActions from "../store/newRegister/registerActions";
import * as DashBoardActions from "../store/dashBoard/dashboardActions";
import * as ComponiesActions from "../store/componies/action";
// import * as ResumeActions from "../store/resume/resumeActions";
import * as ProfileActions from "../store/profile/profileActions";
import * as ToolsActions from "../store/Tools/toolsActions";
import gradientlogo from "../assets/images/tools/gradientlogo.svg";
import EvaluateSearch from "../assets/images/tools/evaluate_search_icon.svg";
import history from "../routes/History";
import "./style.scss";
// import notification from "../assets/images/notification.svg";
import avatar from "../assets/images/DefaultImage.svg";
import search from "../assets/images/search.svg";
import unnlockLogo from "../assets/images/unnlockLogo.svg";

const { Header } = Layout;
// const { Title } = Typography;

const mockVal = (str, repeat) => {
  return {
    value: str.repeat(repeat),
  };
};

const MiinedHeader = () => {
  const dispatch = useDispatch();

  // const userData = useSelector((state) => state.user.user);
  // const userPersonalData = useSelector((state) => state.user.userPersonalData);
  const SecondaryResumeIds = useSelector(
    (state) => state.resume.SecondaryResumeIds
  );
  const loginState = useSelector((state) => state.login);

  const [value, setValue] = useState("");
  const [showToolMenue, setShowToolMenue] = useState(false);
  const [showProfileMenue, setShowProfileMenue] = useState(false);
  const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   if (!isEmpty(userData) && !isEmpty(userPersonalData)) {
  //     const decoded = jwt_decode(userData.id_token);
  //     const date = new Date(0);

  //     const tokExpiryDate = date.setUTCSeconds(decoded.exp);

  //     if (tokExpiryDate.valueOf() < new Date().valueOf()) {
  //       dispatch(
  //         LoginActions.refreshLoginRequest({
  //           user_id: userPersonalData.user_id,
  //           refresh_token: userData.refresh_token,
  //         })
  //       );
  //     }
  //   }
  // }, [userData, userPersonalData]);

  useEffect(() => {
    if (!loginState.userData) {
      dispatch(LoginActions.getUserDataRequest());
    }
  }, []);

  const onSearch = (searchText) => {
    setOptions(
      !searchText
        ? []
        : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)]
    );
  };
  const onSelect = (data) => {
    // console.log("onSelect", data);
  };
  const onChange = (data) => {
    setValue(data);
  };
  const signoutHandler = () => {
    window.localStorage.clear();
    dispatch(LoginActions.reset());
    dispatch(ResumeActions.reset());
    dispatch(ProfileActions.reset());
    dispatch(ToolsActions.reset());
    dispatch(RegisterActions.reset());
    dispatch(ComponiesActions.reset());
    dispatch(DashBoardActions.reset());
    history.push("/");
  };
  const headerItemClick = (item) => {
    switch (item.key) {
      case "home":
        history.push("/dashboard");
        break;
      case "dashboard":
        history.push("/dashboard");
        break;
      // case "resumes":
      //   if (SecondaryResumeIds?.length) {
      //     history.push("/resumeVault");
      //   } else {
      //     history.push("/resumes");
      //   }
      //   break;
      // case "resumes2":
      //   if (SecondaryResumeIds?.length) {
      //     history.push("/resumeVault2");
      //   } else {
      //     history.push("/resumes2");
      //   }
      //   break;
      case "resumes3":
        if (SecondaryResumeIds?.length) {
          history.push("/resumeVault3");
        } else {
          history.push("/resumes3");
        }
        break;
      case "analysis":
        history.push("/analysis");
        break;
      case "analysis2":
        history.push("/analysis2");
        break;
      case "companies":
        history.push("/companyMatch");
        break;
      case "recruiters":
        history.push("/recruiters");
        break;
      case "tools":
        history.push("/tools");
        break;
      case "evaluateJob":
        history.push("/evaluateJob");
        break;
      // case "blogs":
      //   history.push("/blogs");
      //   break;
      default:
        // history.push("/dashboard");
        break;
    }
  };
  const content = (
    <div className="popover_container">
      <div
        className="list_item"
        onClick={() => {
          history.push("/tools");
        }}
        onKeyDown={() => {
          history.push("/tools");
        }}
        role="button"
        tabIndex={0}
      >
        <div className="icon_custom">
          <img src={gradientlogo} alt="icon" />
        </div>
        <div className="popover_txt">
          <h3>Match to job posting</h3>
          <div>Find out how well suited are you for the job</div>
        </div>
      </div>
      <div
        className="list_item"
        style={{ marginTop: "15px" }}
        onClick={() => {
          history.push("/evaluateJob");
        }}
        onKeyDown={() => {
          history.push("/evaluateJob");
        }}
        role="button"
        tabIndex={0}
      >
        <div className="icon_custom">
          <img src={EvaluateSearch} alt="icon" />
        </div>
        <div className="popover_txt">
          <h3>Evaluate a job offer</h3>
          <div>
            Compare a new company with your current <br />
            employer
          </div>
        </div>
      </div>
    </div>
  );
  const propfileItems = [
    { label: "My Profile", path: "/profile" },
    { label: "Contact Support", path: "/profile" },
    { label: "Terms & Policies", path: "/profile" },
    { label: "FAQ’s", path: "/profile" },
    { label: "Log Out", path: "/profile" },
  ];

  const profileContent = (
    <div className="proppopover_container">
      {propfileItems.map((item) => (
        <div
          className="profile_list_item"
          key={item}
          onClick={() => {
            if (item.label === "Log Out") {
              signoutHandler();
            } else {
              history.push(item.path);
            }
          }}
          onKeyDown={() => {
            if (item.label === "Log Out") {
              signoutHandler();
            } else {
              history.push(item.path);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <div>{item.label}</div>
        </div>
      ))}
    </div>
  );

  return (
    <Header className="header-style">
      {/* <Title className="mined-title" level={2}>
        {messages.logo}
      </Title> */}
      <img src={unnlockLogo} alt="unnlocklogo" />
      <div className="profile-style" style={{ marginLeft: "" }}>
        <Image
          src={search}
          preview={false}
          width="20px"
          height="20px"
          style={{ cursor: "pointer" }}
        />
        <AutoComplete
          value={value}
          options={options}
          style={{ minWidth: "312px", border: "none", marginLeft: "12px" }}
          onSelect={onSelect}
          onSearch={onSearch}
          onChange={onChange}
          placeholder={messages.searchCompanies}
        />
      </div>
      <div style={{ width: "55%", marginLeft: "" }}>
        <Menu
          onClick={headerItemClick}
          mode="horizontal"
          defaultSelectedKeys={[
            ["matchjobResults", "evaluateJob"].includes(
              history.location.pathname.split("/")[1]
            )
              ? "tools"
              : history.location.pathname.split("/")[1],
          ]}
        >
          <Menu.Item key="dashboard">{messages.home}</Menu.Item>
          {/* <Menu.Item key="dashboard2">Dashboard2</Menu.Item>
        <Menu.Item key="dashboard3">Dashboard3</Menu.Item> */}
          {/* <Menu.Item key="resumes">Optimization</Menu.Item> */}
          {/* <Menu.Item key="resumes2">Optimization2</Menu.Item> */}
          <Menu.Item key="resumes3">Optimization3</Menu.Item>
          <Menu.Item key="analysis">ATS View</Menu.Item>
          <Menu.Item key="analysis2">ATS View2</Menu.Item>
          {/* <Menu.Item key="analysis3">Analysis3</Menu.Item> */}
          <Menu.Item key="companies">{messages.companies}</Menu.Item>
          <Menu.Item key="tools">Match</Menu.Item>
          <Menu.Item key="evaluateJob">Evaluate</Menu.Item>
          {/* <Menu.Item key="recruiters">{messages.recruiters}</Menu.Item> */}
          {/* <Menu.Item key="tools">
            <div
              onClick={() => setShowToolMenue(!showToolMenue)}
              style={{ position: "relative" }}
            >
              {messages.tools}
              <div className="tool_menu">
                <div
                  style={{ display: showToolMenue ? "block" : "none" }}
                  className="box arrow-top"
                >
                  {content}
                </div>
              </div>
            </div>
          </Menu.Item> */}
          {/* <Menu.Item key="blogs">{messages.blogs}</Menu.Item> */}
        </Menu>
      </div>
      <div className="profile-style">
        {/* <Image
          src={notification}
          preview={false}
          width="24px"
          height="24px"
          style={{ cursor: "pointer", marginRight: "24px" }}
        /> */}
        {/* <LogoutOutlined
          style={{ cursor: "pointer", fontSize: "24px" }}
          onClick={() => {
            signoutHandler();
          }}
        /> */}
        <div
          style={{ position: "relative" }}
          onClick={() => setShowProfileMenue(!showProfileMenue)}
        >
          <div>
            <Avatar
              shape="circle"
              size={42}
              style={{ cursor: "pointer", marginLeft: "36px" }}
              icon={<img src={avatar} alt="avatar" />}
            />
          </div>
          <div className="profile_menu">
            <div
              style={{ display: showProfileMenue ? "block" : "none" }}
              className="box arrow-top"
            >
              {profileContent}
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default MiinedHeader;
