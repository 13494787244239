import React from "react";
import PropTypes from "prop-types";
import ToolsAvatar from "../../../assets/images/tools/ToolsAvatar.svg";
import gradientlogo from "../../../assets/images/tools/gradientlogo.svg";
import "./MatchToJobPosting.scss";

const MatchToJobCommonComp = ({ children }) => {
  return (
    <div className="basicinfo_wrapper_section">
      <div className="basicinfo_container_section">
        <img src={ToolsAvatar} alt="logo" className="avatar_logo" />
        <div className="header_container">
          <img src={gradientlogo} alt="logo" className="" />
          <div className="header_text">
            <h4 className="main_header">Match to job posting</h4>
            <p className="sub_header">
              Find out how well suited are you for the job
            </p>
          </div>
        </div>
        <div className="body_section">{children}</div>
      </div>
    </div>
  );
};
/* eslint-disable */
MatchToJobCommonComp.propTypes = {
  children: PropTypes.any,
};

MatchToJobCommonComp.defaultProps = {
  children: " ",
};

export default MatchToJobCommonComp;
