/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Typography, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import "./EmploymentDetails.scss";
import messages from "../../assets/strings/messages";
import * as ToolsActions from "../../store/Tools/toolsActions";

const { Content } = Layout;
const { Title, Text } = Typography;

export const value = {
  companyName: "",
  url: "",
};
export const initialValues = { employment: [value] };

const validation = Yup.object().shape({
  employment: Yup.array().of(
    Yup.object().shape({
      companyName: Yup.string(),
      url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
    })
  ),
});

const EmploymentDetails = ({ closeModal }) => {
  const dispatch = useDispatch();
  const primaryResumeId = useSelector(
    (state) => state.login?.userData?.primary_resume
  );
  const [initValues, setInitValues] = useState([]);
  const employementInfo = useSelector((state) => state.dashboard.employementInfo);
  const contactInfo = useSelector((state) => state.dashboard.contactInfo);

  const handleSubmit = (values) => {
    const arr = values.employment.map((item) => {
      return {
        name: item.companyName,
        url: item.url,
      };
    });
    const formData = {
      TopCompanies: arr,
    };
    dispatch(ToolsActions.postCompanyURLS({ formData, primaryResumeId }));
    closeModal();
  };

  useEffect(() => {
    const arr = employementInfo.employment.map((item) => {
      return { companyName: item.company, url: "" };
    });
    setInitValues(arr);
  }, [employementInfo]);

  return (
    <Layout className="employment-container">
      <Content style={{ width: "100%" }}>
        <Row
          justify="center"
          align="middle"
          style={{ height: "100%", width: "100%" }}
        >
          <Col span={24} className="employment-section">
            <Row justify="center">
              <Col span={24} justify="center">
                <Title level={3} style={{ textAlign: "center" }}>
                  Hi {contactInfo.formattedName},
                </Title>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <Text>{messages.welcometounnlockTest}</Text>
              </Col>
              <Row className="form-section">
                <Col span={24}>
                  <Title level={5} style={{ marginBottom: "16px" }}>
                    {messages.addthecompTxt}
                  </Title>
                </Col>
                <Col span={24}>
                  <Formik
                    initialValues={{ employment: initValues }}
                    enableReinitialize
                    validationSchema={validation}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <FieldArray name="employment">
                          {({ remove, push }) => (
                            <div>
                              {values.employment.length > 0 &&
                                values.employment.map((employ, index) => (
                                  <Row
                                    key={index}
                                    justify="center"
                                    align="middle"
                                  >
                                    <Col span={8}>
                                      <Field
                                        name={`employment.${index}.companyName`}
                                        placeholder="Company Name"
                                        className="input-filed"
                                        type="text"
                                      />
                                      <div style={{ color: "red" }}>
                                        {errors.employment &&
                                          errors.employment[index] &&
                                          errors.employment[index]
                                            ?.companyName &&
                                          touched.employment &&
                                          touched.employment[index]
                                            ?.companyName && (
                                            <span style={{ color: "red" }}>
                                              {
                                                errors.employment[index]
                                                  ?.companyName
                                              }
                                            </span>
                                          )}
                                        <span style={{ color: "transparent" }}>
                                          dummy
                                        </span>
                                      </div>
                                    </Col>
                                    <Col span={8}>
                                      <Field
                                        name={`employment.${index}.url`}
                                        placeholder="Company URL"
                                        className="input-filed"
                                        type="text"
                                      />
                                      <div style={{ color: "red" }}>
                                        {errors.employment &&
                                          errors.employment[index] &&
                                          errors.employment[index]?.url &&
                                          touched.employment &&
                                          touched.employment[index]?.url && (
                                            <span style={{ color: "red" }}>
                                              {errors.employment[index]?.url}
                                            </span>
                                          )}
                                        <span style={{ color: "transparent" }}>
                                          dummy
                                        </span>
                                      </div>
                                    </Col>
                                    <Col
                                      span={8}
                                      style={{ textAlign: "center" }}
                                    >
                                      <Button
                                        type="primary"
                                        ghost
                                        shape="round"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                ))}
                              <Button
                                type="primary"
                                ghost
                                shape="round"
                                style={{ height: "40px" }}
                                onClick={() =>
                                  push({ companyName: "", url: "" })
                                }
                              >
                                Add New Company
                              </Button>
                              <button
                                type="submit"
                                id="submit_compnay_url"
                                style={{ display: "none" }}
                              >
                                submit
                              </button>
                              <button
                                type="reset"
                                id="reset_compnay_url"
                                style={{ display: "none" }}
                              >
                                submit
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
              <Col span={24} style={{ marginTop: "56px" }}>
                <Row justify="end">
                  <Col span={8}>
                    <Button
                      type="primary"
                      style={{
                        height: "40px",
                        background: "white",
                        color: "black",
                        marginRight: "15px",
                        width: "40%",
                      }}
                      onClick={() => {
                        document.getElementById("reset_compnay_url").click();
                        closeModal();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        height: "40px",
                        background: "#4389FA",
                        color: "#ffffff",
                        width: "50%",
                      }}
                      onClick={() => {
                        document.getElementById("submit_compnay_url").click();
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default EmploymentDetails;
