import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./FilterTab.scss";
import "../Explore/index.scss";
import { Checkbox, Radio, Select } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  getOpenJobs,
  setOpenJobFilters,
  setOpenJobcities,
} from "../../store/Tools/toolsActions";
import MiinedButton from "../../components/Button";
import { stateData } from "../../utils/Data";

const FilterTab = ({ companyFilterList, selectedExploreSkills }) => {
  const dispatch = useDispatch();
  const {
    appliedOpenJobFilters: { state, city },
    openJobCities: cityList,
  } = useSelector((state) => state.tools);

  const selectedState = localStorage.getItem("openJobState");

  useEffect(() => {
    if (!state?.length) {
      dispatch(
        setOpenJobFilters({
          state: [selectedState],
          city: [],
        })
      );
    }
  }, [selectedState, state]);

  useEffect(() => {
    // console.log("Ourtside loop ", companyFilterList.city_list);
    // if (!cityList.length) {
    //   console.log("Insside loop ", cityList);
    dispatch(
      setOpenJobcities(
        companyFilterList?.city_list?.map((city) => city?.city_name) || []
      )
    );
    // }
  }, [companyFilterList.city_list]);

  return (
    <div className="leftNav-container">
      <div style={{ overflow: "hidden" }}>
        <div style={{ fontSize: "16px", fontWeight: 600 }}>Selected Skills</div>
        <div style={{ wordBreak: "break-all" }}>
          {selectedExploreSkills?.join(", ")}
        </div>
      </div>
      <div
        style={{
          borderBottom: "2px solid #d5e0f3",
          marginTop: "8px",
        }}
      />
      <div
        className="filteresCount"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <span className="Filters">Filters</span>
        </div>
        {state.length || city.length ? (
          <MiinedButton
            title="Clear"
            onClick={(e) => {
              dispatch(
                getOpenJobs({
                  specialized_skills: selectedExploreSkills,
                  state_list: [selectedState],
                  city_list: [],
                })
              );
              //dispatch(setOpenJobcities([]));
              dispatch(
                setOpenJobFilters({
                  state: [],
                  city: [],
                })
              );
            }}
          />
        ) : null}
      </div>
      <div className="filter-titles" style={{ marginTop: "8px" }}>
        State
      </div>
      <div className="filter_body" style={{ height: "auto" }}>
        <Select
          value={state[0]}
          defaultValue="Select State"
          onChange={(e) => {
            dispatch(
              setOpenJobFilters({
                state: [e],
                city: [],
              })
            );
            dispatch(setOpenJobcities([]));
            dispatch(
              getOpenJobs({
                specialized_skills: selectedExploreSkills,
                state_list: [e],
                city_list: [],
              })
            );
          }}
          className="customeselectResume"
          style={{ width: "99%" }}
        >
          <Option key={undefined}>Select State</Option>
          {stateData?.length &&
            [...new Set(stateData.map((state) => state))]
              .sort()
              .map((i) => <Option key={i.value}>{i.value}</Option>)}
        </Select>
      </div>
      <div
        style={{
          borderBottom: "2px solid #d5e0f3",
          marginBottom: "12px",
        }}
      />

      <div className="filter-titles" style={{ marginTop: "8px" }}>
        City
      </div>
      <PerfectScrollbar className="filter_body">
        <Checkbox.Group
          // defaultValue={[]}
          className="custom_check_grp"
          onChange={(e) => {
            dispatch(
              setOpenJobFilters({
                state: state,
                city: e,
              })
            );
            dispatch(
              getOpenJobs({
                specialized_skills: selectedExploreSkills,
                state_list: state,
                city_list: companyFilterList?.city_list?.map((city) => {
                  if (e.includes(city.city_name)) {
                    return {
                      ...city,
                      checked: true,
                    };
                  }
                  return {
                    ...city,
                    checked: false,
                  };
                }),
              })
            );
          }}
          value={city}
        >
          {[...new Set(cityList.map((city) => city))].sort().map((city, i) => (
            <Checkbox value={city} style={{ margin: "0px" }} key={i}>
              {city}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </PerfectScrollbar>
    </div>
  );
};

export default FilterTab;
