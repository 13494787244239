/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Divider, Tabs } from "antd";
import * as COMPANYActions from "../../../store/componies/action";
import history from "../../../routes/History";
import Heart from "../../../assets/images/componies/heart.svg";
import Heartfilled from "../../../assets/images/componies/heartFilled.svg";
import ArrowLeft from "../../../assets/images/componies/ArrowLeft.svg";
import verticleDevider2 from "../../../assets/images/componies/verticleDevider2.svg";
import verticleDevider3 from "../../../assets/images/componies/verticleDevider3.svg";
import DefaultImage from "../../../assets/images/componies/defaultImage.svg";
import Globe from "../../../assets/images/componies/Globe.svg";
import LinkedinLogo from "../../../assets/images/componies/LinkedinLogo.svg";

import "./CompanyDetails.scss";
import CommonCard from "./commonCard";
import makeGeoJSON from "../GeoJson";
import LocationsMap from "../LocationsMap";
import { resumeParserRequest } from "../../../store/dashBoard/dashboardActions";
import { getUserDataRequest } from "../../../store/newLogin/newLoginAction";
// import { PreviewSuggestionWithoutInfo } from "../../ResumeOpimizer/suggestions";
import HardAndSoftSkills from "./hard&SoftSkills";

const checkvalidArray = (data) => {
  if (Array.isArray(data) && data.length) {
    return true;
  } else return false;
};

const CompanyDetails = ({ handleBack, setisComponyDetails }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const loginLoader = useSelector((state) => state.login.loader);
  const companyLoader = useSelector((state) => state.componies.Loader);
  const companyId = useSelector((state) => state.componies.selectedCompanyId);
  const companyDeatils = useSelector((state) => state.componies.companyDeatils);
  const companyDeatilsErr = useSelector(
    (state) => state.componies.companyDeatilsErr
  );
  const user_socs = useSelector((state) => state.profile.user_socs);

  const savedHardSocsSkills = useSelector(
    (state) => state.componies.hard_skillsSocArray
  );
  const savedSoftSocsSkills = useSelector(
    (state) => state.componies.soft_skillsSocArray
  );
  const savedCertificationSocsSkills = useSelector(
    (state) => state.componies.certificationSocsArray
  );

  const dashboardSkills = useSelector(
    (state) => state.dashboard.skillsForJobMatch
  );
  const loginState = useSelector((state) => state.login);

  const [faveratesList, setFaveratesList] = useState([]);

  const [hardSocsSkills, setHardSocsSkills] = useState([]);
  const [softSocsSkills, setSoftSocsSkills] = useState([]);
  const [certSocsSkills, setCertSocsSkills] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [locationsList, setLocationsList] = useState([]);

  const getFavClick = (operation, companyId) => {
    dispatch(
      COMPANYActions.favouriteClickRequest({
        operation,
        companyId,
        urlKey: "favourite_companies",
      })
    );
  };

  const handleSocs = (cardtitle, skillArr, skillId) => {
    if (cardtitle === "hard_skills" && savedHardSocsSkills.length > 0) {
      setHardSocsSkills(savedHardSocsSkills);
      return;
    } else if (cardtitle === "soft_skills" && savedSoftSocsSkills.length > 0) {
      setSoftSocsSkills(savedSoftSocsSkills);
      return;
    }
    // else if (
    //   cardtitle === "certifications" &&
    //   savedCertificationSocsSkills.length > 0
    // ) {
    //   setCertSocsSkills(savedCertificationSocsSkills);
    //   return;
    // }

    if (user_socs && user_socs.length > 0) {
      const skill = cardtitle;
      // const id = user_socs[0].name;
      const skillsArr = Array.from(skillArr, (obj) => obj.name);

      // dispatch(COMPANYActions.hardSoftCertSocsCalled(skill));
      dispatch(
        COMPANYActions.getSocSkillsRequest({ skillsArr, skill, id: skillId })
      );
    }
  };

  useEffect(() => {
    if (savedHardSocsSkills && savedHardSocsSkills.length > 0) {
      setHardSocsSkills(savedHardSocsSkills);
    }
    if (savedSoftSocsSkills && savedSoftSocsSkills.length > 0) {
      setSoftSocsSkills(savedSoftSocsSkills);
    }
    if (
      savedCertificationSocsSkills &&
      savedCertificationSocsSkills.length > 0
    ) {
      setCertSocsSkills(savedCertificationSocsSkills);
    }
  }, [savedHardSocsSkills, savedSoftSocsSkills, savedCertificationSocsSkills]);

  useEffect(() => {
    if (companyId) {
      dispatch(COMPANYActions.getComponyDeatailsRequest(companyId));
    }
  }, [companyId, loginLoader]);

  useEffect(() => {
    if (
      userData &&
      userData.favourite_companies &&
      Array.isArray(userData.favourite_companies)
    ) {
      setFaveratesList(userData.favourite_companies);
    }
  }, [userData]);

  useEffect(() => {
    if (companyDeatils?.unnlock_id) {
      dispatch(
        COMPANYActions.getCompanyLocationsRequest({
          unlockId: companyDeatils.unnlock_id,
        })
      );
    }
  }, [companyDeatils]);

  useEffect(() => {
    if (!dashboardSkills.jobMatchSkills.length) {
      dispatch(resumeParserRequest(loginState.userData.primary_resume));
    }
  }, [loginState]);

  useEffect(() => {
    if (companyDeatils) {
      var locations_list = [];
      if (companyDeatils.hasOwnProperty("locations"))
        // locations_list = JSON.parse(
        //   companyDeatils.locations.replace(/: NaN/g, ": null")
        // );
        locations_list = companyDeatils.locations;
      locations_list.forEach(function (location, index, arr) {
        arr[index]["company"] = companyDeatils;
        arr[index]["lat"] = arr[index]["Geometry"]["Point"][1];
        arr[index]["lng"] = arr[index]["Geometry"]["Point"][0];
      });
      const geoLocations = makeGeoJSON.parse(locations_list.flat(), {
        Point: ["lat", "lng"],
      });
      setLocationsList(geoLocations);
    }
  }, [companyDeatils]);

  useEffect(() => {
    dispatch(getUserDataRequest());
    return () => {
      setisComponyDetails(false);
      dispatch(COMPANYActions.getComponyDeatailsFailure(""));
    };
  }, []);

  return (
    <div className="companyDetailScreen">
      {companyDeatils ? (
        <div className="companymaincardsection">
          <div className="companymaincard">
            <div className="companymaincardInner">
              <div
                onClick={() => {
                  if (
                    window &&
                    window.location.search === "?ExploreByCname=true"
                  ) {
                    history.goBack();
                  } else {
                    handleBack();
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={ArrowLeft}
                  alt="arrow"
                  style={{
                    marginBottom: "4px",
                    width: "20px",
                    marginRight: "8px",
                  }}
                />
                <span style={{ fontWeight: 600, textDecoration: "underline" }}>
                  {window && !window.location.search
                    ? "Company Match"
                    : "Company Explore"}
                </span>
              </div>
              <img
                src={verticleDevider2}
                alt="verticleDevider"
                style={{
                  margin: "0px 15px",
                }}
              />
              <div>Company Details</div>
            </div>
            <div
              onClick={() =>
                getFavClick(
                  faveratesList.includes(`${companyDeatils.unnlock_id}`)
                    ? "remove"
                    : "add",
                  companyDeatils.unnlock_id
                )
              }
              style={{ cursor: "pointer" }}
              className="favClick"
            >
              <img
                src={
                  faveratesList.includes(`${companyDeatils.unnlock_id}`)
                    ? Heartfilled
                    : Heart
                }
                alt="arrow"
              />{" "}
              <span>
                {!faveratesList.includes(`${companyDeatils.unnlock_id}`)
                  ? "Add to "
                  : "Remove from "}
                Favorites
              </span>
            </div>
          </div>
          <Skeleton loading={companyLoader} active>
            <div className="companycard">
              <div className="companycardwrap">
                <div className="companyimagewrap">
                  <img
                    src={companyDeatils.logo_url}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = DefaultImage;
                    }}
                    alt="cl"
                    className="companyLogo"
                  />
                </div>
                <div className="companyDescriptionwrap">
                  <div className="cName">{companyDeatils.company}</div>
                  <div className="location">
                    {checkvalidArray(companyDeatils.city_name)
                      ? companyDeatils.city_name[0].name
                      : null}
                  </div>

                  <div className="cAttributes">
                    <div className="typeAndSize">
                      <div className="type">
                        <div style={{ fontWeight: 600 }}>Type</div>
                        <div>{companyDeatils.type}</div>
                      </div>
                      <div className="size">
                        <div style={{ fontWeight: 600 }}>Employees</div>
                        <div>{companyDeatils.company_size}</div>
                      </div>
                    </div>
                    <div className="industryQuickLink">
                      <div className="industry">
                        <div style={{ fontWeight: 600 }}>Industry</div>
                        <div>{companyDeatils.industry}</div>
                      </div>
                      <div className="quickLinks">
                        <div style={{ fontWeight: 600 }}>Quick Links</div>
                        {companyDeatils.company_web_url ? (
                          <a
                            href={companyDeatils.company_web_url}
                            target="_blank"
                            style={{ marginRight: "5px" }}
                          >
                            <img src={Globe} alt="web" />
                          </a>
                        ) : null}
                        {companyDeatils.l_url_jobs ? (
                          <a
                            href={"https://" + companyDeatils.l_url_jobs}
                            target="_blank"
                          >
                            <img src={LinkedinLogo} alt="linked in" />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <img
                src={verticleDevider3}
                alt="verticleDevider"
                style={{ margin: "0px 20px" }}
              /> */}

              <img
                src={verticleDevider3}
                alt="verticleDevider"
                className="verticleDevider"
              />
              <div className="cDescription">{companyDeatils.description}</div>
            </div>
          </Skeleton>

          <Skeleton loading={companyLoader} active>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="companySubCardsWrapper">
                <div className="companySubCards">
                  <Tabs onChange={(e) => setCurrentTab(e)}>
                    {[
                      "Hard Skills",
                      "Soft Skills",
                      "Location",
                      "Certifications",
                      "Colleges",
                      "Titles",
                    ].map((item, i) => (
                      <Tabs.TabPane tab={item} key={i}>
                        {item}
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </div>
                {/* <Divider style={{ margin: "-1px 0px 0px" }} /> */}

                {currentTab == 0 && (
                  <div
                    style={{
                      display: currentTab == 0 ? "block" : "none",
                      background: "#e9f1ff",
                      paddingTop: "20px",
                    }}
                    className="commonCardContainer"
                  >
                    <HardAndSoftSkills
                      title="Hard Skills"
                      companyDeatils={companyDeatils.hard_skills_name}
                      handleSocsClick={(id) =>
                        handleSocs(
                          "hard_skills",
                          companyDeatils.hard_skills_name,
                          id
                        )
                      }
                      socsArrays={hardSocsSkills}
                    />
                  </div>
                )}
                {currentTab == 1 && (
                  <div
                    style={{
                      display: currentTab == 1 ? "block" : "none",
                      background: "#e9f1ff",
                      paddingTop: "20px",
                    }}
                    className="commonCardContainer"
                  >
                    <HardAndSoftSkills
                      title="Soft Skills"
                      companyDeatils={companyDeatils.soft_skills_name}
                      handleSocsClick={(id) =>
                        handleSocs(
                          "soft_skills",
                          companyDeatils.soft_skills_name,
                          id
                        )
                      }
                      socsArrays={softSocsSkills}
                    />
                  </div>
                )}
                {currentTab == 2 ? (
                  <div className="commonCardContainer">
                    <LocationsMap
                      locations={locationsList}
                      showList={false}
                      useLocationFilter={false}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    display: currentTab == 3 ? "block" : "none",
                    background: "#e9f1ff",
                    paddingTop: "20px",
                  }}
                  className="commonCardContainer"
                >
                  <CommonCard
                    title="Certifications"
                    companyDeatils={companyDeatils.certifications_name}
                    handleSocsClick={() =>
                      handleSocs(
                        "certifications",
                        companyDeatils.certifications_name
                      )
                    }
                    socsArrays={certSocsSkills}
                  />
                </div>
                <div
                  style={{
                    display: currentTab == 4 ? "block" : "none",
                    background: "#e9f1ff",
                    paddingTop: "20px",
                  }}
                  className="commonCardContainer"
                >
                  <CommonCard
                    title="Colleges"
                    companyDeatils={companyDeatils.schools_name}
                  />
                </div>
                <div
                  style={{
                    display: currentTab == 5 ? "block" : "none",
                    background: "#e9f1ff",
                    paddingTop: "20px",
                  }}
                  className="commonCardContainer"
                >
                  <CommonCard
                    title="Titles"
                    companyDeatils={companyDeatils.title_name}
                  />
                </div>
              </div>
              {/* <div style={{ width: "25%" }}>
                <PreviewSuggestionWithoutInfo page="companyDetails" />
              </div> */}
            </div>
          </Skeleton>
        </div>
      ) : (
        <div style={{ textAlign: "center", padding: "20px" }}>
          {companyDeatilsErr === "SUBSCRIPTION_EXPIRED" && !companyLoader ? (
            <div className="subscribeAlertContainer">
              <p>Upgrade your plan to view additional companies</p>
              <button onClick={() => history.push("/subscriptions")}>
                Subscribe
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default CompanyDetails;
