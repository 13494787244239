import * as types from "./jobMatchTypes";

// Job Match Results Actions
export const jobMatchResultsRequest = (payload) => ({
  type: types.JOBMATCHRESULTS_REQUEST,
  payload,
});
export const jobMatchResultsSuccess = (payload) => ({
  type: types.JOBMATCHRESULTS_SUCCESS,
  payload,
});
export const jobMatchResultsrFailure = (payload) => ({
  type: types.JOBMATCHRESULTS_FAILURE,
  payload,
});

// get job trackeres
export const getJobTrackeresRequest = (payload) => ({
  type: types.GET_JOBTACKERS_REQUEST,
  payload,
});
export const getJobTrackeresSuccess = (payload) => ({
  type: types.GET_JOBTACKERS_SUCCESS,
  payload,
});
export const getJobTrackeresrFailure = (payload) => ({
  type: types.GET_JOBTACKERS_FAILURE,
  payload,
});

// save JobMatchresults
export const saveJobMatchRequest = (payload) => ({
  type: types.SAVE_JOBMATCHRESULTS_REQUEST,
  payload,
});
export const saveJobMatchSuccess = (payload) => ({
  type: types.SAVE_JOBMATCHRESULTS_SUCCESS,
  payload,
});
export const saveJobMatchrFailure = (payload) => ({
  type: types.SAVE_JOBMATCHRESULTS_FAILURE,
  payload,
});

// Update JobMatchresults
export const updateJobMatchRequest = (payload) => ({
  type: types.UPDATE_JOBMATCHRESULTS_REQUEST,
  payload,
});
export const updateJobMatchSuccess = (payload) => ({
  type: types.UPDATE_JOBMATCHRESULTS_SUCCESS,
  payload,
});
export const updateJobMatchrFailure = (payload) => ({
  type: types.UPDATE_JOBMATCHRESULTS_FAILURE,
  payload,
});

export const jobMatchFetchRequest = (payload) => ({
  type: types.JOBMATCHFETCH_REQUEST,
  payload,
});
export const jobMatchFetchSuccess = (payload) => ({
  type: types.JOBMATCHFETCH_SUCCESS,
  payload,
});
export const jobMatchFetchFailure = (payload) => ({
  type: types.JOBMATCHFETCH_FAILURE,
  payload,
});
// Delete JobMatchresults
export const deleteJobTrackRequest = (payload) => ({
  type: types.DELETE_JOBTACKERS_REQUEST,
  payload,
});
export const deleteJobTrackSuccess = (payload) => ({
  type: types.DELETE_JOBTACKERS_SUCCESS,
  payload,
});
export const deleteJobTrackrFailure = (payload) => ({
  type: types.DELETE_JOBTACKERS_FAILURE,
  payload,
});

export const updateJobTrackRequest = (payload) => ({
  type: types.UPDATE_JOBTACKERS_REQUEST,
  payload,
});
export const updateJobTrackSuccess = (payload) => ({
  type: types.UPDATE_JOBTACKERS_SUCCESS,
  payload,
});
export const updateJobTrackrFailure = (payload) => ({
  type: types.UPDATE_JOBTACKERS_FAILURE,
  payload,
});

export const setTrackerID = (payload) => ({
  type: types.SET_TRACKER_ID,
  payload,
});

export const setTrackerIDToUpdate = (payload) => ({
  type: types.SET_TRACKER_ID_TO_UPDATE,
  payload,
});

export const showUpdateSkillsModal = (payload) => ({
  type: types.SHOW_UPDATE_SKILLS_MODAL,
  payload,
});
// reset
export const reset = () => ({
  type: types.RESET,
});
