/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Row, Col, Spin, message } from "antd";
import history from "../../routes/History";
import optimize from "../../assets/images/resumeVault/optimize.svg";
import documentText from "../../assets/images/resumeVault/document-text.svg";
import documentCopy from "../../assets/images/resumeVault/document-copy.svg";
import download from "../../assets/images/resumeVault/download.svg";
import pdf from "../../assets/images/resumeVault/pdf.svg";
import Union from "../../assets/images/resumeVault/Union.svg";
import trash from "../../assets/images/resumeVault/trash.svg";
import editIcon from "../../assets/images/resumeVault/edit-2.svg";
// import lightbulb from "../../assets/images/lightbulb.svg";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  cloneResume,
  getResumeVaultsRequest,
  resumeDeleteRequest,
  setCurrentResumeId,
  // resumeUpdateRequest,
  putResumeVaultsRequest,
  resumeRequest,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import CloneModal from "./cloneModal";
import { Skeleton } from "antd";
import AtsModal from "./atsModal";
import DownloadModal from "./downloadModal";
import PreviewModal from "../ResumeOpimizer/previewModal";
import UnnlockLayout from "../layout";
import unnlockNotification from "../../components/notification";
import { PreviewSuggestionWithoutInfo } from "../ResumeOpimizer/suggestions";

const ResumeVault = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAtsModalVisible, setIsAtsModalVisible] = useState(false);
  const [previewModal, setpreviewModal] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentResume, setcurrentResume] = useState("");
  const [isAddModal, setisAddModal] = useState(false);
  const resumeOptimizerState = useSelector((state) => state.resumeOptimizer);
  const loginState = useSelector((state) => state.login);

  const showModal = () => {
    setName("");
    setIsModalVisible(!isModalVisible);
  };

  const showModal2 = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = () => {
    if (name) {
      if (isEdit) {
        dispatch(
          putResumeVaultsRequest({
            note,
            name,
            id: currentResume.resume_id,
          })
        );
      } else {
        dispatch(
          cloneResume({
            name,
            note,
            fromTracker: false,
            currentResume: currentResume.resume_id,
          })
        );
      }
    } else {
      unnlockNotification("Please enter name!", "warning");
    }
  };

  useEffect(() => {
    if (!resumeOptimizerState.loader) dispatch(getResumeVaultsRequest());
  }, [loginState.loader]);

  const handleDuplicateClick = (item, isEdit) => {
    setcurrentResume(item);
    if (isEdit) {
      setName(item.file_name.split(".")[0] || "");
      setNote(item.notes);
      setIsEdit(true);
      showModal2();
    } else {
      setIsEdit(false);
      showModal();
      setNote("");
    }
    setisAddModal(false);
    dispatch(setCurrentResumeId(item.resume_id));
  };

  const getCurrentResume = (item) => {
    // if (resumeOptimizerState.currentResume !== item.resume_id) {
    dispatch(setCurrentResumeId(item.resume_id));
    dispatch(resumeRequest());
    // }
  };

  console.log(previewModal, "previewModal");

  return (
    <UnnlockLayout>
      <div className="ResumeVault-container">
        <div className="ResumwVault-body">
          <div
            className="ResumeVault-body-header"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div className="vault-title">Your resume vault</div>
            {loginState?.subsCount && loginState?.userData?.tier_id === 0 ? (
              <div
                style={{
                  fontSize: "16px",
                  padding: "0px 10px",
                  marginLeft: "10px",
                  borderRadius: "4px",
                  background: "rgb(245 124 66 / 55%)",
                  color: "white",
                  fontWeight: 500,
                }}
              >{`${loginState?.subsCount?.clone_count} free resumes left.`}</div>
            ) : null}
          </div>
          <Skeleton
            loading={
              resumeOptimizerState.loader &&
              !isAtsModalVisible &&
              !isDownloadModalVisible &&
              !previewModal
            }
            active
          >
            <div className="resumeVault-cards-container">
              <Row className="resumeVault-cards">
                {resumeOptimizerState.resumeVaults.map((item, i) => (
                  <>
                    <Col
                      md={i ? 12 : 24}
                      lg={i ? 12 : 24}
                      xs={24}
                      sm={24}
                      className="vault_card_container"
                    >
                      <div className="vault_card">
                        <div className="vault_card_header">
                          <div justify="space-between">
                            <div className="titleCard">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="documentText">
                                  <img src={documentText} />
                                </div>
                                <div className="resume_name">
                                  <div
                                    className={i ? "name" : ""}
                                    title={item.file_name?.split(".")[0] || ""}
                                  >
                                    {item.file_name?.split(".")[0] || ""}
                                  </div>
                                  {i ? (
                                    <img
                                      src={editIcon}
                                      style={{
                                        paddingLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleDuplicateClick(item, true);
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              {i ? (
                                <div className="edit_btn">
                                  <button
                                    onClick={() => {
                                      history.push({
                                        pathname: `/resumeOptimizer`,
                                        state: {
                                          resumeId: item.resume_id,
                                          resumeName: item.file_name,
                                        },
                                      });
                                      dispatch(
                                        setCurrentResumeId(item.resume_id)
                                      );
                                    }}
                                  >
                                    <div className="ResumevalutOptimizebutton">
                                      <img src={optimize} alt="optimize" />
                                      <p>Optimize</p>
                                    </div>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div>
                              <div className="resume_controles">
                                <Row
                                  gutter={[16, 16]}
                                  justify={i ? "space-between" : "start"}
                                  style={{ alignItems: "center" }}
                                >
                                  <Col>
                                    <div
                                      onClick={() => {
                                        handleDuplicateClick(item, false);
                                      }}
                                      className="white_bg"
                                      id={`white_bg${i}`}
                                    >
                                      <img
                                        src={documentCopy}
                                        alt="CopySimple"
                                      />
                                      <p className="resume-control__optionName">
                                        Duplicate
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      className="white_bg"
                                      onClick={() => {
                                        getCurrentResume(item);
                                        setIsAtsModalVisible(
                                          !isAtsModalVisible
                                        );
                                      }}
                                    >
                                      <img src={Union} alt="DownloadSimple" />
                                      <p className="resume-control__optionName">
                                        ATS View
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      className="white_bg"
                                      onClick={() => {
                                        getCurrentResume(item);
                                        setpreviewModal(!previewModal);
                                      }}
                                    >
                                      <img src={pdf} alt="CopySimple" />
                                      <p className="resume-control__optionName">
                                        View
                                      </p>
                                    </div>
                                  </Col>
                                  {/* <Col>
                                    <div
                                      className="white_bg"
                                      onClick={() => {
                                        dispatch(
                                          setCurrentResumeId(item.resume_id)
                                        );
                                        setIsDownloadModalVisible(
                                          !isDownloadModalVisible
                                        );
                                      }}
                                    >
                                      <img
                                        src={download}
                                        alt="DownloadSimple"
                                      />
                                      <p className="resume-control__optionName">
                                        Download
                                      </p>
                                    </div>
                                  </Col> */}
                                  {i ? (
                                    <Col>
                                      <div
                                        onClick={() => {
                                          dispatch(
                                            resumeDeleteRequest(item.resume_id)
                                          );
                                        }}
                                        className="red_bg"
                                      >
                                        <img src={trash} alt="TrashSimple" />
                                        <p className="resume-control__optionName">
                                          Delete
                                        </p>
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                              <p
                                className="resumelast-edited__date"
                                title={item.notes}
                                onClick={() => {
                                  if (i) {
                                    handleDuplicateClick(item, true);
                                  }
                                }}
                              >
                                {/* Last Updated {convertDate(item.updated_date)} */}
                                {i
                                  ? item.notes
                                  : "This is your original resume"}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <div className="vault_card_body">
                        {i ? (
                          <span className="valut_card__footer">
                            {item.notes}
                          </span>
                        ) : (
                          "This is your original resume"
                        )}
                      </div> */}
                      </div>
                    </Col>
                    {!i ? <div className="devider" /> : null}
                  </>
                ))}
              </Row>
              <div className="resumeVault-Suggestion">
                <PreviewSuggestionWithoutInfo page="resumeVault" />
              </div>
            </div>
          </Skeleton>
        </div>
        <CloneModal
          visible={isModalVisible}
          value={name}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isAddModal={isAddModal}
          note={note}
          handleNoteChange={handleNoteChange}
          isEdit={false}
        />
        <AtsModal visible={isAtsModalVisible} />
        <DownloadModal visible={isDownloadModalVisible} />
        <PreviewModal
          visible={previewModal}
          // editDownloadFileName={isDownloadModalVisible}
        />
      </div>
    </UnnlockLayout>
  );
};

export default ResumeVault;
