/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FroalaEditorComp from "../../../components/FroalaEditor";
import * as ResumeActions from "../../../store/resumeOptimizer/resumeOptimizerActions";
import chatbot from "../../../assets/images/chatbot.svg";
import arrowDown from "../../../assets/images/resumeOptimizer/arrowDown.svg";
import "../index.scss";
import SaveButton from "../saveButton";
import DescriptionEditor from "../experienceInfo/descriptionEditor";

const Summary = () => {
  const dispatch = useDispatch();
  const { summary, currentResume } = useSelector(
    (state) => state.resumeOptimizer
  );
  const [suggestionData, setsuggestionData] = useState("");
  const [suggestionLoader, setsuggestionLoader] = useState(false);
  const [editorValue, setEditorvalue] = useState("");
  const [showAISuggestion, setshowAISuggestion] = useState(false);

  const handleSubmit = (values) => {
    const formData = {
      summary: values.replace(
        /&lt;p&gt;|&lt;\/p&gt;|&lt;div&gt;|&lt;\/div&gt;/gm,
        ""
      ),
    };
    dispatch(
      ResumeActions.resumeUpdateRequest({
        formData,
        label: "summary-update",
      })
    );
  };

  useEffect(() => {
    if (summary) {
      setEditorvalue(summary);
    }
  }, [summary]);

  const handleEditorChange = (e) => {
    console.log("Editor change", e);
    setEditorvalue(e);
    setshowAISuggestion(false);
    // setTimeout(() => {
    //   handleSubmit(e);
    // }, 2000);
  };

  const getSuggestion = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", localStorage.getItem("unnlockUserToken"));

    var raw = JSON.stringify({
      resume_id: currentResume,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setsuggestionLoader(true);


    const optionalVariableName = process.env.REACT_APP_ENV === "production" ? "?env=prod" : process.env.REACT_APP_ENV === "staging" ? "?env=stg" : "";
    const isAddOptionalParameter = (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") ? optionalVariableName : "";


    fetch(
      `https://server.unnlock.ai/ai-service/unnlock/summarize${isAddOptionalParameter}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setsuggestionData(result.rephrased_response);
        setsuggestionLoader(false);
      })
      .catch((error) => {
        setsuggestionLoader(false);
      });
  };

  return (
    <div className="update_experience_container">
      <SaveButton
        handleClick={() => handleSubmit(editorValue)}
        styles={{ background: "none" }}
      />
      <DescriptionEditor
        handleEditorChange={handleEditorChange}
        value={editorValue}
      />
      <div
        onClick={() => {
          setshowAISuggestion(!showAISuggestion);
          if (!showAISuggestion) {
            getSuggestion();
          }
        }}
        className="Ai_suggestion_header"
      >
        <img
          src={chatbot}
          alt="tips"
          style={{ width: "25px", paddingBottom: "5px" }}
        />
        <div>Generate a new AI summary</div>
        <img
          src={arrowDown}
          alt="arrowDown"
          style={{ transform: showAISuggestion ? "rotate(180deg)" : "" }}
        />
      </div>
      {showAISuggestion ? (
        <div className="Ai_suggestion_content">
          {suggestionLoader ? (
            "Loading...!"
          ) : (
            <>
              {suggestionData ? (
                <div>
                  {suggestionData}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() => setshowAISuggestion(false)}
                    >
                      Ignore
                    </div>
                    <div
                      style={{
                        color: "#4389FA",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!suggestionLoader && suggestionData) {
                          setEditorvalue(suggestionData);
                        }
                      }}
                    >
                      Replace
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Summary;
