/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "antd";
import { Tooltip, Menu, Dropdown, Popover, Button, Input, Space } from "antd";
import {
  DownloadOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import history from "../../routes/History";

import playButton from "../../assets/images/resumeOptimizer/playButton.svg";
import DownloadSimple from "../../assets/images/resumeOptimizer/DownloadSimple.svg";
import arrowDown from "../../assets/images/resumeOptimizer/arrowDown.svg";
import DotsThreeOutlineVertical from "../../assets/images/resumeOptimizer/DotsThreeOutlineVertical.svg";
import ArrowLeft from "../../assets/images/resumeOptimizer/ArrowLeft.svg";

import template1 from "../../assets/images/resumeOptimizer/Resume Templates-02.svg";
import template2 from "../../assets/images/resumeOptimizer/template2.svg";

import "./index.scss";
import PreviewModal from "./previewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTemplate,
  resumeDeleteRequest,
  resumeUpdateRequest,
} from "../../store/resumeOptimizer/resumeOptimizerActions";
import DownloadModal from "../ResumeVault/downloadModal";
import {
  jobMatchFetchRequest,
  jobMatchResultsRequest,
  setTrackerID,
} from "../../store/jobMatch/jobMatchActions";
import { formatToEmployementData } from "../../utils/helper";

const data = [
  { name: "Template 1", imgSrc: template1, id: 1 },
  {
    name: "Template 2",
    imgSrc: template2,
    id: 2,
  },
];

const ResumeOptimizerHeader = (props) => {
  const dispatch = useDispatch();
  const downloadLinkRef = props.downloadLinkRef;
  
  const currentTemplate = useSelector(
    (state) => state.resumeOptimizer.currentTemplate
  );
  const currentResume = useSelector(
    (state) => state.resumeOptimizer.currentResume
  );
  const { employementInfo, loaded } = useSelector(
    (state) => state.resumeOptimizer
  );

  const { loader, jobMatchResultData, jobMatchFetchData } = useSelector(
    (state) => state.jobmatch
  );
  
  const [resumeName, setResumeName] = useState("");
  const [previewModal, setpreviewModal] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [fileName, setFileName] = useState("Resume");
  const menu2 = (
    <Menu className="more_menue">
      <Menu.ItemGroup>
        {/* <Menu.Item>
          <div>Duplicate</div>
        </Menu.Item> */}
        <Menu.Item>
          <div
            onClick={() => {
              dispatch(resumeDeleteRequest(currentResume));
            }}
          >
            Delete
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const getPostData = (values) => {
    console.log(values.employment, "formData");
    let formData = {
      employment: values.employment.map((item, i) => {
        return {
          ...formatToEmployementData(item),
          PositionHistory: [
            {
              ...formatToEmployementData(item).PositionHistory[0],
              Description: item.jobDescription || "",
              HardSkills: item.hardSkills || [],
              SoftSkills: item.softSkills || [],
              currentStep: 3,
            },
          ],
        };
      }),
    };
    console.log(formData, "formData");
    return formData;
  };

  useEffect(() => {
    const resumeName = props.location?.state?.resumeName;
    if (resumeName) {
      setResumeName(resumeName);
    }
  }, []);

  useEffect(() => {
    const updateResume = props?.location?.state?.trackerId;

    if (updateResume && loaded && employementInfo?.employment?.length) {
      let formData = getPostData(employementInfo);
      dispatch(
        resumeUpdateRequest({
          formData,
          label: "employment-update",
        })
      );
    }
  }, [loaded]);

  const handleJobCheck = () => {
    if (!loader) {
      // dispatch(setTrackerID(props?.location?.state?.trackerId));
      if (props?.location?.state?.fromJobMatch) {
        const {
          company_name,
          job_title,
          industry,
          required_experience,
          education,
          company_url,
          text,
          job_match_id,
          open_job_id,
        } = jobMatchFetchData;
        const postdata = {
          resumeId: currentResume,
          fromOpenJobs: true,
          data: {
            company_name,
            company_url,
            job_title,
            education,
            required_experience,
            text,
            industry,
            primary_resume: currentResume,
            job_match_id,
            open_job_id,
          },
        };
        history.push({
          pathname: `/jobmatch`,
          state: {
            postdata,
            fromOpenJobs: true,
            doNotSave: true,
          },
        });
      } else {
        history.push(`/jobTracker`);
      }
    }
  };
  const hidePopover = () => {
    setPopoverOpen(false);
  };

  const handlePopoverOpenChange = (newOpen) => {
    setPopoverOpen(newOpen);
  };
  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };
  const handleDownload = () => {
    if (downloadLinkRef && downloadLinkRef.current && fileName) {
      downloadLinkRef.current.download = `${fileName}.pdf`;
      downloadLinkRef.current.click();
      hidePopover();
    }
    if (!fileName) {
      unnlockNotification("Please Enter File Name", "warning");
    }
  };

  return (
    <Row className="resume_optimizer_header" justify="space-between">
      <Col xs={24} sm={24} md={16} lg={16}>
        <div className="resume_optimizer__header__div">
          <div
            onClick={() => {
              if (props?.location?.state?.trackerId) {
                handleJobCheck();
              } else {
                history.push("/resumeVault");
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={ArrowLeft} alt="back" className="back_btn" />{" "}
            <span className="resume_vault">
              {!props?.location?.state?.trackerId
                ? "Resume Vault"
                : props?.location?.state?.fromJobMatch
                ? "Job Match"
                : "Job Tracker"}
            </span>
          </div>

          <p className="resume_name">{resumeName} </p>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Row justify="end">
          {/* <Col span={10}>
            <div>
              <Dropdown overlay={menu}>
                <div className="template_menue_selected_title">
                  <span>Template {currentTemplate}</span>
                  <img src={arrowDown} alt="arrowDown" />
                </div>
              </Dropdown>
            </div>
          </Col>
          <Col span={3}>
            <div
              className="play_btn"
              onClick={() => setpreviewModal(!previewModal)}
            >
              <img src={playButton} alt="play" />
            </div>
          </Col> */}
          {/* <Col span={3}>
            <div
              className="play_btn"
              onClick={() => setIsDownloadModalVisible(!isDownloadModalVisible)}
            >
              <img src={DownloadSimple} alt="play" />
            </div>
          </Col> */}
          <Popover 
            content={
              <Space.Compact>
                <Input defaultValue={fileName ? fileName : "Resume"} onChange={handleFileNameChange} addonAfter=".pdf" style={{width: "200px"}}/>
                <Tooltip title="Download">
                  <Button type="primary" onClick={handleDownload} icon={<CheckOutlined />}/>
                </Tooltip>
              </Space.Compact>
            }
            title="Enter filename:"
            trigger="click"
            open={popoverOpen}
            onOpenChange={handlePopoverOpenChange}
          >
            <Tooltip title="Download">
              <Button type="primary" icon={<DownloadOutlined/>} size="large" />
            </Tooltip>
          </Popover>
          <Col span={3}>
            <Dropdown overlay={menu2}>
              <div className="play_btn">
                <img src={DotsThreeOutlineVertical} alt="play" />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <PreviewModal visible={previewModal} />
      <DownloadModal visible={isDownloadModalVisible} />
    </Row>
  );
};

export default ResumeOptimizerHeader;
