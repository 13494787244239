import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Layout, Spin, Input, Button, Avatar, Tooltip } from "antd";
import moment from "moment";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import UnnlockLayout from "../layout";
import * as ProfileActions from "../../store/profile/profileActions";
import * as DashBoardActions from "../../store/dashBoard/dashboardActions";
import history from "../../routes/History";
import avatar from "../../assets/images/DefaultImage.svg";
import mail from "../../assets/images/profile/mail.svg";
import verfied from "../../assets/images/profile/verfied.svg";
import infoCircle from "../../assets/images/profile/info-circle.svg";
import phone from "../../assets/images/profile/phone.svg";
import editProfileIcon from "../../assets/images/profile/editProfileIcon.svg";
import akarstar from "../../assets/images/profile/akarstar.svg";
import lock from "../../assets/images/profile/lock.svg";
import key from "../../assets/images/profile/key.svg";
import updatenow from "../../assets/images/profile/updatenow.svg";
import PasswordInput from "./passwordInput";
import "./NewProfile.scss";
import SocEditModal from "./socEditModal";

const { Content } = Layout;

const DisplayingErrorMessagesSchema = Yup.object().shape({
  // emailId: Yup.string().required("Required"),
  // curr_base: Yup.string().required("Required"),
});

const NewProfile = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.profile.loading);
  const uploadPic = useSelector((state) => state.profile.uploadPic);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const [isedit, setisedit] = useState(false);
  const [socEditModal, setSocEditModal] = useState(false);
  const [isChangePassClick, setIsChangePassClick] = useState(false);
  const [expire, setExpire] = useState(0);
  const [lastUpdate, setLastUpdate] = useState("");
  const [initialFormData, setinitialFormData] = useState({
    emailId: "",
    phoneNumber: "",
  });
  const [design, setDesign] = useState("");
  const loginState = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(ProfileActions.getprofileRequest());
    dispatch(ProfileActions.getSubscriptionRequest());
    if (uploadPic) {
      dispatch(ProfileActions.getprofileRequest());
    }
  }, [uploadPic]);

  const basicInfo = useSelector((state) => state.profile.basicInfo);
  const contactInfo = useSelector((state) => state.dashboard.contactInfo);
  const currentSub = useSelector((state) => state.profile.currentSubscription);
  useEffect(() => {
    const date1 = moment(currentSub.expiery);
    const date2 = moment();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setExpire(diffDays);
    const update = moment(currentSub.lastupdate).format("Do MMM YYYY");
    setLastUpdate(update);
  }, [currentSub]);

  useEffect(() => {
    if (
      loginState.userData &&
      loginState.userData.primary_resume &&
      !employementInfo?.employment[0]?.designation
    ) {
      // console.log("INSIDELOGINSTATE")
      dispatch(
        DashBoardActions.resumeParserRequest(loginState.userData.primary_resume)
      );
    }
    setinitialFormData({
      emailId: loginState.userData.email,
      phoneNumber: loginState.userData.phone,
    });
  }, [loginState]);

  useEffect(() => {
    if (employementInfo?.employment[0]?.designation)
      setDesign(
        `${employementInfo.employment[0]?.designation} , ${employementInfo.employment[0]?.company}`
      );
  }, [employementInfo]);

  const handleSubmit = (data) => {
    setisedit(false);
    // setinitialFormData(data);
    basicInfo.phone = data.phoneNumber;
    const formData = {
      phone: data.phoneNumber,
    };
    dispatch(
      ProfileActions.profilePicUpdateRequest({
        formData,
      })
    );
  };

  const handleSubmitPass = () => {
    document.getElementById("submit_password").click();
  };

  const userToken = "";

  const initialValues = {
    current: "",
    new: "",
    confirm: "",
  };

  const validation = Yup.object().shape({
    current: Yup.string().required("current password is required"),
    new: Yup.string().required("new password is required"),
    confirm: Yup.string().required("confirm password is required"),
  });

  const handleFile = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const formData = {
        base64String: reader.result,
      };
      dispatch(
        ProfileActions.uploadProfilePicRequest({
          formData,
        })
      );
    };

    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const imageUpload = () => (
    <input
      type="file"
      accept="image/*"
      style={{ display: "none" }}
      onChange={(e) => {
        handleFile(e);
      }}
      id="upload_profile_pic"
    />
  );

  const handleClick = () => {
    document.getElementById("upload_profile_pic").click();
  };

  const getSubByTierId = (id) => {
    switch (id) {
      case 0:
        return "FREE";
        break;
      case 1:
        return "MONTHLY";
        break;
      case 2:
        return "QUARTERLY";
        break;
      case 3:
        return "YEARLY";
        break;

      default:
        return "FREE";
        break;
    }
    return;
  };

  return (
    <UnnlockLayout>
      <div className="newProfileContainer">
        <Spin size="large" spinning={isLoading}>
          <Content className="contentcontainer">
            <div className="topSection">
              <div role="button" className="horizontalCenter1">
                {/* <p
                  onClick={() => {
                    setisedit(true);
                  }}
                  className="responsiveedit"
                >
                  Edit Profile <img src={editProfileIcon} />{" "}
                </p> */}
                <div
                  className="profile_image_conatiner"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                  role="button"
                  tabIndex={0}
                >
                  <Avatar
                    shape="circle"
                    size={{ xs: 96, lg: 200 }}
                    style={{ width: "60px", height: "60px" }}
                    className="profile_image"
                    src={
                      basicInfo.profileImg ? (
                        <img src={basicInfo.profileImg} alt="avatar" />
                      ) : (
                        <img src={avatar} alt="avatar" />
                      )
                    }
                    icon={
                      basicInfo.profileImg ? (
                        <img src={basicInfo.profileImg} alt="avatar" />
                      ) : (
                        <img src={avatar} alt="avatar" />
                      )
                    }
                  />
                </div>
                {imageUpload()}

                <div className="profileinfocontainer">
                  <p className="profileName">{`${contactInfo?.firstName || basicInfo?.firstName}`}</p>
                  {/* <p className="profileSubName">{design}</p> */}
                </div>
              </div>
              {isedit ? (
                <div>
                  <Formik
                    initialValues={initialFormData}
                    validationSchema={DisplayingErrorMessagesSchema}
                    enableReinitialize
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Field
                            name="emailId"
                            render={({ field }) => (
                              <>
                                <Input
                                  {...field}
                                  type="email"
                                  disabled
                                  value={basicInfo.email}
                                  className="cardtextinput"
                                  placeholder="Enter Email Id"
                                  prefix={<img src={mail} alt="search" />}
                                />
                                <div
                                  className="error_fields"
                                  style={{
                                    color:
                                      touched.emailId && errors.emailId
                                        ? ""
                                        : "transparent",
                                  }}
                                >
                                  {errors?.emailId}
                                </div>
                              </>
                            )}
                          />
                          {/* <Field
                            name="phoneNumber"
                            render={({ field }) => (
                              <>
                                <Input
                                  {...field}
                                  type="text"
                                  className="cardtextinput"
                                  placeholder="Enter Phone No."
                                  prefix={<img src={phone} alt="search" />}
                                />
                              </>
                            )}
                          /> */}
                          <button type="submit" className="check_btn">
                            <p>Done</p>
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginRight: "20px",
                    }}
                  >
                    <div className="horizontalCenter">
                      <img src={mail} alt="mail" />
                      <p className="emailtext">
                        {basicInfo.email}{" "}
                        {loginState.userData?.email_verified ? (
                          <img src={verfied} />
                        ) : (
                          <Tooltip
                            placement="bottom"
                            title="We had sent a verification link to your
                      email address."
                          >
                            <p>
                              Email Verification Pending
                              <img src={infoCircle} />
                            </p>
                          </Tooltip>
                        )}
                      </p>
                    </div>
                    {/* {!isedit && (
                      <img
                        className="editimage"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        src={editProfileIcon}
                        alt="edit"
                        onClick={() => {
                          setisedit(true);
                        }}
                      />
                    )} */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="horizontalCenter">
                      {/* <img
                        src={mail}
                        alt="mail"
                        style={{ marginRight: "15px" }}
                      /> */}
                      {loginState?.userData && (
                        <div>{loginState?.userData?.user_socs[0]?.name}</div>
                      )}
                    </div>
                    <img
                      className="editimage"
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        marginLeft: "15px",
                      }}
                      src={editProfileIcon}
                      alt="edit"
                      onClick={() => {
                        setSocEditModal(!socEditModal);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="contentContainer">
              <Row>
                <Col md={12} className={"col1"}>
                  <div className="bigcard">
                    <Row className="headersec">
                      <img src={akarstar} alt="dash" />
                      <p className="compsatitle">Subscriptions</p>
                    </Row>
                    <div className="cardsec">
                      <div className="subcriptioncard">
                        <div className="subsCard">
                          <p className="currentplantext">Current Plan</p>
                          <h2 className="freetext">
                            {getSubByTierId(currentSub.id)}
                          </h2>
                          <p
                            className="currentplantext"
                            style={{ fontSize: "14px" }}
                          >
                            {`Will end in ${expire} days`}
                          </p>
                        </div>
                        <div>
                          <p className="subsInfotext">
                            Experience Unnlock to the fullest with our monthly
                            and annual subscriptions.
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              className="borderedBtn"
                              onClick={() => {
                                history.push("/subscriptions");
                              }}
                            >
                              Upgrade
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="bigcard">
                    <Row className="headersec">
                      <img src={lock} alt="dash" />
                      <p className="compsatitle">Password</p>
                    </Row>
                    <div className="cardsec">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "24px",
                        }}
                      >
                        <div style={{ display: "flex", maxWidth: "70%;" }}>
                          <img
                            src={updatenow}
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "16px",
                            }}
                            alt=""
                          />
                          <p className="subTitlesText">Last Updated on</p>
                        </div>
                        <p className="updatedText">{lastUpdate}</p>
                      </div>
                      <div className="subcontainer">
                        {!isChangePassClick ? (
                          <>
                            <div style={{ display: "flex", maxWidth: "70%;" }}>
                              <img
                                src={key}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  marginRight: "16px",
                                }}
                                alt=""
                              />
                              <p className="subTitlesText">
                                Choose a unique password to protect your account
                              </p>
                            </div>
                            <div
                              className="borderedBtn change1"
                              onClick={() => {
                                setIsChangePassClick(true);
                              }}
                            >
                              Change
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ maxWidth: "70%;" }}>
                              <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validation}
                                onSubmit={(values) => {
                                  const formData = {
                                    access_token: userToken,
                                    old_password: values.current,
                                    new_password: values.new,
                                  };
                                  dispatch(
                                    ProfileActions.changePassRequest({
                                      formData,
                                    })
                                  );
                                }}
                              >
                                {({
                                  errors,
                                  touched,
                                  values,
                                  handleChange,
                                }) => (
                                  <Form>
                                    <div className="input_container">
                                      <div
                                        className="input_holder"
                                        style={{ marginBottom: "16px" }}
                                      >
                                        <PasswordInput
                                          label="Current Password"
                                          value={values.current}
                                          handleChange={handleChange}
                                          name="current"
                                        />
                                        {errors &&
                                          errors.current &&
                                          touched &&
                                          touched.current && (
                                            <p className="error_field">
                                              {errors.current}
                                            </p>
                                          )}
                                      </div>
                                      <div
                                        className="input_holder"
                                        style={{ marginBottom: "16px" }}
                                      >
                                        <PasswordInput
                                          label="New Password"
                                          value={values.new}
                                          handleChange={handleChange}
                                          name="new"
                                        />
                                        {errors &&
                                          errors.new &&
                                          touched &&
                                          touched.new && (
                                            <p className="error_field">
                                              {errors.new}
                                            </p>
                                          )}
                                      </div>
                                      <div className="input_holder">
                                        <PasswordInput
                                          label="Re-Enter New Password"
                                          value={values.confirm}
                                          handleChange={handleChange}
                                          name="confirm"
                                        />
                                        {errors &&
                                          errors.confirm &&
                                          touched &&
                                          touched.confirm && (
                                            <p className="error_field">
                                              {errors.confirm}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      id="submit_password"
                                      style={{ display: "none" }}
                                    >
                                      Submit
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                            <Button
                              type="primary"
                              shape="round"
                              ghost
                              className="custom_btn"
                              onClick={() => {
                                setIsChangePassClick(false);
                                handleSubmitPass();
                              }}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </Spin>
        <SocEditModal visible={socEditModal} />
      </div>
    </UnnlockLayout>
  );
};

export default NewProfile;
