import React from "react";
import JobTracker from "../PersonalDashboard/jobTracker";
import UnnlockLayout from "../layout";
import "../PersonalDashboard/index.scss";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";

const Tracker = () => {
  const loginState = useSelector((state) => state.login);
  return (
    <UnnlockLayout>
      <div className="personalDashboard_container" style={{ padding: "30px" }}>
        <Skeleton loading={loginState.loader} active>
          <JobTracker handleViewAll={() => {}} viewJTAll={true} />
        </Skeleton>
      </div>
    </UnnlockLayout>
  );
};

export default Tracker;
